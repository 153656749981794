

export default function init_multi_lca_lcc(){

    var prv_val = "" ,f_val = "";
    var prv_val_1 = "" ,f_val_1 = "";
    $('#target_input').removeAttr('value');

    var lca_elem = false;
    var lcc_open = false;

    $(".main-container.collapse").on('shown.bs.collapse', function () {
         $(".main-container.collapse.show").not($(this)).collapse('hide');
    });

    function getSelectValues(select) {
        var result = [];
        var options = select && select.options;
        var opt;

        for (var i=0, iLen=options.length; i<iLen; i++) {
            opt = options[i];

            if (opt.selected) {
                result.push(opt.value || opt.text);
            }
        }
        return result;
    }


    $('#get_pvs_from_list').change(function() {
        console.log("#get_pvs_from_list")
        const input_element = document.getElementById('target_input');

        var new_val = $(this).val();
        if(new_val != ""){
            prv_val = $('#target_input').val();
            if(prv_val != ""){
                f_val = prv_val + " " + new_val;
            }
            else {
                f_val = new_val;
            }
            $('#target_input').val(f_val);
        }
    });


    $('#get_pvs_from_list_lcc').change(function() {
        var new_val_1 = $(this).val();
        if(new_val_1 != ""){
            prv_val_1 = $('#target_input_lcc').val();
            if(prv_val_1 != ""){
                f_val_1 = prv_val_1 + " " + new_val_1;
            }
            else {
                f_val_1 = new_val_1;
            }
            $('#target_input_lcc').val(f_val_1);
        }
    });
   // $(".main-container.collapse.show").collapse('hide');
};
