import Chart from 'chart.js';
import 'chartjs-plugin-colorschemes';

const gridLinesColor = '#39517b'
const chartConfig = {
  type: 'line',
  data: {
    datasets: []
  },
  options: {
    scales: {
      xAxes: [
        {
          fontColor: '#666',
          type: 'time',
          time: {
              unit: 'hour',
              stepSize: 1,
          },
          lineThickness: 1,
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Time',
            fontColor: "white"
          },
          ticks: {
            fontColor: "white"
          },
          gridLines: {
            color: gridLinesColor
          }
        }
      ],
    }
  }
};


export default class ChartHelper {
  constructor(p_type, color) {
    this.p_type = p_type;
    this.chart = null;
    this.datasets = [];
    this.monitoring = true;
  }

  getPlotType() {
    return this.p_type;
  }

  initPlot() {
    const canvas = document.querySelector(`[data-plot_type='${this.p_type}']`);
    const ctx = canvas.getContext('2d');
    this.chart = new Chart(ctx, {
      ...chartConfig,
      data: {
        datasets: this.datasets
      },
      options: {
        ...chartConfig.options,
        scales: {
          ...chartConfig.options.scales,
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
                fontColor: "white",
                autoskip: false
              },
              scaleLabel: {
                display: true,
                labelString: this.p_type,
                fontColor: "white"
              },
              gridLines: {
                color: gridLinesColor
              }
            }
          ]
        },
        legend: {
          labels: {
            fontColor: 'white'
          }
        }
      }
    })
  };

  addLine(projectId, name, color) {
    let exists = this.datasets.some(d => d.projectId === projectId);

    if (!exists) {
      this.datasets.push({
        projectId,
        label: name,
        data: [],
        fill: false,
        borderWidth: 2,
        borderColor: color,
        backgroundColor: color,
        pointBorderColor: color,
        pointRadius: 0
      });
      
      this.chart.update();
    }
  };

    removeLine(projectId) {
      const dataset = this.datasets.findIndex(d => d.projectId === projectId);
      if (dataset != -1) {
        this.datasets.splice(dataset, 1);
        this.chart.update();
      }
    };

    addDataPoint(projectId, x, y) {
      if (this.monitoring) {
        const dataset = this.datasets.find(d => d.projectId === projectId);
        if (dataset && y != "NaN" && y != undefined) {
          dataset.data.push({
            x: new Date(x),
            y: y
          });
          this.chart.update();
        }
      }
    };

    clearChart() {
      this.datasets.splice(0, this.datasets.length);
      // this.chart.config.data = { datasets: this.datasets }
      this.chart.update();
    };

    clearDataPoints(projectId, individual=false) {
      // in the individual plots two lines may be plotted
      // when clearing the data points, both of them should be cleared
      if (individual) {
        $(this.datasets).each(function (index, ds) {
          ds.data = [];
        });
        this.chart.update();
      } else {
        const dataset = this.datasets.find(d => d.projectId === projectId);
        if (dataset) {
          dataset.data = [];
          this.chart.update();
        }
      }
    }

    // without points
    smoothChartLine(projectId, smooth=true) {
      const dataset = this.datasets.find(d => d.projectId === projectId);
      if (dataset) {
        let radius = 3;
        if (smooth) radius = 0;
        dataset.pointRadius = radius;

        if (smooth) {
          this.chart.options.scales.xAxes[0].time = {
              unit: 'day',
              stepSize: 1,
          };
        } else {
          this.chart.options.scales.xAxes[0].time = {
              unit: 'hour',
              stepSize: 1,
          };
        }
        this.chart.update();
      }
    }

    pauseMonitoring (pause) {
      this.monitoring = !pause;
    }

    isPaused () {
      return !this.monitoring;
    }
};
