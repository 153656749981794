// TODO: If the class organization stays consider creating a parent class
// for this class and building sector form
import ProductionFormHandler from "../../pvs/form_helpers/productionFormHandler";

export default class ResFarmForm {

    constructor (resFarmsElement, scenarioElement, genType='rf') {
        this.resFarmsElement = resFarmsElement
        this.scenarioElement = scenarioElement
        this.genType = genType

        this.#setUpModalFormsHandler()
    }

    #setUpModalFormsHandler () {
        const index = $(this.scenarioElement).data('index')
        const attributesPath = `district_project[district_scenarios_attributes][${index}]`
        new ProductionFormHandler($(this.resFarmsElement), attributesPath, this.genType)
    }

}