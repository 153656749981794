// Opens the modal for a specific component to add custom COP and SEER
// Creates the hidden input - use the elementCreator
// Enter the user value when the modal re-opens for a component

import { createHiddenInput } from '../../modules/createElements'

let modal, modalHeader
let copInput, seerInput
let currentComponentName, currentComponentEl, currentComponentContainer
let contribType  // heat or cool
const copInputPattern = 'thermal[{0}_contributions[{1}[heat_eff]]]'
const seerInputPattern = 'thermal[{0}_contributions[{1}[cool_eff]]]'

export default function() {
  modal = $('#customCopModal')
  modalHeader = $(modal).find('h5')
  copInput = $('#cop_input')
  seerInput = $('#seer_input')

  setListeners()
  setSaveListener()
  setCancelListener()
}

function setListeners() {
  $('.custom_cop').on('click', function() {
    currentComponentEl = this
    currentComponentName = $(this).text()
    currentComponentContainer = $(this).parent()
    contribType = findContributionType()
    const seerInput = $('#seer_row')

    $(modalHeader).text(modalTitle())
    if(isBoiler()) {
      $(seerInput).hide()
    } else {
      $(seerInput).show()
    }
    loadInputValues()
    $(modal).modal('toggle')
  })
}

function setSaveListener() {
  const saveButton = $('#save_btn')
  $(saveButton).on('click', function() {
    onSave()
    $(modal).modal('toggle')
  })
}

function onSave(componentName) {
  // get user inputs
  const copInputValue = $(copInput).val()
  let seerInputValue = 1
  if(!isBoiler()) seerInputValue = $(seerInput).val()

  // generate input name - maybe as global variables
  const copInputName = copInputPattern.replace('{0}', contribType).replace('{1}', currentComponentName)
  const seerInputName = seerInputPattern.replace('{0}', contribType).replace('{1}', currentComponentName)
  // create two hidden inputs - if boiler then add seer 1
  const copHiddenInput = createHiddenInput(copInputName, copInputName, copInputValue)
  const seerHiddenInput = createHiddenInput(seerInputName, seerInputName, seerInputValue)

  // append the input under component name
  removePreviousInputs()
  $(currentComponentContainer).append(copHiddenInput)
  $(currentComponentContainer).append(seerHiddenInput)
}

function removePreviousInputs() {
  $(currentComponentContainer).find('input').remove()
}

function loadInputValues() {
  const inputs = $(currentComponentContainer).find('input')
  const copComponentInput = inputs[0]
  const seerComponentInput = inputs[1]

  if(copComponentInput && seerComponentInput) {
    $(copInput).val($(copComponentInput).val())
    $(seerInput).val($(seerComponentInput).val())
  } else {
    $(copInput).val('')
    $(seerInput).val('')
  }
}

function findContributionType() {
  const table = $(currentComponentEl).closest('table')
  const tableId = $(table).attr('id')
  return tableId.replace('_contributions_table', '')
}

function setCancelListener() {
  const cancelButton = $('#cancle_btn')
  $(cancelButton).on('click', function() {
    $(modal).modal('toggle')
  })
}

function modalTitle() {
  if(isBoiler()) {
    return "Please enter the component's COP value."
  } else {
    return "Please enter the component's COP and SEER value."
  }
}

function isBoiler() {
  return currentComponentName.toLowerCase().includes('boiler')
}
