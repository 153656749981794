export default function() {
  toggleMqttButton()
  updateMqttConnectButton()
  addUploadCsvListener()
  addDataLakeRetrievalListener()
}

// This has to be done manually because if it is done with link
// the page refreshes and the notification is not showing.
// So AJAX is a better choice
function toggleMqttButton() {
  $('.mqtt-toggle').on('click', function(e) {
    const parent = $(e.target).parent()
    const deviceId = $(parent).data('device_id')

    $.ajax({
      url: `/b/devices/${deviceId}/toggle_mqtt_connection`,
      type: 'post'
    })
  })
}

function updateMqttConnectButton() {
  $(document).on('notification_received', function(e, notificationType, details) {
    if(notificationType === 'mqtt_toggle') {
      const menus = $(`div[data-device_id=${details.deviceId}]`)
      const buttons = $(menus).find('.mqtt-toggle')
      if(details.active) {
        $(buttons).text('Disconnect from MQTT')
      } else {
        $(buttons).text('Connect from MQTT')
      }
    }
  })
}


// This assigns a listener that is fired when the user clicks
// the action Upload CSV File and it opens the corresponding modal
function addUploadCsvListener() {
  const modal = $('#upload-csv-modal')
  $('.upload-csv').on('click', function() {
    const parent = $(this).parent()
    const deviceId = $(parent).data('device_id')
    // call ajax to get form html
    $.ajax({
      url: `/b/devices/${deviceId}/upload_csv_form`,
      type: "get",
      data: {},
      success: function(data) {
        modal.find('.modal-body').html(data.html);
        modal.modal('toggle')
      },
      error: function(data) {}
    })
  })
}

// Same as above but for data lake retrieval
function addDataLakeRetrievalListener() {
  const modal = $('#upload-csv-modal')
  $('.data-lake-retrieval').on('click', function() {
    const parent = $(this).parent()
    const deviceId = $(parent).data('device_id')
    $.ajax({
      url: `/b/devices/${deviceId}/retrieve_data_lake_form`,
      type: "get",
      data: {},
      success: function(data) {
        modal.find('.modal-body').html(data.html);
        $('.datepicker').flatpickr({ mode: "range", inline: true,})
        modal.modal('toggle')
        const form = $('#retrieve-form')
        form.on('submit', function(e) {
          e.preventDefault()
          onRetrieveSubmit()
          e.submit()
        })
      },
      error: function(data) { }
    })
  })
}

// Actions for when the Retrieve Measurements (from data lake) button is pressed
// The dates should be exported from the datepicker -> TODO: Export this into a globally used method
// The form should be submitted using device id and the exported dates
function onRetrieveSubmit() {
  // find input
  const datesInput = $('.datepicker').first()
  const datesArray = datesInput.val().split('to')
  const hiddenInputFrom = $('input[name=from_date]')
  const hiddenInputTo = $('input[name=to_date]')
  if(datesArray.length == 2) {
    hiddenInputFrom.val(datesArray[0].trim())
    hiddenInputTo.val(datesArray[1].trim())
  } else if (datesArray.length == 1 && datesArray[0] !== "") {
    hiddenInputFrom.val(datesArray[0].trim())
    hiddenInputTo.val(datesArray[0].trim())
  }
}

// The modal form should change each time a device is selected
// This method calls the specific device form and appends it to
// to the modal body
function loadCsvModalForm() {

}
