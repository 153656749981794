import * as elementCreator from '../../modules/createElements.js';
import WaterStorageFormHelper from "./waterStorageFormHelper";

export default function () {
    _setUpWaterStorageForms()
}

function _setUpWaterStorageForms () {
    // there are two divs - one for base and one for var - find them
    const waterStorageForms = $('[id*=water-storage-source]')
    waterStorageForms.each(function (index, element) {
        const waterStorageFormHelper = new WaterStorageFormHelper(element)
        waterStorageFormHelper.setUpInitialState()
        waterStorageFormHelper.addReactivity()
    })
}
