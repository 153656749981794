// TODO: Consider creating subclasses for different types of charts
// noinspection JSMethodCanBeStatic
// TODO: Add support for custom options - e.g. add in constructor options={}
// and if { title: { display: false } } is given do options.title.display = false
// TODO: When double select then adjust the second select's options to the corresponding ones
//  e.g. { Plants: [p1, p2, p3, a], Various: [v1, v2, a] }. When Plants is selected show p1, p2, p3, a
//  when Various is selected show v1, v2, a.
import AxesHelper from "./axesHelper";
import DatasetSelector from './datasetSelector';

const COLOR_PALETTE_BLUE = ['#2b7bd4', '#1c436f']
const COLOR_PALETTE_GREEN = ['#7ad42b', '#396f1c']
const COLOR_PALETTE = {
    'blue': ['#4892e7', '#1f1fa6'],
    'green': ['#a7db6a', '#5dad2f'],
    'orange': ['#fdd850', '#e2d458'],
}

export default class GenericChartHelper {
    constructor(chartObj) {
        this.chartObj = chartObj
        this.title = chartObj.data('title')
        this.chartType = chartObj.data('ctype')
        this.labels = Array.from(chartObj.data('labels'))
        this.datasets = chartObj.data('datasets')
        this.color = chartObj.data('color')
        console.log(this.color)
        if(['green', 'blue', 'orange'].includes(this.color)) this.color = 'office.Apex6'
        this.rangeAxis = chartObj.data('range_axis')
        this.doubleYAxes = chartObj.data('double_y_axis')
        this.xLabel = chartObj.data('x_label')
        this.yLabel = chartObj.data('y_label')
        if (this.yLabel instanceof Array) {
            this.yLabel2 = this.yLabel[1]
            this.yLabel = this.yLabel[0]
        } else {
            this.yLabel2 = this.yLabel
        }

        this.size = chartObj.data('size')
        this.beginAtZero = chartObj.data('begin_at_zero')
        this.stacked = chartObj.data('stacked')
        this.tooltipText = chartObj.data('tooltip_text')
        this.xAxesType = chartObj.data('x_axes_type')

        this.renderedFirstTime = false

        const datasetSelector = new DatasetSelector(chartObj, this)
        const otherTimeseries = chartObj.data('other_timeseries')
        if(otherTimeseries) {
            const obj = this
            const selectContainer = $(this.chartObj).parent().siblings()[0]
            if(Array.isArray(otherTimeseries)) {
                this.allTimeseries = [ { labels: this.labels, datasets: this.datasets }]
                this.allTimeseries = this.allTimeseries.concat(otherTimeseries)
            } else if(otherTimeseries.constructor === Object) {
                this.allTimeseries = otherTimeseries
            }
            this.visited_series = ["0"]
        }
    }

    renderChart() {
        const obj = this
        const axesHelper = new AxesHelper(this.labels, this.xLabel, this.yLabel, this.yLabel2,
            this.beginAtZero, this.rangeAxis, this.doubleYAxes, this.#_whichAxes(), this.xAxesType, this.stacked)

        let rightMin = 0
        const dataHash = { datasets: this.datasets }
        if (this.xAxesType !== 'time') {
            dataHash['labels'] = this.labels
        }

        const options = {
            type: this.chartType,
            data: dataHash,
            options: {
                beginAtZero: this.beginAtZero,
                title: this.#_getTitle(),
                scales: axesHelper.exportScales(),
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    callbacks: {
                        afterBody: function (tooltipItem, data) {
                            const tooltipIndex = tooltipItem[0].index
                            return obj.tooltipText[tooltipIndex]
                        }
                    }
                },
                legend: {
                  labels: {
                    fontColor: 'black'
                  }
                },
                plugins: {
                  colorschemes: {
                    scheme: this.color
                  }
                }
            },
            plugins: [{
                afterRender(chart) {
                    if (obj.chartObj.data('download')) {
                        obj.#_addDownloadListener(true)
                    }

                    if (!obj.renderedFirstTime && obj.doubleYAxes) {
                        const boxes = chart.scales
                        const y2box = boxes.y_right
                        let ticks = y2box._labelItems
                        let y2maxTick = ticks[0]['label']
                        if(typeof(y2maxTick) === "string")
                            y2maxTick = parseInt(y2maxTick)

                        let y2minTick = ticks[ticks.length - 1]['label']
                        if(typeof(y2minTick) === "string")
                            y2minTick = parseInt(y2minTick)

                        const y1box = boxes.y_left
                        ticks = y1box._labelItems
                        let y1maxTick = ticks[0]['label']
                        if(typeof(y1maxTick) === "string")
                            y1maxTick = parseInt(y1maxTick)

                        // const y1minTick = (y1maxTick * y2minTick) / y2maxTick
                        const y1minTick = y2minTick / (y2maxTick / y1maxTick)
                        console.log('y1_max:', y1maxTick, 'y2_max:', y2maxTick, 'y2_min:', y2minTick, 'y1_min:', y1minTick)

                        chart.options.scales.yAxes[0].ticks.min = y1minTick
                        chart.update()
                    }

                    obj.renderedFirstTime = true
                },
            }]
        }

        const renderedChart = new Chart(this.chartObj, options)

        if (this.size === 'sm') {
            renderedChart.canvas.parentNode.style.height = '300px';
            renderedChart.canvas.parentNode.style.width = '100%';
        }

        this.chart = renderedChart
    }

    // TODO: Add this in a subclass
    addDataPoint(lineType, x, y) {

        if (!this.#datasetExists(lineType)) {
            this.#addNewDataset(lineType)
        }

        const dataset = this.datasets.find(d => d.lineType === lineType)

        dataset.data.push({ x, y })

        // if (!this.labels.includes(y)) {
        //     this.labels.push(y)
        // }

        this.chart.update()
    }

    changeTimeSeries(which) {
        this.chart.data.datasets = this.allTimeseries[which].datasets
        this.chart.data.labels = this.allTimeseries[which].labels

        let extend = false
        if(!this.visited_series.includes(which)) {
            extend = true
            this.visited_series.push(which)
        }

        const axesHelper = new AxesHelper(this.chart.data.labels, this.xLabel, this.yLabel, this.yLabel2,
            this.beginAtZero, this.rangeAxis, this.doubleYAxes, this.#_whichAxes(), this.xAxesType, this.stacked,
            extend)

        this.chart.options.scales = axesHelper.exportScales()
        this.visited_series.push(which)
        this.chart.update()
    }

    changeTimeSeriesDouble(key, series) {
        const keySeries = this.allTimeseries[key.toUpperCase()]
        const selectedSeries = keySeries[series] //.find(s => s.datasets[0].label.toLowerCase().replaceAll(' ', '_') === series.toLowerCase())
        const axesHelper = new AxesHelper(this.chart.data.labels, this.xLabel, this.yLabel, this.yLabel2,
            this.beginAtZero, this.rangeAxis, this.doubleYAxes, this.#_whichAxes(), this.xAxesType, this.stacked,
            false)

        this.chart.options.scales = axesHelper.exportScales()
        this.chart.data.labels = selectedSeries.labels
        this.chart.data.datasets = selectedSeries.datasets
        this.chart.update()
    }

    #datasetExists(lineType) {
        return this.datasets.some(d => d.lineType === lineType)
    }

    #addNewDataset(lineType) {
        this.datasets.push({
            lineType,
            label: lineType,
            data: [],
            fill: false
        })
        this.chart.update()
    }

    #_getTitle () {
        if (this.title !== '') {
            return {
                display: true,
                text: this.title,
                fontColor: 'black',
                fontSize: 14
            }
        } else {
            return {
                display: false,
                fontColor: 'black',
                fontSize: 14
            }
        }
    }

    // Finds which axes is the range axes
    #_whichAxes () {
        if (this.chartType === 'horizontalBar') return 'y'
        else return 'x'
    }

    #_colorDataset(index, color) {
        if(this.chartType !== 'line')
            this.datasets[index]['backgroundColor'] = color
    }

    #_addDownloadListener (background=false) {
        const extraPadding = 115
        const canvas = this.chartObj[0]
        const siblings = $($(this.chartObj.parent()).siblings())
        const container = $(siblings[siblings.length - 1])
        const downloadButton = container.find('.download-chart')[0]
        $(downloadButton).attr('disabled', 'disabled')

        // $(downloadButton).on('click', function () {
        if (background) {
            // Tried to find a quick solution to make the background of the canvas white
            // I found this on StackOverflow: https://stackoverflow.com/a/19539048/3309479
            const destinationCanvas = document.createElement("canvas")
            destinationCanvas.width = canvas.width
            destinationCanvas.height = canvas.height + extraPadding
            const destCtx = destinationCanvas.getContext('2d')
            destCtx.fillStyle = "#FFFFFF"
            destCtx.fillRect(0,0, canvas.width + extraPadding, canvas.height + extraPadding)
            destCtx.drawImage(canvas, 0, 0)

            let image = new Image()
            image.src = $(downloadButton).data('logo_path')
            image.width = 10;
            image.onload = function () {
                const scaleFactor = 2
                // 703 x 190 is the logos image size
                const width = 703 / scaleFactor
                const height = 190 / scaleFactor
                destCtx.drawImage(image, (canvas.width - width) / 2, canvas.height + 10, width, height)
                downloadButton.href = destinationCanvas.toDataURL()
                $(downloadButton).removeAttr('disabled')
            }

        } else {
            downloadButton.href = canvas.toDataURL()
        }
        // })
    }
}
