// ** DEPRECATED ** //
// TODO: Maybe add functionality to reset pvs from database

let pvs = []
let pvsToDelete = []
let mode = 'new';
let currentPv = -1;
let destroyCounter = -1;

export default function TestPage () {
  // i think something is going on with turbolinks
  // and sometimes pvs are already loaded when they should be null
  // so manually emptying the list
  // same for containers not loaded from server
  // pvs = []
  // $('.pv-container:not(:has(#pv_id))').remove();

  // OK
  $("#add-pv-btn").on('click', function () {
    mode = 'new';
    resetModalForm();
    $("#pv-modal").modal('toggle');
  });

  $("#clear-pvs-btn").on('click', function () {
    clearPvs();
  });

  $("#save-changes-btn").on('click', function () {
    getInputValues();
    resetModalForm();
    $("#pv-modal").modal('hide')
  });

  // sessionStorage.setItem('pvs', "[]");
  loadExistingPvs();

  let sessionPvs = [];
  pvsToDelete = [];
  if (sessionStorage.getItem('pvs') !== null)
    sessionPvs = JSON.parse(sessionStorage.getItem('pvs')).filter(pv => pv.pv_id === undefined);
  if (sessionStorage.getItem('pvsToDelete') !== null)
    pvsToDelete = JSON.parse(sessionStorage.getItem('pvsToDelete'));

  pvs = pvs.concat(sessionPvs);

  updateSessionStorage();

  loadAfterRefresh();

  editDestroyListeners();
}

function loadAfterRefresh () {
  for (let i = 0; i < pvs.length; i++) {
    if (pvs[i].pv_id === undefined) {
      pvs[i].id = i;
      $('#pvs-container').append(createPvElement(`pv_${i}`));
    }
  }

  for (let i = 0; i < pvsToDelete.length; i++){
    const index = pvs.findIndex(pv => pv.pv_id == pvsToDelete[i])
    if (index > -1) destroyPv(`pv_${index}`);
  }
}

function clearPvs () {
  const iterations = pvs.length - 1;
  for (let i = iterations; i >= 0; i--)
    destroyPv(`pv_${pvs[i]['id']}`);
}

function destroyPv (pvId) {
  // find element position in model
  const container = $(`#${pvId}`);
  const fromServer = container.find('#pv_id').length > 0;
  const index = pvId.split('_')[1]

  // delete element from html
  $(`#${pvId}`).remove();

  // add remove "instuction" for rails to delete from server
  // if pv was loaded from the server
  if (fromServer) {
      const pvServerId = pvs[index]['pv_id'];
      createDestroyHiddenInput(pvServerId);
      pvsToDelete.push(pvServerId);
  }

  // remove from model
  pvs.splice(index, 1)

  // update elements order
  updatePvElement();
  updateSessionStorage();
}

function createDestroyHiddenInput (pvServerId) {
  let pvDestroyContainer = document.createElement('div')
  pvDestroyContainer.className = 'pv-destroy-container';
  pvDestroyContainer.id = `pv_${pvServerId}`;

  let destroyElement = document.createElement('input');
  destroyElement.id = `destroy_pv_${pvServerId}_id`;
  destroyElement.name = `pv[pv_components_attributes][${pvServerId}][id]`;
  destroyElement.hidden = true;
  $(destroyElement).attr('value', pvServerId);
  $(pvDestroyContainer).append(destroyElement);

  destroyElement = document.createElement('input');
  destroyElement.id = `destroy_pv_${pvServerId}_validation`;
  destroyElement.name = `pv[pv_components_attributes][${pvServerId}][_destroy]`;
  destroyElement.hidden = true;
  $(destroyElement).attr('value', 1);
  $(pvDestroyContainer).append(destroyElement);

  $("#pvs-container").append(pvDestroyContainer)
}

function editModeModal (pvId) {
  mode = 'edit';
  currentPv = pvId;

  const container = $(`#${pvId}`);
  const modalForm = $("#pv-modal .modal-body")

  $("#pv-modal").modal('toggle');
  // get values from pvs object
  // set to 0 for testing purposes
  const pv = pvs[currentPv.split('_')[1]]
  $(modalForm).find('input[type=number], input[type=text], select').each(function (index, el) {
    const id = $(el).attr('id');
    $(el).val(pv[id]);
  });

  // material
  const pvMaterial = pv['material']
  $(modalForm).find(`#${pvMaterial}`).prop('checked', true)
}

function getInputValues () {
  const modalForm = $("#pv-modal .modal-body");
  const container = $("#pvs-container");

  let pvContainer = null;
  let pvObject = null;

  if (mode === 'new') {
    pvContainer = createPvElement(`pv_${pvs.length}`);
    pvObject = { id: `${pvs.length}` };
  } else if (mode === 'edit') {
    pvContainer = $(`#${currentPv}`)
    pvObject = pvs[currentPv.split('_')[1]]
  }


  $(modalForm).find('input[type=number], input[type=text], select').each(function (index, el) {

    const inputElement = $(el);

    if (mode === 'new') {
      const hiddenInput = createHiddenInputElement(inputElement.attr('id'), inputElement.val())
      $(pvContainer).append(hiddenInput)
      pvObject[inputElement.attr('id')] = inputElement.val();
    } else if (mode === 'edit') {
      pvObject[inputElement.attr('id')] = inputElement.val();
      $(pvContainer).find(`#${inputElement.attr('id')}`).attr('value', inputElement.val())
    }

  });
  // material type
  const radioBtn = $(modalForm).find('input[type=radio]:checked');
  pvObject['material'] = radioBtn.val();
  if (mode === 'new') {
    $(pvContainer).append(createHiddenInputElement('material', radioBtn.val()));
  } else if (mode === 'edit') {
    $(pvContainer).find('#material').attr('value', radioBtn.val())
  }


  if (mode === 'new') {
    pvs.push(pvObject);
    $(container).append(pvContainer);
  } else if (mode === 'edit') {
    const index = pvs.findIndex((pv) => pv['id'] == currentPv.split('_')[1])
    pvs[index] = pvObject;
  }

  updateSessionStorage();
}

function resetModalForm () {
  $("#pv-modal .modal-body :input:not(:radio)").val('');
  $("#pv-modal .modal-body select").each(function (index, s) {
    const firstOption = $(s).find('option:first');
    $(s).val($(firstOption).val());
  });
}

function createHiddenInputElement(id, value) {
  let element = document.createElement('input');
  element.id = id;
  element.name = `pv[pv_components_attributes][${pvs.length}][${id}]`;
  $(element).attr('value', value);
  element.hidden = true;
  return element;
}

function createPvElement (id) {
  let pvContainer = document.createElement('div');
  pvContainer.className = "pv-container";
  pvContainer.id = id;
  pvContainer.innerHTML = id;
  $(pvContainer).append(createButtonElement('pv-1-edit', 'View', 'edit'));
  $(pvContainer).append(createButtonElement('pv-1-destroy', 'Destroy', 'destroy'));
  return pvContainer;
}

function createButtonElement (id, text, bType) {
   let button = document.createElement('button');
   button.innerHTML = text;
   $(button).addClass(`btn btn-link ${bType}-pv`);
   $(button).data('type', bType);
   button.type = 'button';
   if (bType === 'edit') {
     $(button).on('click', function () {
       const parentId = $($(this).parent()).attr('id');
       editModeModal(parentId);
     });
   } else if (bType === 'destroy') {
     $(button).on('click', function () {
       const parentId = $($(this).parent()).attr('id');
       destroyPv(parentId);
     });
   }
   return button;
}

// initialize pvs array for existing pvs for edit form
function loadExistingPvs () {
  $('.pv-container').each(function (index, element) {
    let pvObject = { id: index }
    $(this).find('input').each(function (index, input) {
      pvObject[$(input).attr('id')] = $(input).val()
    });
    pvs.push(pvObject)
  });
}

function editDestroyListeners () {
  $('.edit-pv').on('click', function () {
    const parentId = $($(this).parent()).attr('id');
    editModeModal(parentId);
  })

  $('.destroy-pv').on('click', function () {
    const parentId = $($(this).parent()).attr('id');
    destroyPv(parentId);
  })
}

// it stores key: value pairs where value is string
// so each time a change happens to the global pvs variable
// the value here should be updated
function updateSessionStorage (pvObject) {
  // object to json : sessionStorage only stores string getInputValues
  sessionStorage.setItem('pvs', JSON.stringify(pvs));
  sessionStorage.setItem('pvsToDelete', JSON.stringify(pvsToDelete));
}

function removePvFromSessionStorage () {

}

function clearSessionStorage () {

}

function getPvsNumber () {
  if (!sessionStorage.pvsForm) return 0;
  if (!sessionStorage.pvsForm.pvs) return 0;
  return sessionStorage.pvsForm.pvs.length;
}

// when deleting an element the next elements should be moved forward`
// to avoid problems with ids that do not match with the storage variable order
// make the adjustments
function updatePvElement () {
  const container = $("#pvs-container")
  const children = $(container).children().find('pv-container');

  children.each(function (index, el) {
    $(el).attr('id', `pv_${index}`);
    //el.innerHTML = `pv_${index}`;
  });

  $(pvs).each(function (index, el) {
    pvs[index]['id'] = index;
  });
}
