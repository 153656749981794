import produceErrorAlert from "../../helpers/error_producer";
import {createTableRow, createLink, createHiddenInput} from "../../modules/createElements";

const CONTAINER_ID = '.collapsible-menu'
let toggleOn = false

export default function initializeSitePvsIndex() {
    addMultiLCAListener()
    _addChangeTableListeners()

    _addBetaAnalysisListener()
    _addLoadMoreListener()
    _addShowLessListener()

    _fixDropdownOffset()

    _addSpinnerListener($('.dropdown-menu'))
};

function _addSpinnerListener(menus) {
  $(menus).find('a').on('click', function() {

    const parent = $(this).parent()[0]
    const dropdownBtnId = $(parent).attr('aria-labelledby')
    const dropdownBtn = $(`#${dropdownBtnId}`)
    const spinner = $(dropdownBtn).find('.spinner-border')
    $(spinner).show()
    $(dropdownBtn).attr('disabled', 'disabled')
  })
}

// https://liferayui.com/bootstrap-dropdown-hidden-issue-table/
function _fixDropdownOffset() {
  let dropdownMenu
  $('#site-pvs-table tbody').on('show.bs.dropdown', function (e) {
    dropdownMenu = $(e.target).find('.dropdown-menu');
    $('body').append(dropdownMenu.detach());
    let eOffset = $(e.target).offset();
    dropdownMenu.css({
        'display': 'block',
        'top': eOffset.top + $(e.target).outerHeight(),
        'left': eOffset.left,
        'line-height': '2.5rem'
    });
	dropdownMenu.addClass("mobPosDropdown");
 });

$('#site-pvs-table tbody').on('hide.bs.dropdown', function (e) {
    $(e.target).append(dropdownMenu.detach());
    dropdownMenu.hide();
 });
}

function _addLoadMoreListener() {
    const loadButton = $('#load-more-projects')
    loadButton.on('click', function () {
        const currentViewRows = $('#site-pvs-table table tbody tr').length
        $.ajax({
            url: '/b/load_more_site_pvs',
            data: {
                current_view_rows: currentViewRows,
            },
            method: 'GET',
            dataType: 'json',
            success: function (response, status, object) {
                if (status === 'success') {
                    addSitePvRows(response['site_pvs'])
                }
            }
        });
    })
}

function addSitePvRows (sitePvs) {
    const tableBody = $('#site-pvs-table tbody')
    const keys = Object.keys(sitePvs)
    for (let i = 0; i < keys.length; i++) {
        const sitePv = sitePvs[i]

        let actionsContainer = document.createElement('span')
        $(actionsContainer).append(createLink('Edit', sitePv['edit'], { class: 'bg-warning text-dark p-1 rounded' }))
        $(actionsContainer).append(' ')
        $(actionsContainer).append(createLink('Destroy', sitePv['destroy'], {
            class: 'bg-danger text-light p-1 rounded',
            data: { confirm: 'Are you sure?' }
        }))

        let analysisContainer = document.createElement('span')
        let analysisDiv = document.createElement('div')
        $(analysisDiv).css({ display: 'inline-block' })
        $(analysisDiv).append(_createAnalysisAction('LCA', sitePv['analysisParams']))
        $(analysisContainer).append(analysisDiv)

        $(analysisContainer).append(' ')

        analysisDiv = document.createElement('div')
        $(analysisDiv).css({ display: 'inline-block' })
        $(analysisDiv).append(_createAnalysisAction('LCC', sitePv['analysisParams']))
        $(analysisContainer).append(analysisDiv)

        let investmentPlan;
        console.log('The path is:', sitePv)
        if (sitePv['investmentName'] !== '-') investmentPlan = createLink(sitePv['investmentName'], sitePv['investmentPath'])
        else investmentPlan = '-'

        let disposalPlan
        const disposalInput = sitePv['disposalName']
        if(disposalInput !== undefined) {
          if(disposalInput !== '-') disposalPlan = createLink(sitePv['disposalName'], sitePv['disposalPath'])
          else disposalPlan = '-'
        } else {
          disposalPlan = undefined
        }

        let dotColor = 'red'
        if (sitePv['sensorsState']) dotColor = 'green'

        let sitePvColumns = ['<span class="dot-' + dotColor + '"></span>' + sitePv['id'], sitePv['site'],
                              createLink(sitePv['electricalName'], sitePv['electricalPath']),
                              createLink(sitePv['thermalName'], sitePv['thermalPath']),
                              investmentPlan,
                              actionsContainer,
                              // analysisContainer
                              _createExtraActionsDropdown(sitePv['id'])]

        if(disposalPlan !== undefined) {
          sitePvColumns.splice(5, 0, disposalPlan)
        }

        const sitePvRow = createTableRow(...sitePvColumns)

        const analysisTd = $(sitePvRow).find('td:last')
        analysisTd.addClass('beta-analysis')
        if (!toggleOn) analysisTd.css({ display: 'none' })

        $(tableBody).append(sitePvRow)
    }

    // add spinner listener
    console.log('ROW: ', $(tableBody).find('.dropdown-menu'))
    _addSpinnerListener($(tableBody).find('.dropdown-menu'))

}

function _createExtraActionsDropdown(sitePvId) {
  let dropdownDiv = document.createElement('div')
  $(dropdownDiv).addClass('dropdown')

  let dropdownBtn = document.createElement('button')
  dropdownBtn.type = 'button'
  $(dropdownBtn).addClass('btn btn-info dropdown-toggle')
  $(dropdownBtn).attr('id', `dropdownMenuButton_${sitePvId}`)
  $(dropdownBtn).attr('data-toggle', 'dropdown')
  $(dropdownBtn).attr('aria-haspopup', 'true')
  $(dropdownBtn).attr('aria-expanded', 'false')
  $(dropdownBtn).text(' View actions')

  let spinner = document.createElement('span')
  $(spinner).addClass('spinner-border spinner-border-sm')
  $(spinner).attr('role', 'status')
  $(spinner).attr('aria-hidden', 'true')
  $(spinner).attr('style', 'display: none')
  $(dropdownBtn).prepend(spinner)

  let dropdownMenu = document.createElement('div')
  $(dropdownMenu).addClass('dropdown-menu')
  $(dropdownMenu).attr('aria-labelledby',`dropdownMenuButton_${sitePvId}`)

  let exportDemandBtn = _createExportDemandButton(sitePvId)
  $(exportDemandBtn).addClass('dropdown-item')
  $(dropdownMenu).append(exportDemandBtn)

  let actionLink = document.createElement('a')
  $(actionLink).attr('href', `/b/download_kpis_from_sftp/${sitePvId}`)
  $(actionLink).text('Fetch from SFTP')
  $(actionLink).addClass('dropdown-item')
  $(actionLink).attr('data-method', 'post')
  $(dropdownMenu).append(actionLink)

  actionLink = document.createElement('a')
  $(actionLink).attr('href', `/b/upload_scenario_to_sftp/${sitePvId}`)
  $(actionLink).text('Send to SFTP')
  $(actionLink).addClass('dropdown-item')
  $(actionLink).attr('data-method', 'post')
  $(dropdownMenu).append(actionLink)

  actionLink = document.createElement('a')
  $(actionLink).attr('href', `/b/site_pvs/${sitePvId}/send_kpis_to_iti`)
  $(actionLink).text('Send to ITI')
  $(actionLink).addClass('dropdown-item')
  $(dropdownMenu).append(actionLink)

  $(dropdownDiv).append(dropdownBtn)
  $(dropdownDiv).append(dropdownMenu)

  return dropdownDiv
}

function _createExportDemandButton(sitePvId) {
    let button = document.createElement('button')
    button.type = 'button'
    $(button).data('href', `/b/site_pvs/${sitePvId}/export_demand_profile`)
    $(button).addClass('btn btn-sm btn-info')
    $(button).text('Export Demand')

    $(button).on('click', function(e) {
        e.preventDefault()
        window.open($(this).data('href'))
        window.focus()
    })

    return button
}

function _createAnalysisAction (analysis, params) {
    const lcaAction = document.createElement('form')
    const lcaParams = params
    if (analysis === 'LCA') lcaAction.action = params['lcaPath']
    else if (analysis === 'LCC') lcaAction.action = params['lccPath']
    lcaAction.method = 'get'
    lcaAction.classList = 'button_to'
    const lcaButton = document.createElement('input')
    if (analysis === 'LCA') lcaButton.classList = 'btn-sm btn-primary'
    else lcaButton.classList = 'btn-sm btn-success'
    lcaButton.type = 'submit'
    lcaButton.value = analysis

    $(lcaAction).append(lcaButton)
    $(lcaAction).append(createHiddenInput('', 'action_is', lcaParams['action_is']))
    $(lcaAction).append(createHiddenInput('', 'electrical_id', lcaParams['electrical_id']))
    $(lcaAction).append(createHiddenInput('', 'thermal_id', lcaParams['thermal_id']))
    $(lcaAction).append(createHiddenInput('', 'investment_plan_id', lcaParams['investment_plan_id']))
    $(lcaAction).append(createHiddenInput('', 'site_id', lcaParams['site_id']))
    $(lcaAction).append(createHiddenInput('', 'site_name', lcaParams['site_name']))

    return lcaAction
}

function _addShowLessListener () {
    const hideButton = $('#show-less-projects')
    hideButton.on('click', function () {

        const currentProjects = $('#site-pvs-table tbody tr').length

        if (currentProjects > 10) {
            const rowsToDelete = $(`#site-pvs-table tbody tr:gt(${currentProjects - 10})`)
            rowsToDelete.remove()
        }
    })
}

function _addBetaAnalysisListener() {
    const selector = '.beta-analysis'
    let rows = $(selector)
    rows.hide()
    const toggleButton = $('#toggle-beta-analysis')
    toggleButton.on('click', function () {
        rows = $(selector)
        rows.toggle()
        toggleOn = !toggleOn
    })
}

function addMultiLCAListener() {
    $("#multi-lca-form, #multi-lcc-form").on('submit', function (e) {
        e.preventDefault();
        const formIdStr = "#" + e.target.id;
        const checkboxesContainer = $(`${formIdStr} #comparison-select`).siblings()[0];
        const checked = $(checkboxesContainer).find(".sol-checkbox:checked");
        if (checked.length == 0) {
            if ($(".alert-includes").length) {
                $(`${formIdStr}`).children(':last').remove();
            }
            if (!$(".alert-empty").length) $(e.target).append(produceErrorAlert("Please select comparison scenarios", "alert-empty"));
        } else {
            const baselineId = $(`${formIdStr} .sol-radio:checked`).val();
            const comparisonIds = $(`${formIdStr} .sol-checkbox:checked`).map(function () {
                return $(this).val();
            });
            if (comparisonIds.toArray().includes(baselineId)) {
                if ($(".alert-empty").length) $(`${formIdStr}`).children(':last').remove();
                if (!$(".alert-includes").length) $(e.target).append(produceErrorAlert("Comparison list contains baseline", "alert-includes"));
            } else e.target.submit();
        }
    });
}

// export this into a module because it is widely used
function _addChangeTableListeners() {
    const pages = {
        "building": $('#site-pvs-table'),
        "district": $('#district-projects-table')
    }

    // initialization
    $('#show-building-page').prop('checked', true)
    _showPage(pages, 'building')
    _showMenu('building')

    $('input[type=radio]').on('click', function () {
        if (this.checked) _showPage(pages, $(this).val())
        _showMenu($(this).val())
    })
}

function _showPage(pages, selected) {
    pages[selected].show()
    for (let p in pages) if (p !== selected) pages[p].hide();
}

function _showMenu(selected) {
    $(`${CONTAINER_ID} .list-group :not(.${selected}-li)[class*=li]`).hide();
    $(`${CONTAINER_ID} .list-group .${selected}-li`).show();
}
