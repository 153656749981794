import addCheckboxesListeners from './components_listeners.js';
import clearFormElements from "../../helpers/inputs_cleaner";
import waterStorage from "./waterStorage";
import * as elementCreator from "../../modules/createElements";
import ComponentsHotWaterConnector from "./componentsHotWaterConnector";
import setUpSol from "./activeComponentsHelper";
import glazingForm from "./glazing_form";
import insulationForm from "./insulation_form";
import surfaceInputs from "./building_surface_form";
import customCopSeer from './custom_cop_seer';

export default function initializeThermalsForm() {

    addCheckboxesListeners();
    _initialize_sol_dropdowns()

    $(".reset-priorities").on('click', function () {
        const priorityFields = $(this).parents('table').find('*[id*=Priority]');
        priorityFields.each(function () {
            $(this).val('0');
        });
        $(this).blur();
    });

    // organize this better in another file
    const checkbox = $("#thermal_has_existing_components")
    $("#active_old_components_span").toggle(checkbox.prop('checked'));
    $("#passive_old_components_span").toggle(checkbox.prop('checked'));

    // water storage
    waterStorage()

    glazingForm()
    insulationForm()
    surfaceInputs()

    customCopSeer();
};

function _initialize_sol_dropdowns() {
    setUpSol()
}
