/* This file is used to perform actions that consider the interaction
   between active components and Hot Water Storage
*/
import * as elementCreator from "../../modules/createElements";

export default class ComponentsHotWaterConnector {
    static COMPONENTS_MAPPING = {
        'boiler__ngas': 'Ngas',
        'boiler__biomass': 'Biomass',
        'heatpump__air2air': 'Electricity',
        'boiler__oil': 'Diesel'
    }

    constructor(waterStorageForm, activeComponentsForm, existingComponents=null) {
        this.waterStorageForm = waterStorageForm
        this.activeComponentsForm = activeComponentsForm
        this.fuelInput = waterStorageForm.find('select[name*=Fuel]')
        this.powerInput = waterStorageForm.find('input[name*=Power]')

        this.addedComponents = []

        // if there are any existing components initialize them
        if (existingComponents !== null) {
            this.existingComponents = existingComponents
            // for (let i = 0; i < this.existingComponents.length; i++)
            //     this.addedComponents.push(this.existingComponents[i])

        }
        this.#_setUpInitialState()
        if (this.addedComponents.length === 0) this.#_enableMissingComponentsError()
    }

    addComponentOption (componentType) {
        const componentFuel = ComponentsHotWaterConnector.COMPONENTS_MAPPING[componentType]

        if (this.addedComponents.includes(componentType)) return

        if (componentFuel !== undefined) {
            let componentName = componentType.split('__')

            const existingOptions = $(this.fuelInput).find('option').toArray().map(e => $(e).text().toLowerCase())
            if (existingOptions.includes(componentType.replace('__', ' ').toLowerCase())) {
                this.addedComponents.push(componentType)
                this.#_disableMissingComponentsError()
                return
            }

            // TODO: This must go into a utils file
            for (let i = 0; i < 1; i++) {
                componentName[i] = componentName[i].charAt(0).toUpperCase() + componentName[i].slice(1);
            }
            const componentOption = elementCreator.createOption(componentName.join(' '), componentFuel)
            this.fuelInput.append(componentOption)

            // also add this new component to the already added components
            // so that it won't be added again
            this.addedComponents.push(componentType)

            this.#_disableMissingComponentsError()
        }
    }

    removeComponentOption (componentType) {
        // find the option
        const componentFuel = ComponentsHotWaterConnector.COMPONENTS_MAPPING[componentType]
        const componentOption = $(this.fuelInput).find(`option[value='${componentFuel}']`)
        // remove it
        componentOption.remove()
        // remove from added components
        const index = this.addedComponents.findIndex(e => e === componentType)
        if (index > -1)
            this.addedComponents.splice(index, 1)

        if (this.addedComponents.length === 0) this.#_enableMissingComponentsError()
    }

    updatePowerValue (component, value) {
        const selectedComponent = $(this.fuelInput).find('option:selected').text()
        if (selectedComponent.toLowerCase() === component.toLowerCase())
            $(this.powerInput).val(value)
    }

    #_setUpInitialState () {
        const selectedOption = $(this.fuelInput).find('option:selected').val()
        const notClause = `option:not([value=Electricity]):not([value=""]):not([value="${selectedOption}"])`
        $(this.fuelInput).find(notClause).remove()
        for (let i = 0; i < this.existingComponents.length; i++) {
            this.addComponentOption(this.existingComponents[i])
        }
    }

    #_updatePowerValuePlease (value) {
        this.powerInput.val(value)
    }

    findPowerAfterReload () {
        let powerValue = $(this.powerInput).attr('value')
        const selectedOption = $(this.fuelInput).find('option:selected').text().toLowerCase()
        if(selectedOption !== 'electricity') {
          const enabledComponents = $(this.activeComponentsForm).find('input[name*=Thermal]').toArray()
          const correspondingPowerInput = enabledComponents.find(e => $(e).attr('name')
              .toLowerCase()
              .includes(selectedOption.replace(' ', '__')))
          const powerValue = $(correspondingPowerInput).val()


        }
        this.#_updatePowerValuePlease(powerValue)
        return powerValue
    }

    #_enableMissingComponentsError () {
        const errorElement = this.waterStorageForm.find('.active-components-error')
        $(errorElement).show()
    }

    #_disableMissingComponentsError () {
        const errorElement = this.waterStorageForm.find('.active-components-error')
        $(errorElement).hide()
    }

}
