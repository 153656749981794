// Manages all actions in data management page for district projects
import loadCharts from "../../lca/results";

let projectId, projectName
let datepickers

export default function() {
  addProjectSelectListener()
  addPerformButtonListener()
  addCalendarFunctionality()
}

function addProjectSelectListener() {
  $('.list-project-item1').on('click', function(e) {
    projectId = $(e.target).data('project_id')
    projectName = e.target.textContent
    $('#dropdownMenuButton').text(projectName)
    fillAvailableDates()
  })
}

function addPerformButtonListener() {
  $('button[name="perform"]').on('click', function() {
    $('.text-danger').hide()
    // enable loading button
    const noProjectNote = $('#selected-project')
    noProjectNote.hide()
    const loadingSpinner = $('#loading-spinner')
    loadingSpinner.show()
    const previousResults = $('#analysis-results')
    if(previousResults) previousResults.remove()

    $.ajax({
      url: "/d/lca_analysis_select_project",
      type: "get",
      data: {
          "district_project_id": projectId,
          dates: {
            current: getInputDates(datepickers.current),
            planned: getInputDates(datepickers.planned)
          }
      },
      success: function(data) {

        $('#selected-project').replaceWith(data.html)
        loadingSpinner.hide()
        loadCharts()
        addCheckboxesFunctionality()

        $([document.documentElement, document.body]).animate({
            scrollTop: $("#analysis-results").offset().top
        }, 500);
      },
      error: function(data) {
        $('.text-danger').show()
        $(loadingSpinner).hide()
      }
    })
  })
}

function addCalendarFunctionality() {
  const datepickerElements = $('.datepicker')
  datepickers = {
    current: datepickerElements[0],
    planned: datepickerElements[1]
  }
  datepickerElements.flatpickr({ mode: "range"});
}

// TODO: Important -> Export this into another file
function getInputDates(picker) {
  const datesArray = $(picker).val().split('to')
  return { from: datesArray[0].trim(), to: datesArray[1].trim() }
}

// TODO: write this block better
function addCheckboxesFunctionality() {
  const environmentalCheckbox = $('#environmental')
  const environmentalPane = $('.environmental-pane')

  const costingCheckbox = $('#costing')
  const costingPane = $('.costing-pane')

  $(environmentalCheckbox).on('click', function() {
    $(environmentalPane).toggle(this.checked)
  })

  $(costingCheckbox).on('click', function() {
    $(costingPane).toggle(this.checked)
  })
}

function fillAvailableDates(){
  const datepickers = $('.datepicker')
  $.ajax({
    url: `/d/district_projects/${projectId}/available_dates`,
    type: "get",
    data: {},
    success: function(data) {
      flatpickr(datepickers[0], {
          mode: "range",
          dateFormat: "d-m-Y",
          enable: [
            {
                from: data.dates.current.from,
                to: data.dates.current.to
            }
        ]
      })

      flatpickr(datepickers[1], {
          mode: "range",
          dateFormat: "d-m-Y",
          enable: [
            {
                from: data.dates.planned.from,
                to: data.dates.planned.to
            }
        ]
      })
    },
    error: function(data) {
      console.log(data)
    }
  })
}
