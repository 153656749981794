export default function initializePricingForm() {

  // prosumer is selected
  const prosumerDiv = $("#div_prosumer_pricing_shceme");
  const standAloneDiv = $("#div_stand_alone");
  const fixedRadioButton = $("#fixed_radio_button");
  const touRadioButton = $("#tou_radio_button");
  let fixedFlag = fixedRadioButton.prop('checked');
  let touFlag = touRadioButton.prop('checked');

  const setupProsumerOptions = function () {

    const netMeteringFixedRadioButton = $("#net_metering_radio_button");
    const netBillingFixedRadioButton = $("#net_billing_radio_button");

    $("#div_charging_type").toggle(fixedRadioButton.prop('checked') || touRadioButton.prop('checked'));
    $("#div_net_metering").toggle(fixedRadioButton.prop('checked') && netMeteringFixedRadioButton.prop('checked'));
    $("#div_net_billing").toggle(fixedRadioButton.prop('checked') && netBillingFixedRadioButton.prop('checked'));
    $("#div_net_metering_tou").toggle(touRadioButton.prop('checked') && netMeteringFixedRadioButton.prop('checked'));
    $("#div_net_billing_tou").toggle(touRadioButton.prop('checked') && netBillingFixedRadioButton.prop('checked'));
  };

  if ($("#prosumer_radio_button").prop('checked')) {
    prosumerDiv.show();
    standAloneDiv.hide();
  } else if ($("#stand_alone_radio_button").prop('checked')) { // stand alone is selected
    clear_radio_buttons($('#div_prosumer_pricing_shceme'));
    clear_input_fields($('#div_prosumer_pricing_shceme'));
    prosumerDiv.hide();
    standAloneDiv.show();
  } else {
    prosumerDiv.hide();
    standAloneDiv.hide();
  }
  setupProsumerOptions();

  //---- event listeners

  //---- clear buttons
  $("#clear-table-pricing-tou-nm-btn").on("click", function() {
    clear_radio_buttons($('#tou_nm_table'));
  });
  $("#clear-table-pricing-tou-nb-btn").on("click", function() {
    clear_radio_buttons($('#tou_nb_table'));
  });

  $('#clear_tou_radio_button').click(function() {
    clear_option($(this));

    fixedFlag = touFlag = false;
  });

  $('#clear_charging_type_radio_button').click(function() {
    clear_option($(this));
    clear_input_fields($('#div_net_billing'));
    clear_input_fields($('#div_net_metering'));
  });
  //----

  // User Type Buttons
  $('#prosumer_radio_button').click(function() {
    $('#div_prosumer_pricing_shceme').toggle(true);
    clear_input_fields(standAloneDiv);
    standAloneDiv.hide();
  });

  $('#stand_alone_radio_button').click(function() {
    clear_radio_buttons($('#div_prosumer_pricing_shceme'));
    clear_input_fields($('#div_prosumer_pricing_shceme'));
    prosumerDiv.hide();
    setupProsumerOptions();
    standAloneDiv.show('fast');
  });

  // Pricing Method Buttons
  $('#fixed_radio_button').click(function() {
    $('#div_charging_type').toggle(true);
    if (!fixedFlag) {
      clear_radio_buttons($("#div_charging_type"));
      $("#div_net_metering_tou").hide('fast');
      $("#div_net_billing_tou").hide('fast');

      clear_input_fields($('#div_net_billing_tou'));
      clear_input_fields($('#div_net_metering_tou'));

      clear_radio_buttons($('#div_net_billing_tou'));
      clear_radio_buttons($('#div_net_metering_tou'));

      touFlag = false;
      fixedFlag = true;
    }
  });

  $('#tou_radio_button').click(function() {
    if (!touFlag) {
      $('#div_charging_type').toggle(true);
      // clear charging type selection
      clear_radio_buttons($("#div_charging_type"));
      // hide divs below
      $("#div_net_metering").hide('fast');
      $("#div_net_billing").hide('fast');

      clear_input_fields($('#div_net_billing'));
      clear_input_fields($('#div_net_metering'));

      fixedFlag = false;
      touFlag = true;
    }
  });

  // Charging Energy Program Buttons
  $('#net_metering_radio_button').click(function() {
    if (fixedRadioButton.prop('checked')) {
      // fixed
      $('#div_net_billing').hide('fast');
      $('#div_net_billing_tou').hide('fast');
      $('#div_net_metering_tou').hide('fast');
      $('#div_net_metering').show('fast');

      clear_input_fields("#div_net_billing");
    } else {
      // ToU
      $('#div_net_billing').hide('fast');
      $('#div_net_metering').hide('fast');
      $('#div_net_billing_tou').hide('fast');
      $('#div_net_metering_tou').show('fast');

      clear_input_fields("#div_net_billing_tou");
      clear_radio_buttons("#div_net_billing_tou");
    }
  });

  $('#net_billing_radio_button').click(function() {
    if (fixedRadioButton.prop('checked')) {
      // fixed
      $('#div_net_metering').hide('fast');
      $('#div_net_billing').show('fast');
      $('#div_net_billing_tou').hide('fast');
      $('#div_net_metering_tou').hide('fast');

      clear_input_fields("#div_net_metering");
    } else {
      // ToU
      $('#div_net_metering').hide('fast');
      $('#div_net_billing').hide('fast');
      $('#div_net_billing_tou').show('fast');
      $('#div_net_metering_tou').hide('fast');

      clear_input_fields("#div_net_metering_tou");
      clear_radio_buttons("#div_net_metering_tou");
    }
  });

  $('#clear_net_metering_radio_button').click(function() {
    $('#div_clear_billing').show('fast');
  });
};

// Helpers
// except includes elements that should not be deselected
function clear_radio_buttons(element) {
  var radioButtons = $(element).find('input[type="radio"]:checked');
  radioButtons.each(function(i, rBtn) {
    $(rBtn).prop('checked', false);
  });
}

function clear_input_fields(element) {
  var tableStripedFields = $(element).find('.table-striped input');
  tableStripedFields.each(function(i, field) {
    $(field).val(0);
  });

  var inputFields = $(element).find('input:not(:radio)').not('.table-striped input');
  inputFields.each(function(i, field) {
    $(field).val('');
  });
}

// index is the index of the selected element in the parent's divs children
function hide_following_divs(elements, index) {
  for (var i = index + 1; i < $(elements).length; i++) {
    $(elements[i]).hide('fast');
  }
}

function clear_option(button) {
  // hide elements below this level
  var parent_divs = $(button).parents();
  var grandparent = $(button).parents()[2];
  var siblings = $(grandparent).children();
  // uncheck every checked radio button
  for (var i = $(parent_divs[1]).index() + 1; i < parent_divs.length; i++) {
    clear_radio_buttons($(siblings[i]));
    clear_input_fields($(siblings[i]));
  }

  hide_following_divs(siblings, $(button).parent().parent().index());
}
