export default function clearFormElements(element, defaultValues=false) {
  element.find(':input').each(function() {
    switch(this.type) {
        case 'password':
        case 'text':
        case 'textarea':
        case 'file':
        case 'select-one':
        case 'select-multiple':
        case 'date':
        case 'number':
        case 'tel':
        case 'email':
          if (defaultValues) $(this).val('0');
            break;
        case 'checkbox':
        case 'radio':
            $(this).prop('checked', false).change();
            break;
    }
  });
}
