import consumer from "channels/consumer";

const colors = [
  '#f55f5f', '#0f67fa', '#3af652', '#ff8c00', '#daa520',
  '#800000', '#808000', '#f4a460', '#9acd32', '#008080',
  '#a0522d', '#bc8f8f', '#842929', '#29847b', '#84296d',
  '#458429', '#ffe93a', '#40ff3a'
]
var index = 0;

export default function initializeButtons(charts, tables) {
  const addButtons = $(".button-add-plot");
  const removeButtons = $(".button-remove-plot");
  const clearAllButton = $("#clear-charts-button");

  addButtons.each(function (i, btn) {
    addButtonListener(btn, charts, tables, i);
  });

  removeButtons.each(function (i, btn) {
    removeButtonListener(btn, charts, tables);
  });

  clearAllButton.on('click', function () {
    clearAllCharts(charts);
    clearAllTables(tables)
    $(".added").each(function (i, el) {
      $(el).removeClass("added");
    });
  });
};

function addButtonListener(btn, charts, tables, btnIndex) {

  $(btn).on('click', function () {
    const button = $(this);
    const projectName = $(this).data('name');
    const projectId = $(this).data('project_id');

    $(charts).each(function (i, chart) {
      chart.addLine(projectId, projectName, colors[btnIndex]);
      const projectTitleElement = button.parent().siblings()[0];
      $(projectTitleElement).addClass("added");
    });

    $(tables).each(function (i, table) {
      table.addTableRow({
        'project-id': projectId
      });
    });
    // communicate with server to get the latest measurements to plot
    $.ajax({
      url: "/latest_aggregated_measurements",
      data: {
        "site_pv_id": projectId,
        "count": 10
      },
      dataType: 'json',
      success: function(response, status, obj) {
        $(charts).each(function (i, chart) {
          const chartType = chart.getPlotType();
          $(response).each(function(j, row) {
            chart.addDataPoint(projectId, row['timestamp'], row[chartType]);
          });
        });

        $(tables).each(function (i, table) {
          $(response).each(function(j, row) {
            table.updateTableRow (projectId, row);
          });
        });
      }
    });

    // subscribe to channel
    subscribeToChannel(projectId, charts, tables);
  });
};

function removeButtonListener(btn, charts, tables) {
  $(btn).on('click', function () {
    const button = $(this);
    const projectName = $(this).data('name');
    const projectId = $(this).data('project_id');
    $(charts).each(function (i, chart) {
      chart.removeLine(projectId, projectName);
      const projectTitleElement = button.parent().siblings()[0];
      $(projectTitleElement).removeClass("added");
    });

    $(tables).each(function (i, table) {
      table.removeTableRow(projectId);
    });
  });
};

function clearAllCharts(charts) {
  $(charts).each(function (i, chart) {
    chart.clearChart();
  });
}

function clearAllTables(tables) {
  $(tables).each(function(i, table) {
    $(table.tableBody).find('tr').remove()
  })
}

function subscribeToChannel(projectId, charts, tables) {
  consumer.subscriptions.create({channel: "AggregatedMeasurementsChannel", id: projectId}, {
    connected() {
      // Called when the subscription is ready for use on the server
      console.log(`Connected to channel for project with id: ${projectId}`);
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      console.log(data);
      $(charts).each(function (i, chart) {
        const chartType = chart.getPlotType();
        $(data).each(function(j, row) {
          chart.addDataPoint(projectId, row['timestamp'], row[chartType]);
        });
      });

      $(tables).each(function (i, table) {
        table.updateTableRow (projectId, data);
      });
    }
  });
};
