/*
    This file takes care of the following function:
    When Insulation is provided, disable External wall surface from building definition
    and auto-complete it using the sum of Insulation surfaces
    Do the same for glazing and Glazing surface
*/

let glazingSurfaceInput, wallSurfaceInput
let glazingCheckbox, insulationCheckbox

export default function () {
    _setInputs()
    _setCheckboxes()
    _loadInitialState()
    _addListeners()
}

function _setInputs() {
    glazingSurfaceInput = $('#thermal_glazing_surface')[0]
    wallSurfaceInput = $('#thermal_external_surface')[0]
}

function _setCheckboxes() {
    glazingCheckbox = $('#box-glazing')[0]
    insulationCheckbox = $('#box-insulation')[0]
}

function _loadInitialState() {
    if(glazingCheckbox.checked) _disableInput(glazingSurfaceInput)
    if(insulationCheckbox.checked) _disableInput(wallSurfaceInput)
}

function _addListeners() {
    $(glazingCheckbox).on('click', _onClick)
    $(insulationCheckbox).on('click', _onClick)
}

function _onClick() {
    if(this.checked) _onEnableComponent(this)
    else _onDisableComponent(this)
}

// typeOfComponent: 'insulation' or 'glazing'
function _onEnableComponent(checkbox) {
    if(checkbox === glazingCheckbox) _disableInput(glazingSurfaceInput)
    else if (checkbox === insulationCheckbox) _disableInput(wallSurfaceInput)
}

function _enableInput(input) {
    $(input).removeAttr('disabled')
    const infoSpan = $(input).parent().siblings('span')
    infoSpan.hide()
}

function _onDisableComponent(checkbox) {
    if(checkbox === glazingCheckbox) _enableInput(glazingSurfaceInput)
    else if (checkbox === insulationCheckbox) _enableInput(wallSurfaceInput)
}

function _disableInput(input) {
    $(input).attr('disabled', 'disabled')
    $(input).val('')
    $(input).removeClass('is-valid')
    const infoSpan = $(input).parent().siblings('span')
    infoSpan.show()
}