export default class TableHelper {
  constructor() {
    this.tables = {};
    this.datasets = {};
    this.headers = {};
  }

  initTables(tableType) {
    const tableElements = document.querySelectorAll(".dynamic-table");

    if (tableElements.length > 0) {
      tableElements.forEach((tableElement) => {
        const { table_id: tableId, table_type: tt } = tableElement.dataset;
        if (tableType === tt) {
          this.tables[tableId] = tableElement;
          if (!this.datasets[tableId]) {
            this.datasets[tableId] = {
              // 34: { Oil: 22, "Wind on": 123 },
              // 35: { Oil: 55, "Wind on": 123 },
            };
          }

          this.repaintTable(tableId);
        }
      });
    }
  }

  addNewRow(tableId, projectId, name) {
    if (this.datasets[tableId] && !this.datasets[tableId][projectId]) {
      this.datasets[tableId][projectId] = { name: name};
      this.repaintTable(tableId);
    }
  }

  addNewPoint(tableId, projectId, label, value) {
    // console.log(
    //   "the table is",
    //   this.datasets[tableId],
    //   "tableId=",
    //   tableId,
    //   "label=",
    //   label,
    //   "value=",
    //   value
    // );
    if (this.datasets[tableId] && this.datasets[tableId][projectId]) {
      this.datasets[tableId][projectId][label] = value;
      this.repaintTable(tableId);
    }
  }

  setHeaders(tableId) {
    if (Object.values(this.datasets[tableId]).length == 0) {
      return;
    }
    this.headers[tableId] = Object.keys(
      Object.assign(...Object.values(this.datasets[tableId]))
    );
    // console.log("The headers are ", this.headers[tableId]);
  }

  repaintTable(tableId) {
    this.setHeaders(tableId);
    this.tables[tableId].innerHTML = "";
    const tr = this.tables[tableId].insertRow(-1);
    // console.log("the headers are ", this.headers[tableId]);
    // console.log("the data are ", this.datasets[tableId]);

    if (this.headers[tableId]) {
      ["Project ID", ...this.headers[tableId]].forEach((headerCell) => {
        const th = document.createElement("th"); // table header.
        th.innerHTML = headerCell;
        tr.appendChild(th);
      });
    }

    // console.log(
    //   "asads",
    //   this.datasets[tableId].length,
    //   tableId,
    //   this.datasets,
    //   this.datasets[tableId]
    // );
    if (Object.values(this.datasets[tableId]).length > 0) {
      this.setHeaders(tableId);

      Object.entries(this.datasets[tableId]).forEach((entry) => {
        const projectId = entry[0];
        const dataRow = entry[1];
        this.insertHtmlRow(
          this.tables[tableId],
          this.headers[tableId],
          projectId,
          dataRow
        );
      });
    }
  }

  insertHtmlRow(tableElement, headers, projectId, dataRow) {
    const tr = tableElement.insertRow(-1);
    const th = document.createElement("th");
    th.innerHTML = projectId;
    tr.appendChild(th);
    headers.forEach((header) => {
      const td = document.createElement("td");
      td.innerHTML = dataRow[header];
      tr.appendChild(td);
    });
  }

  // clearPlot(plotId) {
  //   this.datasets[plotId] = [];
  //   this.charts[plotId].config.data = { datasets: this.datasets[plotId] };
  //   this.charts[plotId].update();
  // }
}
