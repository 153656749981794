import HoursSelectorsController from './hours_selector';
// A class that represents the Season/Period components of the pricing scheme tool
// It contains useful functions to add reactivity to the form and maintain its current state

export default class SeasonPricingContainer {
  constructor(htmlElement, scheme, season, disabledPriceInputs=[]) {
    this.seasonContainer = htmlElement
    this.periodsTable = $(this.seasonContainer).find('table.periods-container')
    this.scheme = scheme
    this.season = season
    this.disabledPriceInputs = []

    if(scheme === 'tou') this.maxPeriods = 3
    else this.maxPeriods = 1

    this.currentPeriods = this.#getPeriodsNumber()
    this.hoursSelector = new HoursSelectorsController()

    this.#initializePriceInputs(disabledPriceInputs)

    // TODO: Find all conditional code and then try to split into subclasses.
    //       The parent class will only have common funcitonality.
    if(this.#isTou()) {
      const hoursSelectors = Array.from($(this.periodsTable).find('.hours-selector'))
      for(let i in hoursSelectors) {
        const hoursSelector = hoursSelectors[i]
        this.hoursSelector.addHourSelector(hoursSelector)
      }


      const addPeriodButton = $(this.seasonContainer).find('button.add-period')
      $(addPeriodButton).on('click', this.#onAddPeriod.bind(this))
      const removePeriodButton = $(this.seasonContainer).find('button.remove-period')
      $(removePeriodButton).on('click', this.#onRemovePeriod.bind(this))
    }
  }

  // Public methods
  togglePriceInputs(searchTerm, show) {
    const index = this.#getInputIndex(searchTerm)
    const header = $(this.periodsTable).find(`th:nth-child(${index})`)
    const inputCells = $(this.periodsTable).find(`td:nth-child(${index})`)
    const column = $(header).add(inputCells)
    $(column).toggle(show)

    if(!show) {
      if(!this.disabledPriceInputs.includes(searchTerm)) {
        this.disabledPriceInputs.push(searchTerm)
      }
    } else {
      this.disabledPriceInputs.filter(m => m !== searchTerm)
    }
  }

  // Private helper methods
  #initializePriceInputs(disablePriceInputs=[]) {
    if(this.#isFixed()) {
      this.togglePriceInputs('hours', false)
    }
    if(disablePriceInputs.length > 0) {
      for(let i in disablePriceInputs) {
        const searchTerm = disablePriceInputs[i]
        this.togglePriceInputs(searchTerm, false)
      }
    }
  }

  // ---------------- (START) Time of Use only ---------------------- //

  #onAddPeriod() {
    if(this.currentPeriods < 3) {
      const newPeriodElement = this.#createPeriodClone(`P${this.currentPeriods + 1}`)
      this.#completeInputNames(this, newPeriodElement, `P${this.currentPeriods + 1}`)

      // TODO: It may be a better option to declare this in the constructor
      const periodsContainer = $(this.periodsTable).find('tbody')[0]
      $(periodsContainer).append(newPeriodElement)

      // after it is added, initialize its hours selector
      const periodContainer = $(periodsContainer).find('tr')[this.currentPeriods]
      const hoursSelector = $(periodContainer).find('.hours-selector')
      this.hoursSelector.addHourSelector(hoursSelector)

      // TODO: Remove code duplication
      for(let i in this.disabledPriceInputs) {
        const searchTerm = this.disabledPriceInputs[i]
        this.togglePriceInputs(searchTerm, false)
      }

      this.#updatePeriodsCounter()
    }
  }

  #onRemovePeriod() {
    if(this.currentPeriods > 1) {
      const periodsContainer = $(this.periodsTable).find('tbody')[0]
      const periodToRemove = $(periodsContainer).children().last()
      $(periodToRemove).remove()

      this.hoursSelector.removeHourSelector(this.currentPeriods - 1)
      this.#updatePeriodsCounter(-1)
    }
  }

  #createPeriodClone(period) {
    const template = $('#period-template')[0]
    let clone = template.content.cloneNode(true)

    let header = $(clone).find('td:first')[0]
    header.textContent = period

    return clone
  }

  #completeInputNames(obj, clone, period) {
    const inputs = $(clone).find('input, select')
    $(inputs).each(function(_, input) {
      const template_name = input.name.replace('__season__', obj.season).replace('__period__', period)
      input.id = template_name
      input.name = template_name
    })
  }

  #updatePeriodsCounter(i=1) {
    this.currentPeriods += i
  }

  #getPeriodsNumber() {
    const periodsRows = $(this.periodsTable).find('tbody tr')
    return periodsRows.length
  }

  // ---------------- (END) Time of Use only ---------------------- //

  #isFixed() {
    return this.scheme === 'fixed'
  }

  #isTou() {
    return !this.#isFixed()
  }

  #getInputIndex(searchTerm) {
    switch (searchTerm) {
      case 'hours':
        return 2
        break
      case 'import':
        return 3
        break
      case 'export':
        return 4
        break
      case 'access':
        return 5
        break
      case 'buyback':
        return 6
        break
      default:
        return -1
    }
  }
}
