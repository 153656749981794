import clearFormElements from "../../../helpers/inputs_cleaner";

// find a better name for this file, one that includes all three matrices
// and make all the initializations in here
export default function initializeConsumptionForm () {
  addListeners();
  initializeState();
};

function addListeners () {
  const annualButton = $("#annual_consumption_radio_button");
  const monthlyButton = $("#monthly_consumption_radio_button");
  annualButton.on('click', function () {
    $("#annual-consumption-input").toggle(annualButton.prop('checked'));
    $("#monthly-consumption-input").toggle(!annualButton.prop('checked'));
    if (annualButton.prop('checked')) clearFormElements($("#monthly-consumption-input"), true);
  });
  monthlyButton.on('click', function () {
    $("#annual-consumption-input").toggle(!monthlyButton.prop('checked'));
    $("#monthly-consumption-input").toggle(monthlyButton.prop('checked'));
    if (monthlyButton.prop('checked')) clearFormElements($("#annual-consumption-input"), true);
  });
}

function initializeState () {
  const annualButton = $("#annual_consumption_radio_button");
  const monthlyButton = $("#monthly_consumption_radio_button");
  if (annualButton.prop('checked') || monthlyButton.prop('checked')) {
    $("#annual-consumption-input").toggle(annualButton.prop('checked'));
    $("#monthly-consumption-input").toggle(!annualButton.prop('checked'));
  }
}
