export default function initializeGeneralInformation() {
  showCheckedBoxes();
  addCheckboxesListeners();
  addGridConnectedListener();
};

function showCheckedBoxes() {
  if ($("#pv_has_electric_storage:checked, #pv_has_electric_consumption:checked, #pv_has_electric_generation:checked").length > 0) {
    if ($("#pv_has_electric_storage").is(":checked")) {
      $("#storage-details").show();
    }
    if ($("#pv_has_electric_consumption").is(":checked")) {
      $("#consumption-details").show();
    }
    if ($("#pv_has_electric_generation").is(":checked")) {
      $("#production-details").show();
      // toggleAutonomousAndProsumer(false)
    }
    $($("#production-details").parents()[3]).show();
  }

  if(!$("#pv_has_electric_generation").is(":checked")) {
    // toggleAutonomousAndProsumer(true)
  }
}

function addCheckboxesListeners() {
  $("#pv_has_electric_generation").on('click', function() {
    checkParent();
    $("#production-details").toggle();
    clearInputs($("#production-details"));
    // toggleAutonomousAndProsumer(!$(this).is(':checked'))
  });

  $("#pv_has_electric_consumption").on('click', function() {
    checkParent();
    $("#consumption-details").toggle();
    clearInputs($("#consumption-details"));

  });

  $("#pv_has_electric_storage").on('click', function() {
    checkParent();
    $("#storage-details").toggle();
    clearInputs($("#storage-details"));
  });
}

function checkParent() {
  if ($("#pv_has_electric_storage:checked, #pv_has_electric_consumption:checked, #pv_has_electric_generation:checked").length > 0)
    $($("#storage-details").parents()[3]).show();
  else {
    $($("#storage-details").parents()[3]).hide();
  }
}

function clearInputs(group) {
  // clear select inputs
  $(group).find('select, input[type=text], input[type=number]').val('');
  $(group).find('input[type=radio]').prop('checked', false);
}

// TODO: The inputs are already queried in another parts of code.
//       They should be queried only once. This requires a major refactor
//       of the PV's form javascript. Elements should be queried once and shared
//       between various actions.
function addGridConnectedListener(){
  const input = $('#pv_is_grid_connected')
  const standAloneInput = $('#autonomous_radio_button')
  const consumerInput = $('#consumer_radio_button')
  const prosumerInput = $('#prosumer_radio_button')

  toggleAutonomousInput({ target: input }, standAloneInput, null, null)
  $(input).on('click', function(e) { toggleAutonomousInput(e, standAloneInput, consumerInput, prosumerInput) })
}

function toggleAutonomousInput(e, standAloneInput, consumerInput, prosumerInput) {
  if($(e.target).is(':checked')) {
    $(standAloneInput).prop('checked', false)
    $(standAloneInput).attr('disabled', 'disabled')
    // in order for the listener of prosumer to be fired
    const consumerChecked = $(consumerInput).is(':checked')
    const prosumerChecked = $(prosumerInput).is(':checked')
    if(!consumerChecked && !prosumerChecked)
      $(consumerInput).click()
  } else {
    $(standAloneInput).removeAttr('disabled')
  }
}

function toggleAutonomousAndProsumer(show) {
  const standAloneInput = $('#autonomous_radio_button')
  const prosumerInput = $('#prosumer_radio_button')
  const consumerInput = $('#consumer_radio_button')
  const allButtons = $(standAloneInput).add(prosumerInput)
  if(show) {
    $(allButtons).prop('checked', false)
    $(allButtons).attr('disabled', 'disabled')
    // $(consumerInput).click()
  } else {
    $(allButtons).removeAttr('disabled')
  }
}
