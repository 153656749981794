// Manages all actions in data management page for district projects
export default function() {
  window.history.replaceState({}, document.title, "/d/" + "data_management");
  $('.list-project-item1').on('click', function(e) {
    const projectId = $(e.target).data('project_id')
    const projectName = e.target.textContent
    $.ajax({
      url: "/d/data_management_select_project",
      type: "get",
      data: {
          "district_project_id": projectId,
      },
      success: function(data) {
        $('#selected-project').replaceWith(data.html)
        $('#dropdownMenuButton').text(projectName)
        $([document.documentElement, document.body]).animate({
            scrollTop: $("#project-devices").offset().top
        }, 500);
      },
      error: function(data) {}
    })
  })
}
