import Chart from 'chart.js';
import 'chartjs-plugin-colorschemes';

const chartConfig = {
    type: 'line',
    data: {
        datasets: [],
    },
    options: {
        scales: {
            xAxes: [
                {
                    type: 'time', // MANDATORY TO SHOW YOUR POINTS! (THIS IS THE IMPORTANT BIT)
                    lineThickness: 1,
                    display: true, // mandatory
                    scaleLabel: {
                        display: true, // mandatory
                        labelString: 'Time', // optional
                        fontColor:  "#000000"

                    }, ticks: {
                        fontColor: "#000000", // this here
                    },
                },
            ],
        },
    },
};

export default class ChartHelper {
    constructor() {
        this.charts = {};
        this.datasets = {};
    }

    initPlots(plot_type) {
        const canvases = document.querySelectorAll('.chart_area_canvas');

        if (canvases.length > 0) {
            canvases.forEach((canvas) => {
                const { plot_id: plotId, plot_type: pt  } = canvas.dataset;
                if (plot_type === pt) {
                    console.log('The plot id is ', plotId);
                    const ctx = canvas.getContext('2d');
                    // Clone the config, so that multiple plots may work independently
                    if (!this.datasets[plotId]) {
                        this.datasets[plotId] = [];
                    }
                    this.charts[plotId] = new Chart(ctx, {
                        ...chartConfig,
                        data: {
                            datasets: this.datasets[plotId],
                        },
                        options: {
                            ...chartConfig.options,
                            scales: {
                                ...chartConfig.options.scales,
                                yAxes: [
                                    {
                                        ticks: {
                                            beginAtZero: true,
                                            fontColor: "#000000", // axes color
                                        },
                                        scaleLabel: {
                                            display: true, // mandatory
                                            labelString: plot_type, // optional
                                            fontColor:  "#000000"
                                        }
                                    },
                                ]

                            },
                        }
                    });
                }
            });
        }
    }

    addNewLine(plotId, projectId, name, color) {
        let found = false;
        this.datasets[plotId].forEach((dataset) => {
            if (dataset.projectId === projectId) {
                found = true;
            }
        });
        if (!found) {
            this.datasets[plotId].push({
                label: name,
                data: [],
                borderWidth: 2,
                //color: "rgba(54,158,173,.7)",
                backgroundColor: color,
                projectId,
                borderColor: "#030403",
            });
            this.charts[plotId].update();
        }
    }

    addNewPoint(plotId, projectId, x, y) {
        this.datasets[plotId].forEach((dataset) => {
            if (dataset.projectId === projectId) {
                dataset.data.push({
                    x: new Date(x),
                    y: y,
                });
            }
        });
        this.charts[plotId].update();
    }

    clearPlot(plotId) {
        this.datasets[plotId] = [];
        this.charts[plotId].config.data = { datasets: this.datasets[plotId] };
        this.charts[plotId].update();
    }
}
