export default function initializeDevicesIndex () {
  const toggles = $(".sensors-toggle");

  $(toggles).on('click', function () {
    const inputEl = $(this);
    const projectId = $(this).data('project_id');
    const state = $(this).is(':checked');

    $.ajax({
      url: '/toggle_sensors_state',
      data: {
        projectId,
        state,
        authenticity_token: $('[name="csrf-token"]')[0].content},
        method: 'POST',
        dataType: 'json',
        success: function (response, status, object) {
          if (status == 'success') {
            const container = $(inputEl).closest('.sensors-state')
            const stateText = $(container).children()[0];
            $(stateText).remove();
            $(container).prepend(createUpdateStateSpan(state));
          }
        }
    });
  });

 const modalForm = $('#projectInfoModal form')[0]
  $('.upload-csv').on('click', function() {
    $(modalForm).attr('action', $(this).data('project-id'))
  })
}

function createUpdateStateSpan (state) {
  let txtFirst = 'Enabled';
  let txtSecond = 'Toggle to disable';
  if (!state) {
    txtFirst = 'Disabled';
    txtSecond = 'Toggle to enable';
  }
  let updatedSpan = document.createElement('span');
  let txtStrongElement = document.createElement('strong');
  txtStrongElement.innerHTML = txtFirst;
  $(updatedSpan).append(txtStrongElement);
  $(updatedSpan).append(' - ');
  $(updatedSpan).append(txtSecond);
  return updatedSpan;
}
