export default function initializeLandForm () {

    if ($("#id_rent_land").prop('checked')) $("#div_rent_land").show();
    else if ($("#id_purchase_land").prop('checked')) $("#div_purchase_land").show();

    $('#id_own_land').click(function () {
        $('#div_rent_land').hide('fast');
        $('#div_purchase_land').hide('fast');
        $('#div_own_land').show('fast');
    });
    $('#id_rent_land').click(function () {
        $('#div_own_land').hide('fast');
        $('#div_purchase_land').hide('fast');
        $('#div_rent_land').show('fast');
    });
    $('#id_purchase_land').click(function () {
        $('#div_own_land').hide('fast');
        $('#div_rent_land').hide('fast');
        $('#div_purchase_land').show('fast');
    });
};
