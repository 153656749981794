import SeasonPricingContainer from './season_pricing_container'

let autonomousButton, consumerButton, prosumerButton
let fixedButton, touButton
let hasMeteringButton, meteringPolicySelect, fixedChargesInput
let fuelPriceInput, fuelPriceContainer
let fixedSeasonContainer, touSeasonContainer
let fixedContainerObject, winterContainerObject, summerContainerObject

export default function() {
  initializeVariables()
  initializeState()
  setUpListeners()
}

function initializeVariables() {
  autonomousButton = $('#autonomous_radio_button')
  consumerButton = $('#consumer_radio_button')
  prosumerButton = $('#prosumer_radio_button')

  fixedButton = $('#fixed_radio_button')
  touButton = $('#time_of_use_radio_button')

  hasMeteringButton = $('input[id*=metering]')
  meteringPolicySelect = $('select[id*=net_policy]')[0]
  fixedChargesInput = $('input[id*=fixed_charges]')

  fuelPriceInput = $('input[id*=fuel_price]')
  fuelPriceContainer = ('#fuel_price_container')

  const seasonContainers = $('.seasons-container')
  fixedSeasonContainer = seasonContainers[0]
  touSeasonContainer = seasonContainers[1]

  fixedContainerObject = new SeasonPricingContainer($(fixedSeasonContainer).find('.season-container')[0], 'fixed', 'Yearly')
  winterContainerObject = new SeasonPricingContainer($(touSeasonContainer).find('.season-container')[0], 'tou', 'Winter')
  summerContainerObject = new SeasonPricingContainer($(touSeasonContainer).find('.season-container')[1], 'tou', 'Summer')
}

function setUpListeners() {
  $(autonomousButton).on('click', onAutonomousClick)
  $(consumerButton).on('click', onConsumerClick)
  $(prosumerButton).on('click', onProsumerClick)
  $(fixedButton).on('click', onFixedClick)
  $(touButton).on('click', onTouClick)
  $(hasMeteringButton).on('click', onHasMeteringClick)
  $(meteringPolicySelect).on('change', onMeteringPolicyChange)
}

function initializeState() {
  // check what is clicked and if it is clicked then apply its action
  if(autonomousButton.is(':checked')) onAutonomousClick()
  if(consumerButton.is(':checked')) onConsumerClick()
  if(prosumerButton.is(':checked')) onProsumerClick()
  if(fixedButton.is(':checked')) onFixedClick()
  if(touButton.is(':checked')) onTouClick()
  onMeteringPolicyChange({ target: meteringPolicySelect })
}

function onAutonomousClick() {
  togglePricingShcemeInpus(false)
  toggleDisableInput($(hasMeteringButton).add(meteringPolicySelect).add(fixedChargesInput), true)
  toggleContainer($(fixedSeasonContainer).add(touSeasonContainer), false)
  $(fuelPriceContainer).toggle(true)
}

function onConsumerClick() {
  togglePricingShcemeInpus(true)
  toggleDisableInput($(hasMeteringButton), true, false)
  toggleDisableInput($(meteringPolicySelect), true)
  toggleDisableInput(fixedChargesInput, false)
  $(fuelPriceContainer).toggle(false)
  const seasonContainers = [fixedContainerObject, winterContainerObject, summerContainerObject]
  for(let i in seasonContainers) {
    const container = seasonContainers[i]
    container.togglePriceInputs('export', false)
    container.togglePriceInputs('buyback', false)
  }
}

function onProsumerClick() {
  togglePricingShcemeInpus(true)
  toggleDisableInput($(hasMeteringButton), false, false)
  toggleDisableInput($(meteringPolicySelect).add(fixedChargesInput), false)
  $(hasMeteringButton).prop('checked', true)
  toggleNoneMeteringOption(false)
  $(fuelPriceContainer).toggle(false)
}

function onFixedClick() {
  toggleContainer(touSeasonContainer, false)
  toggleContainer(fixedSeasonContainer, true)
}

function onTouClick() {
  toggleContainer(fixedSeasonContainer, false)
  toggleContainer(touSeasonContainer, true)
}

function onHasMeteringClick(e) {
  e.preventDefault()
}

function onMeteringPolicyChange(e) {
  const selectedValue = e.target.value
  const lowerCaseValue = selectedValue.toLowerCase()
  let showExportPrice = lowerCaseValue.includes('billing')
  let showBuyback = lowerCaseValue.includes('buyback')
  const seasonContainers = [fixedContainerObject, winterContainerObject, summerContainerObject]
  for(let i in seasonContainers) {
    const container = seasonContainers[i]
    container.togglePriceInputs('export', showExportPrice)
    container.togglePriceInputs('buyback', showBuyback)
  }
}

// ------------------------------------------
// Helper methods but pricing scheme specific
function togglePricingShcemeInpus(show) {
  const inputs = $(fixedButton).add(touButton)
  toggleDisableInput(inputs, !show, false)
}

// Helper methods (export to file? maybe in a utils files to be used by other files too)
function toggleDisableInput(input, disable, resetValue=true) {
  if(disable) {
    $(input).attr('disabled', 'disabled')
    if(resetValue) {
      $(input).val(null)
    }
    $(input).prop('checked', false)
  } else {
    $(input).removeAttr('disabled')
  }
}

function toggleContainer(container, show) {
  const inputs = $(container).find('input')
  if(show) {
    $(inputs).removeAttr('disabled')
    $(container).show()
  } else {
    $(inputs).attr('disabled', 'disabled')
    $(container).hide()
  }
}

function toggleNoneMeteringOption(show) {
  const options = $(meteringPolicySelect).find('option')
  const noneOption = options[0]
  const firstOption = options[1]
  toggleDisableInput(noneOption, !show)
  if(!meteringPolicySelect.value)
    $(meteringPolicySelect).val(firstOption.value)
}
