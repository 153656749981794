import insulationForm from './insulation_form';

export default function() {
  _setupListeners()
}

function _setupListeners() {
  _setupAddLineListener()
  _setupRemoveLineListener()
}

function _setupAddLineListener() {
  const addButtons = $(".addLine")
  addButtons.on('click', {
    event: $(this)
  }, _onAddLine)
}

function _setupRemoveLineListener() {
  const removeButtons = $(".removeLine")
  removeButtons.on('click', {
    event: $(this)
  }, _onRemoveLine)
}

// TODO: Change Id too
function _onAddLine(event) {
  const glazingTable = $(event.target).siblings('table')
  const lastLine = $(glazingTable).find('tbody tr').last()
  const newLine = $(lastLine).clone()
  $(newLine).find('input, select').each(function() {
    $(this).attr('name', _newInputName($(this).attr('name'), lastLine.index()))
    $(this).attr('id', _newInputName($(this).attr('id'), lastLine.index()))
  })
  $(glazingTable).find('tbody').append(newLine)

  insulationForm()
}

function _newInputName(original, index) {
  // TODO: Make this more beautiful
  let inputType = ''
  if (original.indexOf('opening') > 0) {
    inputType = 'opening'
  } else if (original.indexOf('insulation') > 0) {
    inputType = 'insulation'
  }
  return original.replace(`${inputType}_${index}`, `${inputType}_${index + 1}`)
}

function _onRemoveLine(event) {
  const glazingTable = $(event.target).siblings('table')
  const lastLine = $(glazingTable).find('tbody tr').last()
  if (lastLine.index() > 0) $(lastLine).remove()
}

function _duplicateLine() {

}

function _removeLastLine() {

}
