import disposalPlanForm from './disposal_plans/form'

let currentPath, page

export default function(path) {
  currentPath = path
  setPage()
  loadScript()
}

function setPage() {
  if(currentPath.includes('/disposal_plans')) {
    if(currentPath.includes('/edit')) page = 'edit_disposal_plan'
    if(currentPath.includes('/new')) page = 'new_disposal_plan'
  }
}

function loadScript() {
  if(page == 'edit_disposal_plan' || page == 'new_disposal_plan') {
    disposalPlanForm()
  }
}
