import BuildingSectorForm from "./buildingSectorForm";
import ResFarmForm from "./resFarmForm";

export default class ScenarioForm {

    constructor(scenarioElement, index) {
        this.scenarioElement = scenarioElement
        this.index = index

        const buildingSectorElement = $(scenarioElement).find('.buildings-subform')
        this.buildingSector = new BuildingSectorForm(buildingSectorElement, scenarioElement)

        const resFarmsElement = $(scenarioElement).find('.res_farms-subform')
        this.resFarmsSector = new ResFarmForm(resFarmsElement, scenarioElement)

        this.#addAbstractComponents()

        this.expandableSectors = $(scenarioElement).find('.expandable-sector')
        this.#setUpExpandableSectorsListeners({ showSingle: true })
    }

    get scenarioEl () {
        return this.scenarioElement
    }

    #addAbstractComponents () {
        // Re-using the class ResFarms
        // TODO: Rename class or create another which shares the same parent class
        const lightingElement = $(this.scenarioElement).find('.lighting-subform')
        this.lightingSector = new ResFarmForm(lightingElement, this.scenarioElement, 'lighting')

        const chargingStationsElement = $(this.scenarioElement).find('.cs-subform')
        this.chargingStationsSector = new ResFarmForm(chargingStationsElement, this.scenarioElement, 'cs')

        const electricVehiclesElement = $(this.scenarioElement).find('.evs-subform')
        this.electricVehiclesSector = new ResFarmForm(electricVehiclesElement, this.scenarioElement, 'evs')

        const tidalKitesElement = $(this.scenarioElement).find('.tdk-subform')
        this.tidalKitesSector = new ResFarmForm(tidalKitesElement, this.scenarioElement, 'tdk')

        new ResFarmForm($(this.scenarioElement).find('.wind-subform'), this.scenarioElement, 'wind')

        new ResFarmForm($(this.scenarioElement).find('.solar-subform'), this.scenarioElement, 'solar')

        new ResFarmForm($(this.scenarioElement).find('.geo-subform'), this.scenarioElement, 'geo')

        new ResFarmForm($(this.scenarioElement).find('.hydro-subform'), this.scenarioElement, 'hydro')

        new ResFarmForm($(this.scenarioElement).find('.incin-subform'), this.scenarioElement, 'incin')

        new ResFarmForm($(this.scenarioElement).find('.oil-subform'), this.scenarioElement, 'oil')

        new ResFarmForm($(this.scenarioElement).find('.fuel-subform'), this.scenarioElement, 'fuel')

        new ResFarmForm($(this.scenarioElement).find('.bess-subform'), this.scenarioElement, 'bess')

        new ResFarmForm($(this.scenarioElement).find('.fly-subform'), this.scenarioElement, 'fly')

        new ResFarmForm($(this.scenarioElement).find('.conv-subform'), this.scenarioElement, 'conv')

        new ResFarmForm($(this.scenarioElement).find('.trans-subform'), this.scenarioElement, 'trans')

        new ResFarmForm($(this.scenarioElement).find('.pow-subform'), this.scenarioElement, 'pow')
    }

    #setUpExpandableSectorsListeners (options={}) {
        const obj = this

        this.expandableSectors.on('click', function () {
            const sectorContent = $(this).next()

            if (options.showSingle) {
                $(obj.scenarioElement).find('.expanded-sector-content').each(function (index, element) {
                    if ($(sectorContent)[0] !== $(element)[0])
                        $(element).hide(250)
                })
            }
            sectorContent.show(250);
        })
    }

}