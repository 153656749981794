import * as elementCreator from "../../modules/createElements";

export default class WaterStorageFormHelper {
    constructor(waterStorageForm) {
        this.form = waterStorageForm
        waterStorageForm = $(waterStorageForm)
        // what it affects
        this.fuelInput = waterStorageForm.find('select[name*=Fuel]')
        this.powerInput = waterStorageForm.find('input[name*=Power]')
        // is it base or var - check if it contains 'old' in the id
        this.scenario = $(this.fuelInput).attr('id').includes('old') ? 'old_' : ''

        this.activeComponentsForm = null
        if (this.scenario === '') this.activeComponentsForm = $('#div-heating-source')
        else this.activeComponentsForm = $('#div-old-heating-source')

        this.dependentInputsList = [this.powerInput]
    }

    setUpInitialState () {
        const value = this.fuelInput.val()
        this.#_onFuelChange(value)
    }

    addReactivity () {
        const obj = this
        $(this.fuelInput).on('change', function (event) {
            const componentName = $(event.target).find('option:selected').text()
            obj.#_onFuelChange(componentName)
        })
    }

    // Private functions

    #_onFuelChange (value) {
        this.#_setThermalPower(value)
        this.#_unlockInputs()

        if (value !== 'Electricity') this.#_lockInputs()
    }

    #_setThermalPower (component) {
        const componentInputName = this.#_componentTextToName(component)
        const allActiveComponentsInputs = this.activeComponentsForm.find(`input[name*="Thermal"]`).toArray()
        window.all = allActiveComponentsInputs
        const thermalRatingInput = allActiveComponentsInputs.filter(e => $(e).attr('name').toLowerCase().includes(componentInputName.toLowerCase()))[0]
        $(this.powerInput).val($(thermalRatingInput).val())
    }

    #_componentTextToName (text) {
        return text.split(' ').join('__')
    }

    #_lockInputs () {
        const obj = this
        $(this.dependentInputsList).each(function (index, input) {
            input = $(input)
            const tagName = input.prop('tagName') // find what type of input it is to set value
            let value = input.val()
            // if (tagName === 'INPUT')
            //     value = '0'
            // input.val(value)
            input.attr('disabled', 'disabled')

            // TODO: Maybe find a more elegant way to do this
            const inputName = input.attr('name').split('[').at(-1).replaceAll(']', '')

            let prefix = ''
            if (obj.scenario === 'old_') {
                prefix = 'water_'
            }
            const hiddenInput = elementCreator
                .createHiddenInput(`thermal[${prefix}storage_${obj.scenario}types[Storage[${inputName}]]]`,
                    `thermal[${prefix}storage_${obj.scenario}types[Storage[${inputName}]]]`, value)
            // append hidden input
            input.parent().append(hiddenInput)
        })
    }

    #_unlockInputs () {
        $(this.dependentInputsList).each(function (index, input) {
            $(input).removeAttr('disabled')
            // also remove any hidden inputs
            input.parent().find('input[hidden]').remove()
        })
    }


}