import clearFormElements from '../../helpers/inputs_cleaner';

const addDemandInformationListeners = function() {

  $('#box-annual').change(function() {
    $('#div-annual-source').toggle(this.checked);
    clearInputs($('#div-annual-source'));
    clearFormElements($("#div-passive-source"), $("#box-annual").prop("checked"));
    toggleBoxPassive(!$("#box-annual").prop("checked"), false);
  });

  $('#box-specific').change(function() {
    $('#div-specific-source').toggle(this.checked);
    clearInputs($('#div-specific-source'));
  });

  $("#thermal_has_existing_components").on('click', function () {
    $("#active_old_components_span").toggle(this.checked);
    $("#passive_old_components_span").toggle(this.checked);
  });

};

const addComponentsListeners = function() {
  $('#box-active').change(function() {
    $('#div-active-source').toggle(this.checked);
  });

  $('#box-passive').change(function() {
    $('#div-passive-source').toggle(this.checked);
  });

  $('#box-heating').change(function() {
    $('#div-heating-source').toggle(this.checked);
  });
  $('#box-old-heating').change(function() {
    $('#div-old-heating-source').toggle(this.checked);
  });

  $('#box-cooling').change(function() {
    $('#div-cooling-source').toggle(this.checked);
  });
  $('#box-old-cooling').change(function() {
    $('#div-old-cooling-source').toggle(this.checked);
  });

  $('#box-ventilation').change(function() {
    $('#div-ventilation-source').toggle(this.checked);
  });
  $('#box-old-ventilation').change(function() {
    $('#div-old-ventilation-source').toggle(this.checked);
  });

  $('#box-insulation').change(function() {
    $('#div-insulation-source').toggle(this.checked);
  });

  $('#box-old-insulation').change(function() {
    $('#div-old-insulation-source').toggle(this.checked);
  });

  $('#box-storage').change(function() {
    $('#div-storage-source').toggle(this.checked);
  });
  $('#box-heat-pump').change(function() {
    $('#div-heat-pump-source').toggle(this.checked);
  });
  $('#box-pcm').change(function() {
    $('#div-pcm-source').toggle(this.checked);
  });
  $('#box-water-storage').change(function() {
    $('#div-water-storage-source').toggle(this.checked);
  });
  $('#box-old-storage').change(function () {
    $('#div-old-water-storage-source').toggle(this.checked);
  });
  $('#box-glazing').change(function() {
    $('#div-glazing-source').toggle(this.checked);
  });
  $('#box-old-glazing').change(function() {
    $('#div-old-glazing-source').toggle(this.checked);
  });

  ($('#own_heating').click(function() {
    $('#div_share_heating').hide('fast');
    $('#div_own_heating').show('fast');
  }));
  ($('#share_heating').click(function() {
    $('#div_own_heating').hide('fast');
    $('#div_share_heating').show('fast');
  }));

  ($('#own_cooling').click(function() {
    $('#div_share_cooling').hide('fast');
    $('#div_own_cooling').show('fast');
  }));
  ($('#share_cooling').click(function() {
    $('#div_own_cooling').hide('fast');
    $('#div_share_cooling').show('fast');
  }));
};


export default function addCheckboxesListeners() {
  addDemandInformationListeners();
  addComponentsListeners();
  // move this somewhere better
  // console.log(!$("#box-annual").prop("checked"));

  toggleBoxPassive(!$("#box-annual").prop("checked"), $("#box-passive").prop("checked"));
};

function toggleBoxPassive(open, checked) {
  $("#box-passive").prop('checked', checked).toggle(open);
  $("#annual-passive-warning").toggle(!open);
  if (!open) $("#div-passive-source").toggle(open);
}

function clearInputs(group) {
  // clear select inputs
  $(group).find('input[type=text], input[type=number]').each(function(i, e) {
    $(e).val('');
    if (!$($("form#new_thermal").children()[1]).hasClass('alert alert-danger')) {
      $(e).removeClass('is-valid is-invalid');
    }
    $("#thermal_external_material").val("N/A");
  });

}
