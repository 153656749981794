import Chart from 'chart.js';
import 'chartjs-plugin-colorschemes';

export default function initializePlots () {
  energyPaybackPlot();

  createChart({
    c_type: 'bar',
    id: 'exports-chart',
    label: 'Total Annual Exports (kWh)',
    dataLabels: exportsLabels,
    dataValues: exportsData,
    colorScheme: 'office.BlueII6'
  });

  createChart({
    c_type: 'bar',
    id: 'imports-chart',
    label: 'Total Annual Imports (kWh)',
    dataLabels: importsLabels,
    dataValues: importsData,
    colorScheme: 'office.BlueII6'
  });

  createChart({
    c_type: 'bar',
    id: 'heatCoolBills-chart',
    label: 'Annual Electricity Bills for Heating/Cooling (€)',
    dataLabels: heatCoolBillsLabels,
    dataValues: heatCoolBillsData,
    colorScheme: 'office.Apex6'
  });

  createChart({
    c_type: 'pie',
    id: 'revenues-chart',
    label: 'Annual Electricity Revenues (€)',
    dataLabels: revenuesLabels,
    dataValues: revenuesData,
    colorScheme: 'office.Apex6'
  });

  createChart({
    c_type: 'pie',
    id: 'bills-chart',
    label: 'Annual Electricity Bills (€)',
    dataLabels: billsLabels,
    dataValues: billsData,
    colorScheme: 'office.Apex6'
  });

  createTimeseriesChart({
    c_type: 'line',
    id: 'production-chart',
    label: 'Hourly Production (kWh)',
    timestamps: productionTimestamps,
    datasets: productionDatasets,
    colorScheme: 'office.Aspect6'
  });

  createTimeseriesChart({
    c_type: 'line',
    id: 'consumption-chart',
    label: 'Hourly Consumption (kWh)',
    timestamps: consumptionTimestamps,
    datasets: consumptionDatasets,
    colorScheme: 'office.Aspect6'
  });

  createTimeseriesChart({
    c_type: 'line',
    id: 'co2-chart',
    label: 'Hourly CO2 Emissions (kg)',
    timestamps: co2Timestamps,
    datasets: co2Datasets,
    colorScheme: 'office.Aspect6'
  });

}


// helpers
function configBuilder (c_type, data, colorScheme) {
  const barchartConfig = {
    type: c_type,
    data: data,
    options: {
      plugins: {
        colorschemes: {
          scheme: colorScheme
        }
      }
    }
  }
  return barchartConfig;
}

function configTimeseriesBuilder (c_type, data, colorScheme) {
  const config = {
    type: c_type,
    data: data,
    options: {
      scales : {
        xAxes: [
          {
            type: 'time',
            time: {
                unit: 'hour',
                stepSize: 1,
            },
          }
        ]
      },
      plugins: {
        colorschemes: {
          scheme: colorScheme
        }
      }
    }
  }

  return config;
}

function renderChart (id, config) {
  const chart = new Chart(
    document.getElementById(id),
    config
  )
  return chart;
}

function createChart (conf) {
// function createChart (id, label, dataLabels, dataValues, colorScheme) {
  const data = {
    labels: conf.dataLabels,
    datasets: [{
      label: conf.label,
      data: conf.dataValues,
      hoverOffset: 4
    }]
  };

  renderChart(conf.id, configBuilder(conf.c_type, data, conf.colorScheme));
}

function createTimeseriesChart (conf) {
  const data = {
    labels: conf.timestamps,
    datasets: conf.datasets
  }

  renderChart(conf.id, configTimeseriesBuilder (conf.c_type, data, conf.colorScheme));
}

function energyPaybackPlot () {
  const data = {
    labels: ['Primary Energy (kWh)', 'CO2 Emissions (kg)'],
    datasets: epb_data
  }

  const config = {
    type: 'bar',
    data: data,
    options: {
      plugins: {
        colorschemes: {
          scheme: 'office.BlueII6'
        },
        title: {
          display: true,
          text: 'Primary Energy Payback'
        },
      },
      responsive: true,
      scales: {
         xAxes: [{
            stacked: true
         }],
         yAxes: [{
            stacked: true
         }]
      }
    }
  };

  const chart = new Chart(
    document.getElementById('energy-payback-chart'),
    config
  )
}
