/*
 Class that manages the global form functions (project).
 Also contains the instances of the subform helpers
 in order to manage interaction between the sub-components.
 */
import ScenarioForm from "./form_helpers/scenarioForm";
import scenarioSwap from "./form_helpers/scenarioSwap";

export default class FormHandler {

    constructor () {
        let scenarioElement = $('.district-scenario[data-index=0]')
        this.oldScenario = new ScenarioForm(scenarioElement, 0)

        scenarioElement = $('.district-scenario[data-index=1]')
        this.newScenario = new ScenarioForm(scenarioElement, 1)

        scenarioSwap(this)
    }

    get oldScenarioElement () { return this.oldScenario.scenarioElement }

    get newScenarioElement () { return this.newScenario.scenarioElement }

}