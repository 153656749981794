let exportButton, selectInput
let selectedIds

export default function() {
  initializeVariables()
}

function initializeVariables() {
  exportButton = $('.file-download')[0]
  selectInput = $('#site_pvs-select')
  selectedIds = []

  $(exportButton).on('click', onExportClick)

  $(selectInput).searchableOptionList({
    events: {
      onChange: onSelectChange
    }
  })
}

function onExportClick(e) {
  let url = $(exportButton).data('href')
  const startOfParams = url.indexOf('?')
  if(startOfParams > -1) {
    url = url.substring(0, startOfParams);
  }

  const paramsNameStr = '&ids[]='
  const params = '?' + paramsNameStr + selectedIds.join(paramsNameStr)
  url = url + params
  $(exportButton).data('href', url)
}

function onSelectChange(sol,changedElements) {
  const selectedId = changedElements[0].value
  const isChecked = $(changedElements[0]).is(':checked')
  if(isChecked) {
    selectedIds.push(selectedId)
  } else {
    selectedIds = selectedIds.filter(e => e !== selectedId)
  }
  console.log(selectedIds)
}
