/*
    This class is used to handle the reactivity
    of the passive components modal form
*/
export default class PassiveComponentsModal {

    constructor(passiveComponentsModal, buildingSectorElement) {
        this.passiveComponentsModal = passiveComponentsModal
        this.modalBody = $(this.passiveComponentsModal).find('.modal-body')
        this.modalInputs = $(this.modalBody).find('.pc-modal-inputs')
        this.selectedOption = $(this.modalBody).find('.passive-component-select')

        this.buildingSectorElement = buildingSectorElement

        const obj = this
        $(this.selectedOption).on('change', function () {
            $(obj.modalInputs).hide()
            const selectedOption = $(this).find('option:selected')
            obj.#activateSubForm(selectedOption)
        })

        this.#addOnShownListener()
    }

    #addOnShownListener () {
        const obj = this

        $(this.passiveComponentsModal).on('shown.bs.modal', function (e) {
            const typeSelect = $(obj.modalBody).find('.passive-component-select')
            const selected = $(typeSelect).find(':selected')

            obj.#activateSubForm(selected)
        })
    }

    #activateSubForm (selected) {
        this.modalInputs.hide()
        this.modalInputs.find('input[type=number], input[type=text], select').prop('disabled', true);
        const activeInputs = this.modalBody.find(`#${$(selected).text().toLowerCase()}-modal-inputs`)
        activeInputs.show()
        $(activeInputs).find('input[type=number], input[type=text], select').prop('disabled', false)
    }

}