import consumer from "channels/consumer";
import ChartHelper from "./chart_helper";
import TableHelper from "./table_helper";

// This class:
//  - adds the callbacks to all the plot buttons,
//  - manages websocket connections
//  - manages the plots

// Remember to add the class in application.js (See examples)

// In order for this file to be used, the following data-attributes must
// be added to markup (see also the demos in app/views/plot_buttons/)

// In short:
//  - the plot element (where the graph will appear):
//    - should have class 'chart_area_canvas'
//    - should a have UNIQUE 'data-plot_id' attribute. This value should be
//      UNIQUE across the ENTIRE project
//  - the add buttons:
//    - should have class 'button-add-plot'
//    - should have a `data-project_id` attribute (this is the parameter for
//      the websocket connect)
//    - should have a 'data-plot_id' attribute, that matches with the
//      respective 'data-plot_id' attribute of the plot element
//  - the remove buttons:
//    - should have class 'button-remove-plot'
//    - should have a `data-project_id` attribute (this is the parameter for
//      the websocket connect)
//    - should have a 'data-plot_id' attribute, that matches with the
//      respective 'data-plot_id' attribute of the plot element
//  - the clear buttons:
//    - should have class 'clear-plot'
//    - should have a 'data-plot_id' attribute, that matches with the
//      respective 'data-plot_id' attribute of the plot element
export default class PlotButtons {
  // Constructor should be called in application.js (NOT in callback)
  constructor() {
    this.dataPointChannels = {};
    this.productionChartHelper = new ChartHelper();
    this.consumptioonChartHelper = new ChartHelper();
    this.rd_co2ChartHelper = new ChartHelper();
    this.salesChartHelper = new ChartHelper();
    this.avoidedCostChartHelper = new ChartHelper();
    this.emissionfactorChartHelper = new ChartHelper();
    this.genUnitsChartHelper = new TableHelper();
    this.cumulChartHelper = new TableHelper();
  }

  // This method should be called in the "onload" callback at application.js, i.e.
  //  - If turbolinks is used, it is called from 'turbolinks:load' callback
  //  - If turbolinks is NOT used, but jquery IS, then '$(document).ready()' callback.
  //  - Otherwise 'DOMContentLoaded' callback

  initButtons() {
    this.productionChartHelper.initPlots("production");
    this.consumptioonChartHelper.initPlots("consumption");
    this.rd_co2ChartHelper.initPlots("pv_rdco2");
    this.salesChartHelper.initPlots("sales");
    this.avoidedCostChartHelper.initPlots("avoided_cost");
    this.emissionfactorChartHelper.initPlots("emission_factor");

    this.genUnitsChartHelper.initTables("genUnits");
    this.cumulChartHelper.initTables("cumul");

    const singleChart = document.querySelectorAll(".auto-start-plot");
    if (singleChart.length > 0) {
      singleChart.forEach((el) => {
        this.individual_plot(el);
      });
    }
    const addButtons = document.querySelectorAll(".button-add-plot");
    if (addButtons.length > 0) {
      addButtons.forEach((el) => {
        el.addEventListener("click", () => {
          this.individual_plot(el);
        });
        if (
          this.dataPointChannels[el.dataset.plot_id] &&
          this.dataPointChannels[el.dataset.plot_id][el.dataset.project_id]
        ) {
          el.parentElement.parentElement.classList.add("added");
        }
      });
    }
    const removeButtons = document.querySelectorAll(".button-remove-plot");

    if (removeButtons.length > 0) {
      removeButtons.forEach((el) => {
        el.addEventListener("click", () => {
          this.removeSubscriptionFromPlot(
            el.dataset.plot_id,
            el.dataset.project_id
          );
          el.parentElement.parentElement.classList.remove("added");
          return false;
        });
      });
    }

    const clearButtons = document.querySelectorAll(".clear-plot");

    if (clearButtons.length > 0) {
      clearButtons.forEach((el) => {
        el.addEventListener("click", () => {
          if (this.dataPointChannels[el.dataset.plot_id]) {
            Object.keys(this.dataPointChannels[el.dataset.plot_id]).forEach(
              (projectId) => {
                this.removeSubscriptionFromPlot(el.dataset.plot_id, projectId);
              }
            );
          }

          this.productionChartHelper.clearPlot(el.dataset.plot_id);
          this.consumptioonChartHelper.clearPlot(el.dataset.plot_id);
          this.rd_co2ChartHelper.clearPlot(el.dataset.plot_id);
          this.salesChartHelper.clearPlot(el.dataset.plot_id);
          this.avoidedCostChartHelper.clearPlot(el.dataset.plot_id);
          this.emissionfactorChartHelper.clearPlot(el.dataset.plot_id);

          removeButtons.forEach((el) => {
            if (el.dataset.plot_id === el.dataset.plot_id)
              el.parentElement.parentElement.classList.remove("added");
          });
          return false;
        });
      });
    }
  }

  individual_plot(el) {
    this.productionChartHelper.addNewLine(
      el.dataset.plot_id,
      el.dataset.project_id,
      el.dataset.name, "rgba(12,153,250,0.31)"
    );
    this.consumptioonChartHelper.addNewLine(
      el.dataset.plot_id,
      el.dataset.project_id,
      el.dataset.name,"rgba(255,233,186,0.47)"
    );
    this.rd_co2ChartHelper.addNewLine(
      el.dataset.plot_id,
      el.dataset.project_id,
      el.dataset.name,"rgba(47,250,192,0.46)"
    );
    this.salesChartHelper.addNewLine(
      el.dataset.plot_id,
      el.dataset.project_id,
      el.dataset.name,"rgba(38,62,124,0.22)"
    );
    this.avoidedCostChartHelper.addNewLine(
      el.dataset.plot_id,
      el.dataset.project_id,
      el.dataset.name,"rgba(38,62,124,0.22)"
    );
    this.emissionfactorChartHelper.addNewLine(
      el.dataset.plot_id,
      el.dataset.project_id,
      el.dataset.name,"rgba(38,137,94,0.46)"
    );

    this.genUnitsChartHelper.addNewRow(
      el.dataset.table_id,
      el.dataset.project_id,
      el.dataset.name
    );
    this.cumulChartHelper.addNewRow(
      el.dataset.table_id,
      el.dataset.project_id,
      el.dataset.name
    );

    this.subscribeToProject(
      el.dataset.plot_id,
      el.dataset.table_id,
      el.dataset.project_id
    );
    el.parentElement.parentElement.classList.add("added");
  }

  subscribeToProject(plotId, tableId, projectId) {
    if (!this.dataPointChannels[plotId]) {
      this.dataPointChannels[plotId] = {};
    }
    if (!this.dataPointChannels[plotId][projectId]) {
      this.dataPointChannels[plotId][projectId] = consumer.subscriptions.create(
        {
          channel: "DataPointChannel",
          id: projectId,
        },
        {
          connected: () => {
            console.log("connected");
          },
          disconnected: () => {
            console.log("disconnected");
          },
          received: (data) => {
            console.log("received");
            console.log(data);
            console.log(data["sales"]);
            console.log(data["gen_units"]);

            this.productionChartHelper.addNewPoint(
              plotId,
              projectId,
              data["timestamp"],
              data["production"]
            );
            this.consumptioonChartHelper.addNewPoint(
              plotId,
              projectId,
              data["timestamp"],
              data["consumption"]
            );
            this.rd_co2ChartHelper.addNewPoint(
              plotId,
              projectId,
              data["timestamp"],
              data["pv_rdco2"]
            );
            this.salesChartHelper.addNewPoint(
              plotId,
              projectId,
              data["timestamp"],
              data["sales"]
            );
            this.avoidedCostChartHelper.addNewPoint(
              plotId,
              projectId,
              data["timestamp"],
              data["avoided_cost"]
            );
            this.emissionfactorChartHelper.addNewPoint(
              plotId,
              projectId,
              data["timestamp"],
              data["emission_factor"]
            );

            this.genUnitsChartHelper.addNewPoint(
                tableId,
                projectId,
                "Solar",
                data["gen_units"]["Solar"]
            );
            this.genUnitsChartHelper.addNewPoint(
              tableId,
              projectId,
              "Oil",
              data["gen_units"]["Oil"]
            );
            this.genUnitsChartHelper.addNewPoint(
              tableId,
              projectId,
              "Wind",
              data["gen_units"]["Wind_on"]
            );
            this.genUnitsChartHelper.addNewPoint(
                tableId,
                projectId,
                "Lignite",
                data["gen_units"]["Lignite"]
            );
            this.genUnitsChartHelper.addNewPoint(
                tableId,
                projectId,
                "Gas",
                data["gen_units"]["Gas"]
            );
            this.genUnitsChartHelper.addNewPoint(
                tableId,
                projectId,
                "Hydro Pumped",
                data["gen_units"]["Hydro_pumped"]
            );
            this.genUnitsChartHelper.addNewPoint(
                tableId,
                projectId,
                "Hydro Reservoir",
                data["gen_units"]["Hydro_reservoir"]
            );
            Object.entries(data)
              .filter((e) => {
                return e[0].endsWith("_cumul");
              })
              .forEach((v) => {
                this.cumulChartHelper.addNewPoint(
                  tableId,
                  projectId,
                  v[0],
                  v[1]
                );
              });

            var gen_units_info = [];
            gen_units_info.push(data["gen_units"], data["d_id"]);
          },
        }
      );
    }
  }

  removeSubscriptionFromPlot(plotId, projectId) {
    console.log("Removing ", plotId, projectId);
    if (
      this.dataPointChannels[plotId] &&
      this.dataPointChannels[plotId][projectId]
    ) {
      consumer.subscriptions.remove(this.dataPointChannels[plotId][projectId]);
      delete this.dataPointChannels[plotId][projectId];
    }
    console.log(consumer.subscriptions);
  }
}
