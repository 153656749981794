export default class TableHelper {

  constructor (tableElement) {
    this.tableElement = tableElement;
    this.tableBody = $(tableElement).find("tbody");
    this.columns = $(this.tableElement).find("th[data-column_name]").map(function() {
       return $.trim($(this).data('column_name'));
    }).get();
    this.individual = $(tableElement).data('table_individual');
  }

  addTableRow (values) {
    let row = document.createElement('tr');
    row.dataset.project_id = values['project-id'];

    $(this.columns).each(function (i, column) {
      let cell = document.createElement('td');
      const value = (values[column] !== undefined ? values[column] : '-');
      const cellText = document.createTextNode(value);

      $(cell).append(cellText);
      $(row).append(cell);
    });

    $(this.tableBody).append(row);
  };

  removeTableRow (projectId) {
    // find the row with the id of the project
    const row = $(this.tableBody).find(`tr[data-project_id='${projectId}']`);

    // remove it from the table
    $(row).remove();
  };

  updateTableRow (projectId, values) {
    const row = $(this.tableBody).find(`tr[data-project_id='${projectId}']`);
    let tds = $(row).find('td');
    let columns = this.columns;
    // if not individual page
    if (!this.individual) {
      tds = $(row).find('td').slice(1); // slice to get rid of the project id td
      columns = this.columns.slice(1);
    }
    $(columns).each (function (i, column) {
      if (values[column] !== undefined)
        tds[i].textContent = parseFloat(values[column]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    });
  };

  // --------------------
  // Added to update values in the new type of table
  // Writing this a little fast TODO: Incorporate it with the rest of the code
  updateMultiHeaderTds (values) {
    // console.log('--------------')
    // find tds and their keys
    const tds = $(this.tableBody).find('td')
    // hold td element and their corresponding key from lca
    let tdsMap = {}
    tds.each(function (index, td) {
      const key = $(td).data('td_key')
      tdsMap[key] = td
    })
    // Update the tds value
    Object.keys(tdsMap).forEach(function (key) {
      // console.log(key, ':', values[key])
      if (values[key] !== undefined)
        tdsMap[key].textContent = parseFloat(values[key]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    })
  }
};
