import * as DownloadHelper from './chart_download'

export function renderChart(canvas, options, plugins) {
  const ctx = canvas.getContext('2d')

  options.title.text = $(canvas).data('title')

  const chart = new Chart(ctx, {
    type: $(canvas).data('type'),
    data: {
      labels: JSON.parse(ctx.canvas.dataset.labels),
      datasets: [{
        data: JSON.parse(ctx.canvas.dataset.data),
      }]
    },
    options,
    plugins
  })

  return chart
}

export function appendToDataset(chart, datasetIndex, labels, values) {
  chart.data.labels.push(labels)
  chart.data.datasets[datasetIndex].data.push(values)
  chart.update()
}

export function replaceDatasetValueAtIndex(chart, datasetIndex, valueIndex, newValue) {
  chart.data.datasets[datasetIndex].data[valueIndex] = newValue
  chart.update()
}

export function replaceDatasetValues(chart, datasetIndex, newValues) {
  chart.data.datasets[datasetIndex].data = newValues
  chart.update()
}

export function addDownloadButton(chart, options) {
  const canvas = chart.canvas
  const extraPadding = 115
  const container = $(canvas).parents('.chart-container')
  const backgroundColor = $(container).css('backgroundColor')

  let downloadButton
  const existingButtons = $(container).find('.download-btn')
  if(existingButtons.length > 0) {
    downloadButton = existingButtons[0]
  } else {
    downloadButton = DownloadHelper.createDownloadButton(canvas.id)
    $(container).append(downloadButton)
  }

  const destinationCanvas = DownloadHelper.createDownloadCanvas(canvas, backgroundColor)
  $(downloadButton).attr('disabled', 'disabled')
  DownloadHelper.addLogos(canvas, destinationCanvas, downloadButton, canvas.width, canvas.height)
  $(downloadButton).removeAttr('disabled')
}
