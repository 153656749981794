import ProductionFormHandler from "../../pvs/form_helpers/productionFormHandler";
import PassiveComponentsModal from "./passiveComponentsModal";

export default class BuildingSectorForm {
    constructor(buildingSectorElement, scenarioElement) {
        this.buildingSectorElement = buildingSectorElement
        this.scenarioElement = scenarioElement
        this.subSectorRadios = $(this.buildingSectorElement).find('input[name^=building-sub]')

        this.#setUpSubsectionRadioButtons()

        // TODO: Add/Remove the added buildings
        this.addedBuildings = {}
        this.modalsContainers = $(this.buildingSectorElement).find('[id*="private-buildings-form"]').find('.production-container')
        this.#setUpModalFormHandlers()

        this.#addPassiveComponentsModalReactivity()
    }

    #addPassiveComponentsModalReactivity () {
        const obj = this

        const passiveComponentModals = (this.buildingSectorElement).find('.pc-modal')
        $(passiveComponentModals).each(function (index, modal) {
            new PassiveComponentsModal(modal, obj.buildingSectorElement)
        })
    }

    #setUpModalFormHandlers () {
        const obj = this

        this.#setUpModalFormHandler()

        // TODO: Replace this with this.addedBuildings
        const buildingItems = $(this.buildingSectorElement).find('[id^=building-items]')
        buildingItems.on('cocoon:after-insert', function (event, insertedItem) {
            obj.#setUpModalFormHandler(insertedItem)
            new PassiveComponentsModal($(insertedItem).find('.pc-modal'))
        })
    }

    // TODO: Find a better name for this
    // It set ups a new handler or setups handlers for all existing elements
    #setUpModalFormHandler (newElement=null) {
        const obj = this

        let containers = this.modalsContainers
        if (newElement) containers = $(newElement).find('.production-container')

        $(containers).each(function (index, element) {
            const scenarioIndex = $(obj.scenarioElement).data('index')
            const building = $(element).parents('.nested-fields').find('.inputs-group')
            const modalType = $(element).data('gen_type')
            const buildingId = obj.#extractBuildingId(building)
            const attributesPath = `district_project[district_scenarios_attributes][${scenarioIndex}][district_buildings_attributes][${buildingId}]`

            const modalsHandler = new ProductionFormHandler(element, attributesPath, modalType)
        })
    }

    #extractBuildingId (building) {
        const tokens = $(building).find('[data-cocoon=true]').attr('id').split('_')
        const buildingIdPosition = tokens.findIndex(t => t === 'buildings') + 2
        return tokens[buildingIdPosition]
    }

    #setUpSubsectionRadioButtons() {
        const obj = this
        // if something is already checked - because e.g. a reload happened
        this.#toggleBuildingSubSectors($('input[name^=building-sub]:checked'))

        this.subSectorRadios.on('change', function (event) {
            obj.#toggleBuildingSubSectors(event.target)
        })
    }

    #toggleBuildingSubSectors(elements) {
        $(elements).each(function (index, radio) {
            const value = $(radio).val()
            const nameTokens = $(radio).attr('name').split('-')
            const scenario = nameTokens[nameTokens.length - 1]

            if (value === 'private') {
                $(`#public-infrastructure-form-${scenario}`).hide()
                $(`#private-buildings-form-${scenario}`).show()
            } else if (value === 'public') {
                $(`#private-buildings-form-${scenario}`).hide()
                $(`#public-infrastructure-form-${scenario}`).show()
            }
        })
    }
}
