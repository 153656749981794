export function createDownloadButton(file_name) {
  let downloadButton = document.createElement('a')
  downloadButton.innerHTML = '<i class="fa fa-download" aria-hidden="true"></i>'
  $(downloadButton).addClass('download-btn btn btn-sm btn-info')
  $(downloadButton).attr('download', `${file_name}.jpg`)
  return downloadButton
}

export function createDownloadCanvas(originalCanvas, backgroundColor) {
  const width = originalCanvas.width
  const height = originalCanvas.height
  const extraPadding = 125
  const destinationCanvas = document.createElement("canvas")
  destinationCanvas.width = width
  destinationCanvas.height = height + extraPadding
  const destCtx = destinationCanvas.getContext('2d')
  destCtx.fillStyle = backgroundColor
  destCtx.fillRect(0, 0, width + extraPadding, height + extraPadding)
  destCtx.drawImage(originalCanvas, 0, 0)
  return destinationCanvas
}

// cWidth: original canvas width
// cHeight: original canvas height
export function addLogos(originalCanvas, destinationCanvas, downloadButton, cWidth, cHeight) {
  const destCtx = destinationCanvas.getContext('2d')
  let image = new Image()
  image.src = $(originalCanvas).data('logo')
  image.width = 10;
  image.onload = function () {
      const scaleFactor = 2
      // 703 x 190 is the logos image size
      const width = 703 / scaleFactor
      const height = 190 / scaleFactor
      destCtx.drawImage(image, (cWidth - width) / 2, cHeight + 10, width, height)

      const dWidth = destinationCanvas.width
      const dHeight = destinationCanvas.height
      destCtx.font = "15px Arial";
      destCtx.fillStyle = "grey";

      const dateStr = $(originalCanvas).data('timestamp')
      destCtx.fillText(dateStr, 10, dHeight - 5)
      downloadButton.href = destinationCanvas.toDataURL()

      $(downloadButton).removeAttr('disabled')
  }
}

function currentDatetime() {
  const today = new Date()
  today.setMinutes(0)
  today.setSeconds(0)
  return today.toLocaleString()
}
