import ChartHelper from "./chart_helper";
import TableHelper from "./table_helper";
import IndividualChartHelper from "./individual_chart_helper";
import initializeButtons from "./buttons_helper";
import initializeIndividual from "./individual";

// single channel and find right plot from site_pv_id
export default function initializePlots (projectId=-1) {
  let charts = [];
  let individualCharts = [];
  let tables = [];
  // added for multi_header_table
  let multiTables = []

  const canvases = $(".chart_area_canvas");
  canvases.each(function(i, c) {
    // if plot is individual create individual charts
    // these charts are only in the individual page for the time being
    if ($(c).data('individual')) {
      const chartHelper = new IndividualChartHelper('136', $(c).data('plot_type'), c);
      chartHelper.initPlot();
      individualCharts.push(chartHelper);
    } else {
      const consumptionChartHelper = new ChartHelper($(c).data('plot_type'));
      consumptionChartHelper.initPlot();
      charts.push(consumptionChartHelper);
    }
  });

  const tableElements = $(".dynamic-table");
  tableElements.each(function (i, t) {
    const dynamicTableHelper = new TableHelper(t);
    tables.push(dynamicTableHelper);
  });

  const multiTableElements = $('.dynamic-multi-table')
  multiTableElements.each(function (i, t) {
    const dynamicTableHelper = new TableHelper(t);
    multiTables.push(dynamicTableHelper);
  });

  if (projectId === -1) initializeButtons(charts, tables);
  else initializeIndividual(projectId, individualCharts, tables, multiTables);

  // this is for testing purposes
  // $("#forward-btn").on('click', function () {
  //   console.log("Forwarding time...");
  //   $.ajax({
  //     url: "/forward_time",
  //     dataType: 'json',
  //     success: function(response, status, obj){
  //       console.log(response);
  //     }
  //   });
  // });
};
