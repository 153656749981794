import "../../packs/sol";

export default class HoursSelectorsController {
  constructor() {
    this.selectedHours = []
    this.selectorElements = []
  }

  addHourSelector(inputElement) {
    // for some reason just adding selected='selected' in html is not enough to set the property
    // so I do it here manually
    const selectedInputs = $(inputElement).find('option:selected')
    $(selectedInputs).prop('selected', true)
    const values = Array.from(selectedInputs).map(e => parseInt(e.value))
    let selector = null
    const obj = this
    $(inputElement).searchableOptionList({
      showSelectAll: true,
      events: {
        onChange: this.#onSolChange.bind(obj),
        onInitialized: function() {
          obj.selectorElements.push(this)
          selector = this
          // Remove unavailable hours
          const unavailableHours = obj.selectedHours
          const options = $(selector.$selectionContainer).find('.sol-option')
          for(let i = 0; i < unavailableHours.length; i++) {
            const value = unavailableHours[i]
            $(options[value]).toggle(false)
          }

          obj.#fillDbValues(selector, values)
          // for(let i in values) {
          //   obj.selectedHours.push(values[i])
          // }
        }
      }
    });
  }

  removeHourSelector(index) {
    let selector = this.selectorElements[index]
    const checkedOptions = $(selector.$selectionContainer).find('.sol-checkbox:checked')
    const values = Array.from(checkedOptions).map(h => $(h).val())
    const selectedHours = this.selectedHours
    const selectorElements = this.selectorElements

    selectorElements.splice(index, 1)
    this.selectedHours = selectedHours.filter(e => !values.includes(e))

    for(let i = 0; i < values.length; i++) {
      const value = values[i]
      this.#addHourOption(selector, value)
    }
  }

  #onSolChange(sol, changedElements) {
    const checkbox = changedElements
    const value = $(checkbox).val()
    const isChecked = $(checkbox).is(':checked')
    const season = $(checkbox).parents('.season-container').data('season')
    const seasonHours = this.selectedHours
    if(isChecked) {
      seasonHours.push(value)
      this.#removeHourOption(sol, value)
    } else {
      this.selectedHours = seasonHours.filter(h => h !== value)
      this.#addHourOption(sol, value)
    }
  }

  #addHourOption(changedSelector, value) {
    this.#toggleHourOption(changedSelector, value, true)
  }

  #removeHourOption(changedSelector, value) {
    this.#toggleHourOption(changedSelector, value, false)
  }

  #toggleHourOption(changedSelector, value, show) {
    const selectors = this.selectorElements
    for(let i = 0; i < selectors.length; i++) {
      const selector = selectors[i]
      if(selector !== changedSelector) {

        const options = $(selector.$selectionContainer).find('.sol-option')
        $(options[value]).toggle(show)
      }
    }
  }

  #fillDbValues(selector, dbValues) {
    const checkboxes = $(selector.$selectionContainer).find('.sol-checkbox')
    // don't forget to fill the array of the class
    // don't forget to remove the values from the other selectors
    const checkedNumbers = dbValues
    for(let i in checkedNumbers) {
      const number = checkedNumbers[i]
      this.#onSolChange(selector, checkboxes[number])
      // remove values from other selectors
    }
  }

}
