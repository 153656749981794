export default function initializePositionForm () {
  // select the table with the radio buttons
  const table = $("#position_options_table");
  // select the radio buttons
  const buttons = table.find("input[type=radio]");

  // for each button set the event function onClick
  $.each(buttons, function(){

      $( this ).click(function(evt) {
          // retrieve type of property: Central or Owned
          const type = this.id.substring(this.id.lastIndexOf( "_" ), this.id.length);

          // Generate the selector for the equivalent_users field
          // get the id of the radio button (e.g., heat_contribution_content[HeatPump[property]]_owned)
          // remove the type of the property from the selected id (e.g., _central)
          // replace "property" with "equivalent_users" (e.g., heat_contribution_content[HeatPump[equivalent_users]])
          // escape square brackets
          let selector = "#" + this.id.substring( 0, this.id.lastIndexOf( "_" )).replace("Action", "Value");
          selector = selector.replaceAll("[", "\\[");
          selector = selector.replaceAll("]", "\\]");

          // set the defined values to equivalent_users
          // setting readonly = true to lock the text field if type is Owned
          // (d not use disabled cause this way Rails won't read the value of the field)
          if (type === '_optimal'){
              $(selector).val(-999);
              $(selector).prop("readonly", true);
          } else if (type === '_specific'){
              console.log('inside Specific option pv angle')
              $(selector).prop("readonly", false);
              $(selector).prop("placeholder", "user option");
              $(selector).val("");
          }
      });
  });

};
