// For the LCA and LCC I have used the generic chart
// However, I thought that it would be more convenient
// to have each chart in its own file to explicitly define its configuration
// because many times this is needed and the maintenance of the generic chart is
// tedious. So, I will think of another way to handle charts, that will allow
// to be generic but also easily manipulated by hand.

import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as ChartUtils from '../../utils/charts'

let options = {
  title: {
    display: true,
    position: 'top',
    fontSize: 18,
    fontColor: '#2b2b2b',
    lineHeight: 1.5,
    padding: 20
  },
  legend: {
    position: 'left'
  },
  plugins: {
    datalabels: {
      anchor: 'center',
      align: 'center',
      font: {
        size: 15,
        weight: 'bold'
      },
      labels: {
        value: {
          color: 'white',
        }
      },
      formatter: (value, ctx) => {
        const units = $(ctx.chart.canvas).data('unit')
        if (value > 0)
          return `${value} ${units}`;
        else
          return null;
      },
    },
    colorschemes: {
        scheme: 'office.Apex6'
    }
  }
}

export default function() {
  $('.chart.doughnut').each(function() {
    const plugins = [ChartDataLabels]
    ChartUtils.renderChart(this, options, plugins)
  });
}
