// TODO: 1. When a component is added remove it from the list
// TODO: 2. When a component is removed add it to the list
// TODO: 3. When the first component is selected update the units input

import * as elementCreator from '../../modules/createElements'

const subtypesOfTypes = {
  'pv': ['ground', 'roofslanted', 'roofflat', 'facade', 'bintegrated'],
  'wt': ['geared', 'permanentmagnet'],
  'heatpump': ['air2air', 'air2water'],
  'boiler': ['ngas', 'oil', 'biomass', 'diesel'],
  'aircondition': ['basic'],
  'resistive': ['basic'],
  'infrared': ['basic'],
  'aggregator': ['3phase', 'gateway']
};

export default function initializeDevicesForm() {

  //=========
  // New code
  //=========

  setUpInitialStateFinal();

}


// ============================================
// NESTED FORM - THIS SHOULD BE THE FINAL CODE
// ============================================

// summarizing the code
// set up initial state
function setUpInitialStateFinal () {
  const typeInput = $("#device_d_type");
  const subtypeInput = $("#device_subtype");
  const unitsInputs = $('#device_units')
  const selectedType = typeInput.val();
  const selectedSubtype = $("#device_subtype").val();
  updateViewForTypeFinal (selectedType, selectedSubtype);

  typeInput.on('change', onTypeChangeFinal);
  subtypeInput.on('change', onSubtypeChangeFinal);
  unitsInputs.on('change', onUnitsChangeFinal)

  // add unit change listener to the initial box
  $('.measure_type_select').on('change', function () {
    _onMeasureChange($(this))
  })
  // also update units when in editing mode and boxes already exist
  $('.measure_type_select').each(function (index, element) {
    updateUnitsFinal($(element))
  })

  addNewMeasureListenerFinal ();
  const removeButtons = $("[name^='remove-measure-nested']");
  $(removeButtons).on('click', removeDeviceUnitRow);
  addSubmitButtonListenerFinal ();
}

function updateViewForTypeFinal (deviceType, deviceSubtype, triggeredBy=null) {
  const selectedType = deviceType;
  const selectedSubtype = deviceSubtype;
  // rows to show
  const subtypeRow = $("#subtype-row");
  const scenarioRow = $("#scenario-row");
  const unitsRow = $("#units-row");

  const aggregatorButton = $("#add-measure-nested");
  const aggregatorRow = $("#measures-list-row-nested");

  // if thermometer is checked then close all the inputs below because it is a fixed column name in the db
  if (selectedType === 'thermometer') {

    $("#device_subtype").prop('disabled', true);
    $("#device_scenario_base, #device_scenario_var").prop('disabled', true);
    $("#device_scenario_base, #device_scenario_var").prop('checked', false);
    $(subtypeRow).hide();
    $(scenarioRow).hide();
    $(aggregatorRow).hide();

    updateUnitsOptionsFinal (selectedType, selectedSubtype, triggeredBy);

  } else if (selectedType === 'total_electrical_consumption') {

    $("#device_subtype").prop('disabled', true);
    $("#device_scenario_base, #device_scenario_var").prop('disabled', false);
    $(subtypeRow).hide();
    $(scenarioRow).show();
    $(aggregatorRow).hide();

  } else if (selectedType === 'aggregator') {

    // $("#device_subtype").prop('disabled', true);
    $("#device_scenario_base, #device_scenario_var").prop('disabled', true);
    $("#device_scenario_base, #device_scenario_var").prop('checked', false);
    // $(subtypeRow).hide();
    $("#device_subtype").removeAttr('disabled')
    $(subtypeRow).show()
    $(scenarioRow).hide();
    $(unitsRow).hide();
    $(aggregatorButton).show();
    $(aggregatorRow).show();

    updateSubtypeOptionsFinal(deviceType, triggeredBy)

    // remove phase column if not 3phase sensor
    const phaseSelectInputs = $('.phase_select')
    if (deviceSubtype !== '3phase') {
      phaseSelectInputs.attr('disabled', 'disabled')
      phaseSelectInputs.hide()
    } else {
      phaseSelectInputs.removeAttr('disabled')
      phaseSelectInputs.show()
    }


  } else if (selectedType === '') {

  } else {
    $("#device_subtype").prop('disabled', false);
    $("#device_scenario_base, #device_scenario_var").prop('disabled', false);
    $(aggregatorRow).hide();
    $(subtypeRow).show();
    $(scenarioRow).show();
    $(unitsRow).show();

    updateSubtypeOptionsFinal (deviceType, triggeredBy);
    updateUnitsOptionsFinal (selectedType, selectedSubtype, triggeredBy);
  }
  if (selectedType !== 'aggregator') {
    resetDeviceUnitsFinal();

    // create hidden input so that device unit object will be sent
    // first check if it exists
    const hiddenMeasureType = $('select[name="device[device_units_attributes][0][measure_type]"]')
    const scenarioSelected = $('input[name="device[scenario]"]:checked').val()

    let valueForHiddenDeviceUnit
    if (selectedType === 'total_electrical_consumption') {
      valueForHiddenDeviceUnit = `total_electrical_consumption_${scenarioSelected}`
    } else {
      valueForHiddenDeviceUnit = `${deviceType}_${deviceSubtype}_${scenarioSelected}`
    }
    $(hiddenMeasureType).val(valueForHiddenDeviceUnit)
  }

}

// type change listener:
// when the type of the device changes, make the appropriate updates

function onTypeChangeFinal () {
  const selectedType = $("#device_d_type").val();
  const selectedSubtype = $("#device_subtype").val();
  updateViewForTypeFinal (selectedType, selectedSubtype, 'type');
}

function onSubtypeChangeFinal () {
  const selectedType = $("#device_d_type").val();
  const selectedSubtype = $("#device_subtype").val();
  updateViewForTypeFinal (selectedType, selectedSubtype, 'subtype');
}

function onUnitsChangeFinal() {
  const hiddenUnits = $('select[name="device[device_units_attributes][0][units]"]')
  $(hiddenUnits).val($('select[name="device[units]"]').val())
}

function updateSubtypeOptionsFinal (deviceType, triggeredBy) {
  const subtypeInput = $("#device_subtype");
  const subtypeOptions = subtypeInput.find("option");
  if (triggeredBy === 'type') $(subtypeInput).val('');
  filterOptions(subtypeOptions, subtypesOfTypes[deviceType]);
}

function updateUnitsOptionsFinal (selectedType, selectedSubtype, triggeredBy) {
  const unitsInput = $("#device_units");
  const unitsOptions = unitsInput.find("option");
  if (triggeredBy != null) $(unitsInput).val('');
  if (['pv', 'heatpump', 'infrared', 'resistive', 'aircondition', 'total_electrical_consumption', 'wt', 'hotwater'].includes(selectedType)) {
    filterOptions(unitsOptions, ['kW', 'kWh', 'W']);
  } else if (selectedType === 'boiler' && ['ngas', 'diesel', 'oil'].includes(selectedSubtype)) {
    filterOptions(unitsOptions, ['liter', 'kWh', 'kW', 'W']);
  } else if (selectedType === 'boiler' && selectedSubtype === 'biomass') {
    filterOptions(unitsOptions, ['kg', 'kWh', 'kW', 'W']);
  } else if (selectedType === 'thermometer') {
    filterOptions(unitsOptions, ['°C', 'kelvin', 'fahrenheit']);
  } else {
    console.log(selectedType, selectedSubtype, unitsOptions);
  }
}

function filterOptions (allOptions, visibleOptions) {
  $(allOptions).hide();
  const filtered = $(allOptions).filter(function (i, e) {
    return visibleOptions.includes($(e).val());
  }).each(function (i, e) {
    $(e).show();
  });
}

function addSubmitButtonListenerFinal () {
  const submitButton = $('input[type=submit]');
  // better use the button because form id changes
  submitButton.on('click', function (e) {
    e.preventDefault();
    const selectedType = $("#device_d_type").val();

    if (selectedType === 'thermometer') {
      const container = $("#measures-list-nested");
      const units = $("#device_units").val();
      const measureInput = $("#device_device_units_attributes_0_measure_type");
      const unitsInput = $("#device_device_units_attributes_0_units");
      $(measureInput).val(`temperature_indoor`)
      $(unitsInput).val(units);

      const clone = $($(measureInput).parent()).clone();
      const cMeasure = $(clone).find("select")[0];
      const cUnit = $(clone).find("select")[1];

      $(cMeasure).attr('id', `device_device_units_attributes_1_measure_type`);
      $(cMeasure).attr('name', `device[device_units_attributes][1][measure_type]`);
      $(cMeasure).val('temperature_outdoor');
      $(cUnit).attr('id', `device_device_units_attributes_1_units`);
      $(cUnit).attr('name', `device[device_units_attributes][1][units]`);
      $(cUnit).val(units);
      $(clone).appendTo(container);

    } else if (selectedType === 'total_electrical_consumption') {
      const dCase = $("input[name='device[scenario]']:checked").val();
      const units = $("#device_units").val();
      const measureInput = $("#device_device_units_attributes_0_measure_type");
      const unitsInput = $("#device_device_units_attributes_0_units");
      $(measureInput).val(`total_electrical_consumption_${dCase}`)
      $(unitsInput).val(units);
    } else if (selectedType !== 'aggregator') {
      const selectedSubtype = $("#device_subtype").val();
      const dCase = $("input[name='device[scenario]']:checked").val();
      const units = $("#device_units").val();
      const measureInput = $("#device_device_units_attributes_0_measure_type");
      const unitsInput = $("#device_device_units_attributes_0_units");
      $(measureInput).val(`${selectedType}_${selectedSubtype}_${dCase}`)
      $(unitsInput).val(units);
    }
    $(submitButton).closest('form').submit();
  });
}

function resetDeviceUnitsFinal () {
  const container = $("#measures-list-nested");
  const rows = $(".device-unit-item");
  for (let i = rows.length - 1; i > 0; i--) {
    const currentRow = rows[i];
    //$(rows[i]).remove();
    const deviceUnitId = $(currentRow).find("button").data('id');
    if (deviceUnitId !== '') {
      $(container).append(createToDeleteElements(deviceUnitId));
    }
    $(rows[i]).remove();
  }
}

// when the user deletes a measure unit these elements must be created to notify
// rails to delete the object from the database
function createToDeleteElements (deviceUnitId) {
  let container = document.createElement('div');
  container.className = 'device-unit-to-delete';

  let toDelete = document.createElement('input');
  toDelete.type = 'hidden';
  toDelete.name = `device[device_units_attributes][${deviceUnitId}][id]`;
  toDelete.value = deviceUnitId;
  $(toDelete).appendTo(container);

  toDelete = document.createElement('input');
  toDelete.type = 'hidden';
  toDelete.name = `device[device_units_attributes][${deviceUnitId}][_destroy]`;
  toDelete.value = '1';
  $(toDelete).appendTo(container);

  return container;
}

function addNewMeasureListenerFinal () {
  const btn = $("#add-measure-nested");
  btn.on('click', function () {
    const containerInner = $("#measures-list-nested");
    const containerInnerChildren = $("#measures-list-nested").children();
    const measuresCount = containerInnerChildren.length;
    const toBeCloned = containerInnerChildren[0]
    const clone = $(toBeCloned).clone();
    const removeButton = $(clone).find("button")[0];

    $(clone).find("select").val('');
    $(clone).find("button").attr('data-id', "");

    // remove hidden input with id
    const hiddenInput = $(clone).children().find('.hidden');
    $(hiddenInput).remove();

    const measureSelectInputs = $(clone).find("select[id$='measure_type']")

    $(measureSelectInputs).on('change', function () {
      _onMeasureChange($(this))
    });
    // $(measureSelectInputs).on('change', )
    $(removeButton).on('click', removeDeviceUnitRow);

    $(clone).appendTo(containerInner);

    fixMeasuresOrderFinal ()

    $(this).blur();
    $(".device-unit-to-delete").remove();
  });
}

function _onMeasureChange (obj) {
  updateUnitsFinal(obj);
  _toggleOption(obj)
}

function updateUnitsFinal (obj) {
  const container = $(obj).parent();
  const d_typeInput = $(container).find("select")[0];
  const unitsSelect = $(container).find("select")[1];
  const unitsOptions = $(unitsSelect).find("option");
  const typeValue = $(d_typeInput).val()
  let typeTokens = ''
  if (typeValue !== null) typeTokens = typeValue.split("_")
  const selectedType = typeTokens[0];
  const selectedSubtype = typeTokens[1];
  const selectedUnitOption = $(unitsSelect).find(':selected').val() // when in edit mode and units are already set

  $(unitsOptions).hide();
  $(unitsSelect).val("");
  if (['pv', 'heatpump', 'infrared', 'resistive', 'aircondition', 'wt', 'hotwater'].includes(selectedType)) {
    filterUnitsOptions(unitsOptions, ['kW', 'kWh', 'W']);
  } else if (selectedType === 'boiler' && ['ngas', 'diesel', 'oil'].includes(selectedSubtype)) {
    filterUnitsOptions(unitsOptions, ['liter', 'kWh', 'W']);
  } else if (selectedType === 'boiler' && selectedSubtype === 'biomass') {
    filterUnitsOptions(unitsOptions, ['kg', 'kWh', 'W']);
  } else if (selectedType === 'thermometer') {
    filterUnitsOptions(unitsOptions, ['°C', 'kelvin', 'fahrenheit']);
  } else if (['temperature_indoor', 'temperature_outdoor'].includes($(d_typeInput).val())) {
    filterUnitsOptions(unitsOptions, ['°C', 'kelvin', 'fahrenheit']);
  } else if (['total_electrical_consumption_var', 'total_electrical_consumption_base'].includes($(d_typeInput).val())) {
    filterUnitsOptions(unitsOptions, ['kW', 'kWh', 'W']);
  } else {
    console.log(selectedType, selectedSubtype, unitsOptions);
  }
  $(unitsSelect).val(selectedUnitOption)
}

function _toggleOption (obj) {
}
// end of final code




// ----------------------------------




function removeDeviceUnitRow () {
  const row = $(this).parent();

  if ($(".device-unit-item").length > 1) {
    const container = $("#measures-list-nested");
    const deviceUnitId = $(this).data('id');

    const toDelete = createToDeleteElements(deviceUnitId);
    $(toDelete).appendTo(container);

    $(row).remove();
    fixMeasuresOrderFinal();
  } else {
    console.log('not removing');
  }
}

function fixMeasuresOrderFinal () {
  const container = $("#measures-list-nested");
  const rows = $(container).find('.device-unit-item');

  for (let i = 0; i < rows.length; i++) {
    let row = rows[i];

    const measureInput = $(row).find("[id$='measure_type']");
    const unitsInput = $(row).find("[id$='units']");
    const phaseInput = $(row).find("[id$=phase]");
    const hiddenIdInput = $(row).find("[id$='id']");
    const removeButton = $(row).find("button");

    // for measureInput
    const cloneMeasureName = `device[device_units_attributes][${i}][measure_type]`;
    const cloneMeasureId = `device_device_units_attributes_${i}_measure_type`;
    // for units
    const cloneUnitsName = `device[device_units_attributes][${i}][units]`;
    const cloneUnitsId = `device_device_units_attributes_${i}_units`;
    //for phase
    const clonePhaseName = `device[device_units_attributes][${i}][phase]`;
    const clonePhaseId = `device_device_units_attributes_${i}_phase`;
    // for hidden Id
    const hiddenIdName = `device[device_units_attributes][${i}][id]`
    const hiddenIdId = `device_device_units_attributes_${i}_id`
    // remove button
    const removeButtonId = `remove-measure-nested-${i}`;

    // set attributes
    $(measureInput).attr('name', cloneMeasureName);
    $(measureInput).attr('id', cloneMeasureId);

    $(unitsInput).attr('name', cloneUnitsName);
    $(unitsInput).attr('id', cloneUnitsId);

    $(phaseInput).attr('name', clonePhaseName);
    $(phaseInput).attr('id', clonePhaseId);

    $(hiddenIdInput).attr('name', hiddenIdName);
    $(hiddenIdInput).attr('id', hiddenIdId);

    $(removeButton).attr('id', removeButtonId);

  }
}

function onTypeChangeNested (deviceType) {
  const container = $("#measures-list-nested");

  if (deviceType !== 'aggregator') {
    const removeButtons = $(container).find("button");

    $(removeButtons).each (function () {
      if ($(this).data('id') !== "")
        $(createToDeleteElements($(this).data('id'))).appendTo(container);
    });
    $(container).find("select").prop('disabled', true);

  } else {
    $(".device-unit-to-delete").remove();
    $(container).find("select").prop('disabled', false);
  }
}

function createdDeviceUnitInputNested(measureType, units, id) {
  const container = $("#measures-list-nested");
  let mInput = document.createElement('input');
  mInput.type = 'hidden';
  mInput.name = `device[device_units_attributes][${id}][measure_type]`;
  mInput.value = measureType;
  $(mInput).appendTo(container);

  mInput = document.createElement('input');
  mInput.type = 'hidden';
  mInput.name = `device[device_units_attributes][${id}][units]`;
  mInput.value = units;
  $(mInput).appendTo(container);
}

// =============================================
// END OF NESTED INPUT
// =============================================

function updateSubtypeOptions (element, subtypesOfTypes) {
  const selectedType = $(element).val();
  const options = $(element).find('option');
  const subtypeOptions = $("#device_subtype").find('option');

  const acceptableValues = subtypesOfTypes[selectedType];

  const measuresListRow = $("#measures-list-row-nested");
  const subtypeRow = $("#subtype-row");
  const scenarioRow = $("#scenario-row");
  const fuelTypeRow = $("#fuel-type-row");
  const unitsRow = $("#units-row");

  // clear previous input
  $("#device_subtype").val("");
  $(subtypeOptions).each(function (i, el) {
    if (acceptableValues == null || !acceptableValues.includes($(el).val())) {
      $(el).hide();
    } else {
      $(el).show();
    }
  });

  // also clear aggregator choices if any
  measuresListRow.find(".sol-deselect-all").click();
  if (selectedType == 'aggregator' || selectedType == 'thermometer') {
    // scenarioRow.find('input[type=radio]').prop('disabled', true);
    scenarioRow.find('input[type=radio]').prop('checked', false);
    scenarioRow.hide();
    subtypeRow.hide();

    if (selectedType === 'aggregator') {
      //measuresListRow.show();
      $("#measures-list-2").find("select").prop('disabled', false);
      unitsRow.hide();
    }

  } else {
    //measuresListRow.find(".sol-deselect-all").click();
    //measuresListRow.hide();
    $("#measures-list-2").find("select").prop('disabled', true);
    subtypeRow.show();
    scenarioRow.show();
    unitsRow.show();
  }
  fuelTypeRow.toggle(selectedType == 'boiler');
}

function setUpInitialState (subtypesOfTypes) {
  const selectedType = $("#device_d_type").val();
  const selectedSubtype = $("#device_subtype").val();

  if ($('#device_d_type').val() === 'thermometer') {
    $("#device_subtype").prop('disabled', true);
    $("#device_scenario_base, #device_scenario_var").prop('disabled', true);
    $("#device_scenario_base, #device_scenario_var").prop('checked', false);
  }

  updateSubtypeOptions($("#device_d_type"), subtypesOfTypes);
  if (selectedType) $("#device_d_type").val(selectedType);
  if (selectedSubtype) $("#device_subtype").val(selectedSubtype);

  $('#measures-list').searchableOptionList(
    {
      data,
      multiple: true,
      useBracketParameters: true,
      maxHeight: 300
    }
  );

  $("#measures-list-2").find("select").prop('disabled', true);
  if (selectedType === 'aggregator') {
    $("#measures-list-tr").show();
    $("#measures-list-2").find("select").prop('disabled', false);
  }
}

function filterUnitsOptions (allOptions, visibleUnits) {
  const filtered = $(allOptions).filter(function (i, e) {
    return visibleUnits.includes($(e).val());
  }).each(function (i, e) {
    $(e).show();
  });
}
