import loadCharts from "../../lca/results";

export default function() {
  $('.list-project-item1').on('click', function(e) {
    const projectId = $(e.target).data('project_id')
    const projectName = e.target.textContent

    // enable loading button
    const noProjectNote = $('#selected-project')
    noProjectNote.hide()

    $.ajax({
      url: "/d/real_time_select_project",
      type: "get",
      data: {
          "district_project_id": projectId,
      },
      success: function(data) {
        $('#selected-project').replaceWith(data.html)
        $('#dropdownMenuButton').text(projectName)
        loadCharts()
        $([document.documentElement, document.body]).animate({
            scrollTop: $("#monitoring-section").offset().top
        }, 500);
      },
      error: function(data) {}
    })
  })

  $('.datepicker').flatpickr({ mode: "range"} );
}
