/* This file is called when the LCA results page shows */
import GenericChartHelper from "../../modules/charts/genericChartHelper";
import loadDisposalCharts from "./disposal_charts";

const RENDERED_CHARTS = {}

export default function () {
    setupCharts()
    // setupIrrRoiListener()
    setupEpbtNerListener()

    loadDisposalCharts()
}

function setupCharts() {
  const activeTabPane = $('.tab-pane.active')
  renderActiveCharts(activeTabPane)

  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    const targetDivId = $(e.target).attr('aria-controls')
    const targetDiv = $(`#${targetDivId}`)
    renderActiveCharts(targetDiv)
  })
}

function renderActiveCharts(targetDiv) {
  const ctx = $(targetDiv).find('canvas[id*=-gchart]')
  ctx.each(function (index, element) {
      if(!RENDERED_CHARTS[element.id]) {
        const chartHelper = new GenericChartHelper($(element))
        chartHelper.renderChart()
        RENDERED_CHARTS[element.id] = true
      }
  })
}

function setupIrrRoiListener() {
    const chart = $('#roi_irr-gchart')
    const chartContainer = chart.parents('.generic-chart')[0]

    const table = $('#gen-invest').find('table')

    $(chartContainer).on('input_changed', function(event, value) {
        if(value !== 'aggregated') {
            const td = $(table).find(`td:contains(${value})`)
            $(table).find('tbody tr').hide()
            $(td).parent().show()
        }
    })
}


// TODO: Write this function more elegantly
function setupEpbtNerListener() {
    const charts = $('#epbt-gchart, #ner-gchart')
    let chartsContainers = []
    let selectInputs = []
    $(charts).each(function(index, chart) {
        const container = $(chart).parents('.generic-chart')[0]
        chartsContainers.push(container)
        selectInputs.push($(container).find('select'))
    })
    const table = $('#epbt-table').find('table')
    $(table.find('tbody tr')).hide()
    $(table.find('tbody tr')[0]).show()

    $(chartsContainers).each(function(index, container) {
        $(container).on('input_changed', function(event, value) {
            const tr = $(table).find(`tbody tr`)[parseInt(value)]

            $(selectInputs).each(function(i, select) {
                $(select).val(parseInt(value)).trigger('change', ['auto'])
            })
            $(table).find('tbody tr').hide()
            $(tr).show()
        })
    })


}
