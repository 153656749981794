export function createHiddenInput (id, name, value) {
    let hiddenInput = document.createElement('input')
    if (id !== '' && id !== null) hiddenInput.id = id
    hiddenInput.name = name
    hiddenInput.hidden = true
    $(hiddenInput).attr('value', value)
    return hiddenInput
}

export function createOption (text, value) {
    let option = document.createElement('option')
    option.value = value
    option.text = text
    return option
}

export function createTableRow () {
    let tRow = document.createElement('tr')
    for (let i = 0; i < arguments.length; i++) {
        let tData = document.createElement('td')
        $(tData).append(arguments[i])
        // tData.textContent = arguments[i]
        $(tRow).append(tData)
    }
    return tRow
}

export function createLink (text, href, html_options={}) {
    let a = document.createElement('a')
    a.href = href
    a.text = text
    if (html_options['class']) a.classList = html_options.class
    if (html_options['data']) {
        const data = html_options['data']
        const keys = Object.keys(data)

        for (let i = 0; i < keys.length; i++) {
            $(a).attr(`data-${keys[i]}`, data[keys[i]])
        }
    }
    return a
}