import consumer from "channels/consumer";

export default function initializeIndividual(projectId, charts, tables, multiTables=[]) {
  // initialize datepicker
  let available_dates = []
  $.ajax({
    url: "/available_dates_individual",
    data: {
      "site_pv_id": projectId
    },
    dataType: 'json',
    success: function(response, status, obj) {
      if(response[0] !== null) {
        const from = response[0].split('-');
        let day = from[2].split('T')[0];
        available_dates.push(`${from[0]}-${from[1]}-${day}`)

        const to = response[1].split('-');
        day = to[2].split('T')[0];
        available_dates.push(`${to[0]}-${to[1]}-${day}`)
      }
    }
  }).done(function() {
    initializeDatepicker(projectId, available_dates);
    initializeChartDatepickers(projectId, available_dates);
    initializeResetButtons(charts);
  });

  // add table rows
  $(tables).each(function(i, table) {
    table.addTableRow({
      'project-id': projectId
    });
  });

  getLatestMeasurements(projectId, charts);

  consumer.subscriptions.create({
    channel: "AggregatedMeasurementsChannel",
    id: projectId
  }, {
    connected() {
      // Called when the subscription is ready for use on the server
      console.log(`Connected to channel for project with id: ${projectId}`);
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      fillInitialEmbodiedPerComponent (data['initial_embodied_per_component'])
      $(charts).each(function (i, c) {
        addDataPoint(c, data);
      });

      $(tables).each(function(i, table) {
        table.updateTableRow(projectId, data);
      });

      // multi_header_table
      if (multiTables.length > 0) {
        $(multiTables).each(function(i, table) {
          table.updateMultiHeaderTds(data);
        });
      }
    }
  });


  function findChartByType (plotTypes) {
    let theChart = null;

    if ( Array.isArray (plotTypes) ) {

      $(charts).each(function (index, chart) {
        const chartTypes = chart.getPlotTypes();

        if (Array.isArray(chartTypes)) {
          // check if it includes all wanted lines
          let containsAll = true;
          for (let i = 0; i < plotTypes.length; i++) {
            if (!chartTypes.includes(plotTypes[i])) {
              containsAll = false;
              break;
            }
          }

          if (containsAll) theChart = chart;
        }
      });
    } else {
      $(charts).each(function (index, chart) {
        const chartTypes = chart.getPlotTypes();

        if (!Array.isArray(chartTypes)) {
          if (chartTypes === plotTypes) theChart = chart;
        }
      });
    }

    return theChart;
  };

  function initializeChartDatepickers(projectId, available_dates) {

    $(".chart-datepicker").each(function () {
      const chartArea = $(this).parent();
      const belongToChart = $(chartArea).find('.chart_area_canvas');
      const plotType = $(belongToChart).data('plot_type');

      if (['temperature', 'electrical_consumption', 'electrical_production'].includes($(belongToChart).data('plot_id'))) {
        $(this).flatpickr({
          mode: "range",
          onClose: function(selectedDates, dateStr, instance) {
            const from = selectedDates[0]
            const to = selectedDates[1]
            let dateStrArr = dateStr.split(' to ');
            if (dateStrArr.length == 1) {
              dateStrArr.push(dateStrArr[0]);
            }
            if (from != undefined && to != undefined) {
              const chartArea = $(this.element).parent();
              const belongToChart = $(chartArea).find('.chart_area_canvas');
              const plotType = $(belongToChart).data('plot_type');
              drawData(projectId, plotType, dateStrArr[0], dateStrArr[1]);
            }
          }
        });
      } else {
        $(this).flatpickr({
          mode: "range",
          enable: [{
            from: available_dates[0],
            to: available_dates[1]
          }],
          onClose: function(selectedDates, dateStr, instance) {
            const chartArea = $(this.element);
            datepickerOnClose(chartArea, selectedDates, dateStr, instance);
          }
        });
      }
    });
  };

  function datepickerOnClose (chartArea, selectedDates, dateStr, instance) {
    const from = selectedDates[0]
    const to = selectedDates[1]
    let dateStrArr = dateStr.split(' to ');
    if (dateStrArr.length == 1) {
      dateStrArr.push(dateStrArr[0]);
    }
    if (from != undefined && to != undefined) {
      const container = $(chartArea).parent();
      const belongToChart = $(container).find('.chart_area_canvas');
      const plotType = $(belongToChart).data('plot_type');
      drawData(projectId, plotType, dateStrArr[0], dateStrArr[1]);
    }
  }

  function initializeDatepicker(projectId, available_dates) {
    $("#real-time-datepicker").flatpickr({
      mode: "range",
      enable: [{
        from: available_dates[0],
        to: available_dates[1]
      }],
      onValueUpdate: function(selectedDates, dateStr, instance) {
        const from = selectedDates[0]
        const to = selectedDates[1]
        const dateStrArr = dateStr.split(' to ');
        if (from !== undefined && to !== undefined) {
          $(charts).each(function(index, chart) {
            chart.clearDataPoints(projectId);
          });
          $.ajax({
            url: "/latest_aggregated_measurements",
            data: {
              from: dateStrArr[0],
              to: dateStrArr[1],
              "site_pv_id": projectId
            },
            dataType: 'json',
            success: function(response, status, obj) {
              const theChart = findChartByType()
              $(charts).each(function(i, chart) {
                // smooth line here
                this.addDataPoint(chart, response);
              });

              $(tables).each(function(i, table) {
                $(response).each(function(j, row) {
                  table.updateTableRow(projectId, row);
                });
              });

              // multi_header_table
              if (multiTables.length > 0) {
                $(multiTables).each(function(i, table) {
                  $(response).each(function(j, row) {
                    table.updateMultiHeaderTds(row);
                  });
                });
              }
            }
          });
        }
      }
    });
  };

  function drawData (projectId, plotType, from, to) {

    console.log(`send request to server from ${from} to ${to} for ${plotType}`);
    $.ajax({
      url: "/draw_type_data",
      data: {
        from,
        to,
        plot_type: plotType,
        project_id: projectId
      },
      dataType: 'json',
      success: function (response, status, obj) {

        const theChart = findChartByType(plotType);
        console.log(response);
        theChart.clearDataPoints();
        theChart.pauseMonitoring(false);
        addDataPoint(theChart, response);

        let isAggregated;

        if(response.length > 0) {
          const firstItem = response[0]
          const firstTimestamp = firstItem['timestamp']
          isAggregated = !firstTimestamp.includes('T')
        }

        const smoothLine = isAggregated || (!isAggregated && response.length > 48)

        if (smoothLine) theChart.smoothChartLine();
        else theChart.smoothChartLine(false);

        console.log('Pausing plot');
        theChart.pauseMonitoring(true);

        // show new data
      }
    });
  };

  // retrieves the latest measurements from server
  // for the initial view of the page
  // however this should be done individually in the individual page
  // params:
  // * single: if single is empty then show the latest measurements only on a single chart
  //           otherwise is the name of the plot, update globally charts and tables
  function getLatestMeasurements (projectId, charts, single='') {
    // communicate with server to get the latest measurements to plot
    $.ajax({
      url: "/latest_aggregated_measurements",
      data: {
        "site_pv_id": projectId
      },
      dataType: 'json',
      success: function(response, status, obj) {

        if (single) {

          const theChart = findChartByType(single);

          if (theChart.isPaused()) {
            console.log('Unpausing plot');
            theChart.pauseMonitoring(false);
          }
          theChart.clearDataPoints();
          theChart.smoothChartLine(false);
          addDataPoint(theChart, response);

        } else {
          $(charts).each(function(i, chart) {
            addDataPoint(chart, response);
          });

          $(tables).each(function(i, table) {
            $(response).each(function(j, row) {
              table.updateTableRow(projectId, row);
            });
          });

          // multi_header_table
          if (multiTables.length > 0) {
            $(multiTables).each(function(i, table) {
              $(response).each(function(j, row) {
                table.updateMultiHeaderTds(row);
              });
            });
          }

          createInitialEmbodiedRows(response[response.length - 1]['initial_embodied_per_component'])
          fillInitialEmbodiedPerComponent(response[response.length - 1]['initial_embodied_per_component']);
        }
      }
    });
  };

  function initializeResetButtons (charts) {
    const resetButtons = $('.btn-reset');
    $(resetButtons).on('click', function () {
      const chartArea = $(this).parent();
      const belongToChart = $(chartArea).find('.chart_area_canvas');
      const plotType = $(belongToChart).data('plot_type');
      const datepicker = $(chartArea).find(".chart-datepicker");

      $(datepicker).val('');
      getLatestMeasurements (projectId, charts, plotType);
    });
  };

  // adds data point to the corresponding graphs
  // checking for special cases
  function addDataPoint (chart, response) {
    const plotLines = chart.getPlotTypes();

    $(response).each(function(j, row) {

      if (Array.isArray(plotLines)) {

        $(plotLines).each(function (k, p) {
          chart.addDataPoint(p, row['timestamp'], row[p]);
        });

      } else {

        chart.addDataPoint(plotLines, row['timestamp'], row[plotLines]);

      }
    });
  };
};

// new initial embodied table

// a method that creates the table rows based on the save analysis
function createInitialEmbodiedRows (dict) {
  const table = $('#initial-embodied-tbl');

  $.each(dict, function (key, value) {
    const rowId = key.toLowerCase().replace(' ', '_') + '-tr';
    let row = document.createElement('tr');
    row.id = rowId;
    let nameTd = document.createElement('td');
    let co2Td = document.createElement('td');
    let peTd = document.createElement('td');
    // let recycledTd = document.createElement('td');

    co2Td.className = 'embodied-co2';
    peTd.className = 'embodied-pe';
    // recycledTd.className = 'recycled-share';

    nameTd.innerHTML = key;
    co2Td.innerHTML = '-';
    peTd.innerHTML = '-';
    // recycledTd.innerHTML = '-';

    $(row).append(nameTd);
    $(row).append(co2Td);
    $(row).append(peTd);
    // $(row).append(recycledTd);

    $(table).find('tbody').append(row);
  });
};

function fixInitialEmbodiedRows (dict) {

};
// a method that checks if components have been added/removed to adjust rows
// a method that updates the values of the talbe for the exististing rows
function fillInitialEmbodiedPerComponent (dict) {
  const table = $('#initial-embodied-tbl');

  $.each( dict, function( key, value ) {
    const keyTransformed = key.toLowerCase().replace(' ', '_');
    const row = table.find(`#${keyTransformed}-tr`);
    const co2Td = $(row).find('.embodied-co2');
    const peTd = $(row).find('.embodied-pe');
    // const recycledTd = $(row).find('.recycled-share');

    $(co2Td).text(value['capital_co2']);
    $(peTd).text(value['pe_cost']);
    // $(recycledTd).text(value['recycled_share']);
  });
};
