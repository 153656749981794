let container
let downpaymentInput

export default function() {
  container = $('#loan-subform')
  downpaymentInput = $(container).find('input[id*=down_payment]')

  $(downpaymentInput).on('keyup', onDownpaymentChange)
  $(downpaymentInput).on('change', onDownpaymentChange)

  toggleInputs($(downpaymentInput).val() == 1)
}

function onDownpaymentChange(e) {
  const value = e.target.value
  toggleInputs(value)
}

function toggleInputs(downPaymentValue) {
  const inputs = $(container).find(".form-group:not(:first)")
  if(downPaymentValue == 1)
    $(inputs).find('input,select').each(function() { $(this).val(null) })
  $(inputs).toggle(downPaymentValue != 1)
}
