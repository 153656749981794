// This file is used to load all javascript related to
// the district module of VERIFY into the main file
// called application.js
// it accepts as parameter the path of the current page, because
// it is already found in application.js
import districtProjectDataManagement from './district_projects/data_management'
import lcaAnalysis from './district_projects/lca_analysis'
import realTimeMonitoring from './district_projects/real_time_monitoring'

let currentPath, page

export default function(path) {
  currentPath = path
  setPageName()

  districtProjects()

}

function districtProjects(path) {
  if(page === 'data_management') {
    districtProjectDataManagement()
  } else if (page === 'real_time_monitoring') {
    realTimeMonitoring()
  } else if (page === 'lca_analysis') {
    lcaAnalysis()
  }
}

function setPageName() {
  if(currentPath === '/d/data_management')
    page = 'data_management'
  else if (currentPath === '/d/real_time_monitoring')
    page = 'real_time_monitoring'
  else if(matchesRegex(currentPath, /^\/d\/district_projects\/(\d+)\/lca_analysis/))
    page = 'lca_analysis'
  else
    page = ''
}

function matchesRegex(regex) {
  const result = currentPath.match(regex)
  const isNull = result === null
  const matches = result.length > 0
  return !isNull && matches
}
