let insulationTables

export default function() {
  _initializeFormElements()
  _setUpListeners()
}

function _initializeFormElements() {
  insulationTables = $("#insulation_old_table, #insulation_table")
}

function _setUpListeners() {
  $(insulationTables).each(function() {
    const table = this
    const applicationSelect = $(table).find('td .orientation')
    const materialSelect = $(table).find('td .material')
    $(applicationSelect).on('change', { table }, _onSelectChange)
    $(materialSelect).on('change', { table }, _onMaterialChange)
  })
}

function _onSelectChange(event) {
  const table = event.data.table
  const applicationSelect = event.target
  const tableRow = $(applicationSelect).parents('tr')
  const selection = applicationSelect.value

  const thickessTd = $(tableRow).find('td:nth(2)')
  const selectInput = $(thickessTd).find('select')
  const textInput = $(thickessTd).find('input')
  const diameterInput = $(tableRow).find('.pipes_diameter')

  if(selection.toLowerCase() === 'pipes') {
    _showThicknessSelect(selectInput, textInput)
    _updateOptions(selectInput, 'pipes')
    $(diameterInput).removeClass('d-none')
  } else {
    _hideThicknessSelect(selectInput, textInput)
    $(diameterInput).addClass('d-none')
  }
}

function _onMaterialChange(event) {
  const table = event.data.table
  const materialSelect = event.target
  const selection = materialSelect.value
  const tableRow = $(materialSelect).parents('tr')
  const thickessTd = $(tableRow).find('td:nth(2)')
  const selectInput = $(thickessTd).find('select')
  const textInput = $(thickessTd).find('input')

  if(selection.toLowerCase() === 'k-flex (panels)') {
    _showThicknessSelect(selectInput, textInput)
    _updateOptions(selectInput, 'panels')
  } else {
    _hideThicknessSelect(selectInput, textInput)
  }
}

function _showThicknessSelect(selectInput, textInput) {
  $(selectInput).prop('disabled', false)
  $(selectInput).removeClass('d-none')

  $(textInput).prop('disabled', 'disabled')
  $(textInput).addClass('d-none')
}

function _hideThicknessSelect(selectInput, textInput) {
  $(textInput).prop('disabled', false)
  $(textInput).removeClass('d-none')

  $(selectInput).prop('disabled', 'disabled')
  $(selectInput).addClass('d-none')
}

const PIPES_OPTIONS = [9, 13, 19, 25, 32]
const PANELS_OPTIONS = [20, 25, 30, 40, 46, 52, 62, 80]
function _updateOptions(select, method) {
  if(method === 'pipes') {
    _replaceOptions(select, PIPES_OPTIONS)
  } else {
    _replaceOptions(select, PANELS_OPTIONS)
  }
}

// TODO: Export this function into another file
function _replaceOptions(select, options) {
  $(select).empty()
  for(let i = 0; i < options.length; i++) {
    const option = options[i]
    let optionElement = document.createElement('option')
    optionElement.value = option
    optionElement.textContent = option
    $(select).append(optionElement)
  }
}
