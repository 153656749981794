export default class ProductionFormHandler {

  // nameInParams is used to submit the form to rails with the correct names
  constructor (parentContainer, parentType, genType, callbacks={}) {
    this.generators = [];
    this.generatorsToDelete = [];
    this.mode = "new";
    this.currentGenerator = -1;
    this.destroyCounter = -1;

    this.generatorType = genType;
    this.parentContainer = $(parentContainer);
    this.nestedItemsContainer = $(this.parentContainer).find('.nested-items-container')

    this.parentType = parentType
    // give as argument or find dynamically
    if (genType === 'pv') this.nameInParams = 'pv_components';
    else if (genType === 'wt') this.nameInParams = 'wind_turbines';
    else if (genType === 'rf') this.nameInParams = 'res_farms'
    else if (genType === 'ac') this.nameInParams = 'active_components'
    else if (genType === 'pc') this.nameInParams = 'passive_components'
    else if (genType === 'bt') this.nameInParams = 'batteries'
    else this.nameInParams = 'abstract_components'

    this.modalForm = $(parentContainer).find('.modal');

    this.callbacks = callbacks;

    this._addButtonListeners(this);

    this._loadServerPvs(this);

    this._editDestroyListeners(this);

    this._addExtraListeners(this);

    if(this.callbacks.onInitialize) this.callbacks.onInitialize(this)
  }

  // initialize pvs array for existing pvs for edit form
  _loadServerPvs (obj) {
    this.parentContainer.find(`.${this.generatorType}-container`).each(function (index, element) {
      let generatorObject = { id: index }
      $(this).find('input').each(function (index, input) {
        generatorObject[$(input).attr('id')] = $(input).val()
      });
      obj.generators.push(generatorObject)
    });
  }

  // adds button listeners to generic buttons like add, clear etc
  _addButtonListeners (obj) {
    const addBtn = this.parentContainer.find('.add-generator-btn');
    addBtn.on('click', function () {
      obj.mode = "new";
      obj._resetModalForm(obj);
      $(obj.modalForm).modal('toggle')
    });

    const clearBtn = this.parentContainer.find('.clear-generators-btn');
    clearBtn.on('click', function () {
      obj._clearGenerators(obj);
    });

    const saveChangesBtn = this.parentContainer.find('#save-changes-btn');
    saveChangesBtn.on('click', function () {
      obj._getInputValues(obj);
      obj._resetModalForm(obj);
      $(obj.modalForm).modal('hide');
    });
  }

  _createButtonElement (obj, id, text, bType) {
     let button = document.createElement('button');
     // button.innerHTML = text;
     $(button).addClass(`close ${bType}-pv`);
     $(button).data('type', bType);
     button.type = 'button';
     if (bType === 'edit') {
       button.innerHTML = text;
       $(button).on('click', function () {
       const parentId = $($(this).parent()).attr('id');
         obj._editModeModal(parentId);
       });
     } else if (bType === 'destroy') {
       button.innerHTML = '×';
       $(button).on('click', function () {
         const parentId = $($(this).parent()).attr('id');
         obj._destroyGenerator(obj, parentId);
       });
     }
     return button;
  }

  _editDestroyListeners (obj) {
    const genType = $(obj.parentContainer).data('gen_type')
    $(obj.parentContainer).find(`.${genType}-container`).on('click', function () {
      const id = $(this).attr('id')
      obj._editModeModal(id);
    })

    // $(obj.parentContainer).find(`.edit-${this.generatorType}`).on('click', function () {
    //   const parentId = $($(this).parent()).attr('id');
    //   obj._editModeModal(parentId);
    // })

    $(obj.parentContainer).find(`.destroy-${this.generatorType}`).on('click', function () {
      const parentId = $($(this).parent()).attr('id');
      obj._destroyGenerator(obj, parentId);
    })
  }

  _editModeModal (generatorId) {
    this.mode = 'edit';
    this.currentGenerator = generatorId;

    const modalForm = $(this.modalForm).find(".modal-body");

    $(this.modalForm).modal('toggle');
    // get values from pvs object
    // set to 0 for testing purposes
    const generator = this.generators[this.currentGenerator.split('_')[1]]
    $(modalForm).find('input[type=number]:not(:disabled), input[type=text]:not(:disabled), select:not(:disabled)').each(function (index, el) {
      const id = $(el).attr('id');
      $(el).val(generator[id]);
    });

    // material - Fix this - do it more generic
    // const pvMaterial = pv['material']
    // $(modalForm).find(`#${pvMaterial}`).prop('checked', true)
  }

  _createGeneratorElement (obj, id) {
    let pvContainer = document.createElement('div');
    pvContainer.className = `${this.generatorType}-container nested-item-container`;
    pvContainer.id = id;
    pvContainer.innerHTML = id;
    // $(pvContainer).append(this._createButtonElement(obj, 'pv-1-edit', 'View', 'edit'));
    $(pvContainer).append(this._createButtonElement(obj, 'pv-1-destroy', 'Destroy', 'destroy'));
    $(pvContainer).on('click', function () {
      obj._editModeModal(pvContainer.id)
    })
    if(obj.callbacks.onAddGenerator) obj.callbacks.onAddGenerator(obj)
    return pvContainer;
  }

  _createHiddenInputElement(id, value, attributesId) {
    const g = this.generatorType

    let element = document.createElement('input');
    element.id = id;
    element.name = `${this.parentType}[${this.nameInParams}_attributes][${attributesId}][${id}]`;
    $(element).attr('value', value);
    element.hidden = true;
    return element;
  }

  _getInputValues (obj) {
    const modalForm = $(obj.modalForm).find('.modal-body');
    const container = this.nestedItemsContainer;

    let generatorContainer = null;
    let generatorObject = null;

    if (obj.mode === 'new') {
      generatorContainer = this._createGeneratorElement(obj, `${obj.generatorType}_${this.generators.length}`);
      generatorObject = { id: `${this.generators.length}` };
    } else if (obj.mode === 'edit') {
      generatorContainer = this.parentContainer.find(`#${this.currentGenerator}`)
      generatorObject = this.generators[this.currentGenerator.split('_')[1]]
    }

    const attributesId = new Date().getTime() + this.generators.length

    $(modalForm).find('input[type=number]:not(:disabled), input[type=text]:not(:disabled), select:not(:disabled)').each(function (index, el) {
      const inputElement = $(el);
      if (obj.mode === 'new') {
        const hiddenInput = obj._createHiddenInputElement(inputElement.attr('id'), inputElement.val(), attributesId)
        $(generatorContainer).append(hiddenInput)
        generatorObject[inputElement.attr('id')] = inputElement.val();
      } else if (obj.mode === 'edit') {
        generatorObject[inputElement.attr('id')] = inputElement.val();
        $(generatorContainer).find(`[id="${inputElement.attr('id')}"]`).attr('value', inputElement.val())
      }
    });

    /*
    // material type
    // I think this is useless. It is deprecated, not used anymore and causing a bug
    // However, I don't delete it yet until I am sure.
    const radioBtn = $(modalForm).find('input[type=radio]:checked');
    generatorObject['material'] = radioBtn.val();
    if (this.mode === 'new') {
      $(generatorContainer).append(this._createHiddenInputElement('material', radioBtn.val()));
    } else if (this.mode === 'edit') {
      $(generatorContainer).find('#material').attr('value', radioBtn.val())
    }
    */


    if (this.mode === 'new') {
      this.generators.push(generatorObject);
      $(container).append(generatorContainer);
    } else if (this.mode === 'edit') {
      const index = this.generators.findIndex((pv) => pv['id'] == this.currentGenerator.split('_')[1])
      this.generators[index] = generatorObject;
    }

    //updateSessionStorage();
  }

  _resetModalForm (obj) {
    const form = $(obj.modalForm);
    // TODO: Make sure the line below is correct
    form.find('.modal-body :input:not(:radio):not(:hidden)').val('');
    form.find('.modal-body select').each(function (index, s) {
      const firstOption = $(s).find('option:first');
      $(s).val($(firstOption).val());
    });
  }

  _clearGenerators (obj) {
    const iterations = this.generators.length - 1;
    for (let i = iterations; i >= 0; i--)
      this._destroyGenerator(obj, `${this.generatorType}_${this.generators[i]['id']}`);
  };

  _destroyGenerator (obj, generatorId) {
    // find element position in model
    const container = this.parentContainer.find(`#${generatorId}`);
    const fromServer = container.find(`#${this.generatorType}_id`).length > 0;
    const index = generatorId.split('_')[1]

    // delete element from html
    this.parentContainer.find(`#${generatorId}`).remove();

    // add remove "instuction" for rails to delete from server
    // if pv was loaded from the server
    if (fromServer) {
        const generatorServerId = this.generators[index][`${this.generatorType}_id`];
        this._createDestroyHiddenInput(generatorServerId);
        this.generatorsToDelete.push(generatorServerId);
    }

    // remove from model
    this.generators.splice(index, 1)

    // update elements order
    this._updatePvElement(obj);
    // updateSessionStorage();
  }

  _createDestroyHiddenInput (generatorServerId) {
    let generatorDestroyContainer = document.createElement('div')
    generatorDestroyContainer.className = `${this.generatorType}-destroy-container`;
    generatorDestroyContainer.id = `${this.generatorType}_${generatorServerId}`;

    let destroyElement = document.createElement('input');
    destroyElement.id = `destroy_${this.generatorType}_${generatorServerId}_id`;
    destroyElement.name = `${this.parentType}[${this.nameInParams}_attributes][${generatorServerId}][id]`;
    destroyElement.hidden = true;
    $(destroyElement).attr('value', generatorServerId);
    $(generatorDestroyContainer).append(destroyElement);

    destroyElement = document.createElement('input');
    destroyElement.id = `destroy_${this.generatorType}_${generatorServerId}_validation`;
    destroyElement.name = `${this.parentType}[${this.nameInParams}_attributes][${generatorServerId}][_destroy]`;
    destroyElement.hidden = true;
    $(destroyElement).attr('value', 1);

    $(generatorDestroyContainer).append(destroyElement);
    $(this.nestedItemsContainer).append(generatorDestroyContainer)
  }

  _updatePvElement (obj) {
    const container = $(this.nestedItemsContainer)
    const children = $(container).children();

    children.each(function (index, el) {
      $(el).attr('id', `${obj.generatorType}_${index}`);
      //el.innerHTML = `pv_${index}`;
    });

    $(obj.generators).each(function (index, el) {
      obj.generators[index]['id'] = index;
    });
  }

  // add some specific listeners e.g. azimuth for pvs
  _addExtraListeners (obj) {
    $('#tilt_action, #azimuth_action').on('change', function() {

      const action = this.id.split('_')[0];
      const valueField = $(obj.modalForm).find(`#${action}_value`)
      if (this.value === 'optimal') {
        valueField.val(999);
        valueField.prop("readonly", true);
      } else if (this.value === 'specific') {
        valueField.val('');
        valueField.prop("readonly", false);
        valueField.attr('placeholder', 'Please add angle')
      }
    });
  }
}
