import "../../../packs/sol";

// TODO: Optimize queries - start from container then top to bottom
export default function() {
  renderMaterialSelect()
  autoFillOtherMaterialsListener()
}

// TODO: Export all this into a simple module
function renderMaterialSelect() {
  $('.material-select').each(function() {
    const obj = this
    const componentContainer = $(obj).parents('.component-inputs')[0]
    const materialInputsContainer = $(componentContainer).find('.materials-container')
    $(this).searchableOptionList({
      showSelectAll: false,
      events: {
        onInitialized: function() {
          const solContainer = $(obj).prev()
          const solCheckBoxes = $(solContainer).find('.sol-checkbox')
          enableActiveOptions(obj, solCheckBoxes)

          defaultButtonListener()

          autoFillOtherMaterials(componentContainer)
        },
        onChange: function (sol,changedElements) {
          const option = changedElements[0]
          const material = option.value
          if(option.checked) onSelectOption(materialInputsContainer, material)
          else onRemoveOption(materialInputsContainer, material)
        }
    }})
  });
}

function addSolCheckboxListeners(solCheckBoxes) {
  $(solCheckBoxes).on('click', function() {
    onSelectOption(this)
  })
}

function enableActiveOptions(obj, solCheckBoxes) {
  const activeOptions = $(obj).find('option[data-active=true]')
  const nonActiveOptions = $(obj).find('option[data-active=false]')

  $(activeOptions.each(function() {
    const option = this
    const value = option.value
    const solCheckbox = Array.from($(solCheckBoxes)).filter(e => e.value == value)
    $(solCheckbox).each(function() { if(!this.checked) $(this).click() })
  }))

  $(nonActiveOptions.each(function() {

    const option = this
    const value = option.value
    const solCheckbox = Array.from($(solCheckBoxes)).filter(e => e.value == value)
    $(solCheckbox).each(function() {
      if(this.checked) $(this).click()
    })
  }))
}

function onSelectOption(container, material) {
  const materialRow = $(container).find(`.row[data-material=${material}]`)
  enableInput($(materialRow).find('input, select'))
  $(materialRow).show()
}

function onRemoveOption(container, material) {
  const materialRow = $(container).find(`.row[data-material=${material}]`)
  disableInput($(materialRow).find('input'))
  disableInput($(materialRow).find('select'))
  $(materialRow).hide()
}

function defaultButtonListener() {
  $('.component-inputs').each(function() {
    const container = this
    const button = $(container).find('.reset-button')
    const solCheckBoxes = $(container).find('.sol-checkbox')

    $(button).on('click', function() {
      const textInputs = $(container).find('input[type=text]')
      const selectInputs = $(container).find('select')

      $(textInputs).each(function() {
        const input = this
        $(input).val($(input).data('default'))
      })

      $(selectInputs).each(function() {
        const input = this
        $(this).val($(input).data('default'));
      })

      // enable and disable materials
      const defaultOptions = Array.from($(container).find('option[data-default=true]'))
      const defaultMaterials = defaultOptions.map(e => e.value)
      // TODO: Export into function
      const active = Array.from($(solCheckBoxes)).filter(e => defaultMaterials.includes(e.value))
      const nonActive = Array.from($(solCheckBoxes)).filter(e => !defaultMaterials.includes(e.value))
      $(active).each(function() { if(!this.checked) $(this).click() })
      $(nonActive).each(function() { if(this.checked) $(this).click() })

      autoFillOtherMaterials(container)
    })
  })
}

function autoFillOtherMaterialsListener() {
  // for each container
  $('.component-inputs').each(function() {
    const container = this
    const allInputs = Array.from($(this).find('input[type=text]'))
    const customInputs = allInputs.slice(0, -1).filter(e => $(e).parent().is(':visible') == true)

    const otherInput = allInputs[allInputs.length - 1]

    $(customInputs).on('keyup', function() {
      let value = customInputs.map(e => e.value)
                                .filter(e => e != '')
                                .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
      value = Math.round(value * 10000) / 10000

      const totalWeightInput = $(container).find('input[name=total_weight]')
      let totalWeight = $(totalWeightInput).val()
      if(totalWeight) {
        totalWeight = parseFloat(totalWeight)
        value = totalWeight - value
      } else {
        value = ''
      }

      $(otherInput).val(value)
    })
  })
}

// TODO: Find a way to re-use the code in this function with the one above
function autoFillOtherMaterials(container) {
  const allInputs = Array.from($(container).find('input[type=text]'))
  const customInputs = allInputs.slice(1, -1).filter(e => $(e).parent().is(':visible') == true)
  const otherInput = allInputs[allInputs.length - 1]

  let value = customInputs.map(e => e.value)
                            .filter(e => e != '')
                            .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
  value = Math.round(value * 10000) / 10000

  const totalWeightInput = $(container).find('input[name=total_weight]')
  let totalWeight = $(totalWeightInput).val()
  if(totalWeight) {
    totalWeight = parseFloat(totalWeight)
    value = totalWeight - value
  } else {
    value = ''
  }

  $(otherInput).val(value)
}

function disableInput(inputElement) {
  $(inputElement).attr('disabled', 'disabled')
}

function enableInput(inputElement) {
  $(inputElement).removeAttr('disabled')
}
