export default function dragTableRow () {
  $('.table-dragable tbody .grabable').mousedown(function (e) {
    var tr = $(e.target).closest('tr'), sy = e.pageY, drag;
    if ($(e.target).is('tr')) tr = $(e.target);

    var index = tr.index();

    $(tr).addClass('grabbed');

    function move (e) {
      if (!drag && Math.abs(e.pageY - sy) < 10) return;
      drag = true;
      tr.siblings().each(function() {
        var s = $(this), i = s.index(), y = s.offset().top;
        if (e.pageY >= y && e.pageY < y + s.outerHeight()) {
          if (i < tr.index()) s.insertAfter(tr);
          else s.insertBefore(tr);
          return false;
        }
      });
    }
    function up (e) {
      if (drag && index != tr.index()) {
        drag = false;
      }
      $(document).unbind('mousemove', move).unbind('mouseup', up);
      $(tr).removeClass('grabbed');

      updatePriorities($(tr).parent(), tr);
    }
    $(document).mousemove(move).mouseup(up);
  });

  // update priorities when a user deletes an element from sol
  $('.table-dragable').each(function() {
    const solSelector = $(this).siblings().toArray().find(e => $(e).hasClass('multiple-components-selector'))
    const tbl = this
    $(solSelector).on('sol-changed', function() {
      updatePriorities($(tbl).find('tbody').first(), 0)
    })
  })
};

function updatePriorities (tableBody, currentTr) {
  const children = $(tableBody).children(':visible');
  let aboveUnset = false;
  // old line before dynamic selection of components
  // currently the row index is not the index the user sees in the form
  // it depends on the visible children
  // const index = $(currentTr).index();
  const index = children.index(currentTr);
  for (let i = 0; i < index; i++){
    if ($(children[i]).find('*[id*=Priority]').val() === '' || $(children[i]).find('*[id*=Priority]').val() === '0') {
      aboveUnset = true;
      break;
    }
  }

  if (index === 0 || !aboveUnset) {
    $(currentTr).children().find('input[id*=Priority]').val(index + 1);
    $(children).each(function () {
      const priorityInput = $(this).children().find('input[id*=Priority]');
      // --> old line before dynamic components in form
      // if ($(priorityInput).val() != '0' && $(priorityInput).val() != '') $(priorityInput).val($(this).index() + 1);
      if ($(priorityInput).val() !== '0' && $(priorityInput).val() !== '') $(priorityInput).val(children.index($(this)) + 1);
    });
  } else if (index > 0 && aboveUnset) {
    $(currentTr).find('*[id*=Priority]').val('0');
    for (let i = 0; i < children.length; i++){
      const priorityValue = $(children[i]).find('*[id*=Priority]').val()
      if (priorityValue === '' || priorityValue === '0')
        break;
      // --> old line before dynamic components in form
      // $(children[i]).find('*[id*=Priority]').val($(children[i]).index() + 1);
      $(children[i]).find('*[id*=Priority]').val(children.index(children[i]) + 1);
    }
  }
}
