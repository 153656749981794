import ComponentsHotWaterConnector from "./componentsHotWaterConnector";
import clearFormElements from "../../helpers/inputs_cleaner";

// WATCH OUT: Some functions require the sol components to be initialized first
// I lost a lot of time trying to find out why the powerInput on hot water was not updating

// TODO: When the user deselects a whole active components category the component he previously set
// TODO (continue): are removed. They must also be removed from the water storage options, too.

// Risky but worked
let varHotWaterConnector, baseHotWaterConnector

export default function () {
    // find the existing components if in edit mode
    const divHeatingVar = $('#div-heating-source')
                            .find('input[name*=exists]')
                            .toArray()
                            .filter(e => $(e).attr('value') === 'true')
                            .map(e => $(e).attr('id').split('[')[1].replaceAll(']', '').toLowerCase())

    const divHeatingBase = $('#div-old-heating-source')
                            .find('input[name*=exists]')
                            .toArray()
                            .filter(e => $(e).attr('value') === 'true')
                            .map(e => $(e).attr('id').split('[')[1].replaceAll(']', '').toLowerCase())

    setUpSolDropdowns()
    varHotWaterConnector = new ComponentsHotWaterConnector($(`#div-water-storage-source`), $('#div-heating-source'), divHeatingVar)
    baseHotWaterConnector = new ComponentsHotWaterConnector($(`#div-old-water-storage-source`), $('#div-old-heating-source'), divHeatingBase)
    setUpListeners(varHotWaterConnector, baseHotWaterConnector)
}

function setUpListeners (varHotWaterConnector, baseHotWaterConnector) {
    $("input[name*='Thermal rating (kW)']").on('keyup', function (event) {
        const componentName = $(event.target).attr('name').split('[').at(2).trim().replace('__', ' ')
        if ($(event.target).attr('name').includes('old')) {
            baseHotWaterConnector.updatePowerValue(componentName, event.target.value)
        } else {
            varHotWaterConnector.updatePowerValue(componentName, event.target.value)
        }
    })
}

function setUpSolDropdowns () {
    // TODO: after refresh/errors: if some options have been selected for example Thermal rating
    // the component should be active if user refreshes the page

    // find all the components selectors
    $('.multiple-components-selector').each(function (index, selector) {

        const dataVariable = $(selector).data('variable_name')
        $(selector).searchableOptionList({
            data: window[dataVariable],
            events: {
                onChange: function (sol, changedElements) {
                    if (changedElements.length === 1) {
                        _onAddSingle(sol, changedElements[0])
                    } else {
                        _onAddMultiple(sol, changedElements)
                    }
                    $(this.$originalElement).trigger('sol-changed')
                },
                onInitialized: function (sol) {
                    const container = sol.$container.parent()
                    const table = $(container).find('table')[0]
                    const trows = $(table).find('tr')

                    $(trows).each(function (rowIndex, trow) {
                        // find component
                        const name = $(trow).children()[0].textContent.toLowerCase()
                        if (name === 'i') {

                        } else {
                            const caseSensitiveName = $(trow).children()[0].textContent

                            // if at least one field has value
                            let found
                            // check priority
                            const priority = $(trow).find('input[id*=Priority]').val()
                            found = (priority !== undefined && priority > 0)

                            // check thermal rating
                            if (!found) {
                                const thermalRating = $(trow).find('input[id*="Thermal rating"]').val()
                                found = (thermalRating !== undefined && thermalRating > 0)
                            }

                            // check property
                            if (!found) {
                                const property = $(trow).find('input[id*="Property"]:checked')
                                found = (property.length > 0)
                            }

                            // check equivalent users
                            if (!found) {
                                const users = $(trow).find('input[id*="Equivalent_Users"]').val()
                                found = (users !== undefined && users > 0)
                            }

                            // check by value: true/false (sometimes after reload it stays as true but it remains hidden)
                            if(!found) {
                                let value = $(container.find('input[name*=exists]')).toArray()
                                    .find(e => $(e).attr('name').includes(caseSensitiveName))
                                if(value) value = value.value
                                found = (value === 'true')
                            }

                            const checkbox = $(sol.$container).find(`input[value*='${name}']`)
                            if (found && !$(checkbox).prop('checked')) checkbox.click()
                        }
                    })

                    const scenario = $(container).attr('id').includes('old') ? 'base' : 'var'
                    if (scenario === 'base') {
                        baseHotWaterConnector.findPowerAfterReload()
                    } else if (scenario === 'var') {
                        varHotWaterConnector.findPowerAfterReload()
                    }

                }
            }
        });
    })
}

function _onAddSingle(sol, element) {
    const container = sol.$container.parent()
    const scenario = $(container).attr('id').includes('old') ? 'base' : 'var'
    const isChanged = element.checked
    const value = element.value
    const trow = $("#" + value)
    const caseSensitiveName = $(trow.children()[0]).find('span')[0].textContent
    // the id as it was, was not working
    const existsInput = $(container).find("input[id*='[" + caseSensitiveName + "][exists]']")
    // find the id of the hidden input to set exists: true/false
    // so that when the object is edited rails will know which components
    // are checked
    if (isChanged) {
        trow.show()
        existsInput.val('true')
        // find components name and fuel
        const componentType = element.value.split('-').at(1)
        if (scenario === 'base') {
            baseHotWaterConnector.addComponentOption(componentType)
        } else if (scenario === 'var') {
            varHotWaterConnector.addComponentOption(componentType)
        }
    } else {
        trow.hide()
        existsInput.val('false')
        _onComponentRemove(trow, scenario)
    }
}

function _onAddMultiple(sol, elements) {
    const obj = this
    $(elements).each(function (index, element) {
        _onAddSingle(sol, element)
    })
}

// call this when a component is removed from multiple select
function _onComponentRemove(trow, scenario) {
    // TODO: Find a better way to retrieve this
    const componentType = $(trow).attr('id').split('-').at(1).toLowerCase()
    // persistence should not be cleared at new components
    // so check whether it new or old and set back to false
    clearFormElements(trow, true)
    if (scenario === 'var') {
        // find the persistence button
        const input = $(trow).find('input[id*=Persistent][value=false]')
        $(input).prop('checked', true)
        // send signal to hot water connector
        varHotWaterConnector.removeComponentOption(componentType)
    } else {
        // only send the signal to hot water
        baseHotWaterConnector.removeComponentOption(componentType)
    }
}

// utils functions
