export default function () {
    const pages = {
        "buildings": $('#district-buildings-lca-analysis'),
        "plants": $('#district-plants-lca-analysis')
    }
    _setupInitialState(pages)
    _setupListeners(pages)

    _setupStackedCharts()
    _setupPieCharts()

}

// Reactivity

/*
 Add a check when if non of the buttons is selected,
 select the buildings button
 */
function _setupInitialState (pages) {
    let checkedPage = $(`input[type=radio]:checked`)[0]
    if (checkedPage === undefined) {
        const buildings_radio = $("#show-buildings-page")
        buildings_radio.prop('checked', true)
        checkedPage = buildings_radio
    }
    _showPage(pages, $(checkedPage).val())
}

function _setupListeners (pages) {
    // switch page listener
    $('input[type=radio]').on('click', function () {
        if (this.checked) _showPage(pages, $(this).val())
    })
}

function _showPage (pages, selected) {
    pages[selected].show()
    for (let p in pages) if (p !== selected) pages[p].hide();
}

function _setupCounters () {
    $('.count').each(function () {
        $(this).prop('Counter',0).animate({
            Counter: $(this).text()
        }, {
            duration: 1000,
            easing: 'swing',
            step: function (now) {
                $(this).text(Math.ceil(now));
            }
        });
    });
}

const config = {
    type: 'bar',
    options: {
        plugins: {
            title: {
                display: true,
                text: 'Chart.js Bar Chart - Stacked'
            },
        },
        responsive: true,
        scales: {
            xAxes: [{
                stacked: true
            }],
            yAxes: [{
                stacked: true
            }]
        }
    }
};

function _setupStackedCharts () {
    _setupStackedChart("electrical-stacked", electricalStackedData, 1.6)
    _setupStackedChart("peak-demand-bar", peakDemandData, 1.4)
    _setupStackedChart("peak-supply-bar", peakSupplyData, 1.4)
    _setupStackedChart("cash-flows-stacked", cashFlowsData, 1.85)
    _setupStackedChart("environmental-annual-stacked", environmentalAnnualStackedData, 1.8)
    _setupStackedChart("environmental-lifetime-stacked", environmentalLifetimeStackedData, 1.8)
}

function _setupStackedChart (id, chartData, aspectRatio) {
    const peakSupply = $(`#${id}`)
    const config3 = Object.assign({}, config)
    config3.data = chartData
    config3.options.aspectRatio = aspectRatio
    let myChart = new Chart(
        peakSupply,
        config3
    );
}

// =============================================
// Pie charts for annual thermal demand and load
// =============================================
const pieConfig = {
    type: 'doughnut',
    options: {
        title: {
            display: true,
            position: 'bottom',
        }
    }
}

function _setupPieChart (id, chartData, title, units, aspectRatio=1) {
    const canvas = $(`#${id}`)
    const config1 = Object.assign({}, pieConfig)
    config1.data = chartData
    const totalSum1 = chartData.datasets[0].data.reduce(function(acc, val) { return acc + val; }, 0)
    config1.options.title.text = title + ': ' + totalSum1 + units
    config1.options.aspectRatio = aspectRatio
    let myChart = new Chart(
        canvas,
        config1
    );
}

function _setupPieCharts () {
    _setupPieChart("thermal-pie-1", thermalPieData1, 'Annual Demand', 'kWh', 1.5)
    _setupPieChart("thermal-pie-2", thermalPieData2, 'Annual Load (gross)', 'kWh', 1.5)
    _setupPieChart("initial-investment-pie", initialInvestmentData, 'Initial Investment', '€')
    _setupPieChart("om-costs-pie", annualOmCostsData, 'Annual O&M Costs', '€')
}