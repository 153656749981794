let form, modal
let openScenario
let oldCreateButton, newCreateButton
let oldCompleteButton, newCompleteButton

export default function (formElement) {
    openScenario = ''
    modal = $('#confirm')
    form = formElement
    oldCreateButton = $('#setup-old')
    newCreateButton = $('#setup-new')
    oldCompleteButton = $('#complete-0')
    newCompleteButton = $('#complete-1')

    addModalListener()
    setUpCreateButtons()
    setUpCompleteButtons()
}

function setUpCompleteButtons () {
    // Currently only closes the scenario form but in the future it could
    // send an ajax request to save it on the fly
    addCompleteButtonListener(oldCompleteButton, form.oldScenarioElement, 'old')
    addCompleteButtonListener(newCompleteButton, form.newScenarioElement, 'new')
}

function addCompleteButtonListener (button, scenarioElement, scenario) {
    button.on('click', function () {
        openScenario = ''
        $(scenarioElement).hide()
        const createButton = $(`#setup-${scenario}`)

        if (scenario === 'old') createButton.text('Edit Old Scenario')
        else createButton.text('Edit New Scenario')

        changeButtonClass(createButton, 'inactive')
    })
}

function setUpCreateButtons () {
    addCreateButtonListener(oldCreateButton, form.oldScenarioElement, 'old')
    addCreateButtonListener(newCreateButton, form.newScenarioElement, 'new')
}

function addCreateButtonListener (button, scenarioElement, scenario) {
    console.log('creating create buttons')
    button.on('click', function () {
        if (openScenario === '') {
            $(scenarioElement).toggle()
            openScenario = scenario
            changeButtonClass(button, 'active')
        } else if (openScenario !== scenario) {
            modal.modal('toggle')
        }
    })
}

function addModalListener () {
    const okButton = modal.find('#accept')
    $(okButton).on('click', function () {
        if (openScenario === 'old') {
            openScenario = 'new'
            $(form.oldScenarioElement).hide()
            $(form.newScenarioElement).show()
            changeButtonClass(newCreateButton, 'active')
            changeButtonClass(oldCreateButton, 'inactive')
        }
        else if (openScenario === 'new') {
            openScenario = 'old'
            $(form.newScenarioElement).hide()
            $(form.oldScenarioElement).show()
            changeButtonClass(oldCreateButton, 'active')
            changeButtonClass(newCreateButton, 'inactive')
        }

        modal.modal('toggle')
    })
}

function changeButtonClass(button, state) {
  if (state === 'active') {
    $(button).removeClass('btn-info')
    $(button).addClass('btn-primary')
  } else {
    $(button).removeClass('btn-primary')
    $(button).addClass('btn-info')
  }
  $(button).blur()
}
