import Chart from 'chart.js';
import 'chartjs-plugin-colorschemes';

let gridLinesColor = '#909090'
const chartConfig = {
  type: 'line',
  data: {
    datasets: []
  },
  options: {
    scales: {
      xAxes: [
        {
          type: 'time',
          time: {
              unit: 'hour',
              stepSize: 1,
          },
          lineThickness: 1,
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Time',
            fontColor: "#000000"
          },
          ticks: {
            fontColor: "#000000"
          },
          gridLines: {
            color: gridLinesColor
          }
        }
      ]
    }
  }
};


export default class IndividualChartHelper {
  constructor(projectId, p_types, chartCanvas) {
    this.projectId = projectId;
    this.p_types = p_types;
    this.chart = chartCanvas;
    this.datasets = [];
    this.monitoring = true;
  }

  getPlotTypes() {
    return this.p_types;
  }

  initPlot() {
    const colors = ['blue', 'crimson', 'orange', 'green', 'purple'];
    const canvas = this.chart;
    const ctx = canvas.getContext('2d');
    this.chart = new Chart(ctx, {
      ...chartConfig,
      data: {
        datasets: this.datasets
      },
      options: {
        ...chartConfig.options,
        scales: {
          ...chartConfig.options.scales,
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
                fontColor: "#000000",
                autoskip: false
              },
              scaleLabel: {
                display: true,
                labelString: `Project ${this.projectId}`,
                fontColor: "#000000"
              },
              gridLines: {
                color: gridLinesColor
              }
            }
          ]
        }
      }
    });

    if (Array.isArray(this.p_types)) {
      for (let i = 0; i < this.p_types.length; i++) {
        const currentType = this.p_types[i];
        this.addLine(currentType, currentType.replace('_', ' '), colors[i]);
      }
    } else {
      const currentType = this.p_types;
      this.addLine(currentType, currentType.replace('_', ' '), colors[0]);
    }
  };

  addLine(lineType, labelName, color) {
    if(labelName === 'total electrical_consumption') labelName = 'various electrical_consumption'

    let exists = this.datasets.some(d => d.lineType === lineType);
    if (!exists) {
      this.datasets.push({
        lineType,
        label: labelName,
        data: [],
        fill: false,
        borderWidth: 2,
        borderColor: color,
        backgroundColor: color,
        pointBorderColor: color,
      });
      this.chart.update();}
    };

    addDataPoint(lineType, x, y) {
      if (this.monitoring) {
        const dataset = this.datasets.find(d => d.lineType === lineType);
        if (dataset && y != "NaN" && y !== undefined) {
          dataset.data.push({
            x: new Date(x),
            y: y
          });
          this.chart.update();
        }
      }
    };

    clearChart() {
      this.datasets.splice(0, this.datasets.length);
      // this.chart.config.data = { datasets: this.datasets }
      this.chart.update();
    };

    clearDataPoints() {
      $(this.datasets).each(function (index, dataset) {
        dataset.data = []
      });
      this.chart.update();
    }

    // without points
    smoothChartLine(smooth=true) {
      const theChart = this.chart;
      $(this.datasets).each(function (index, dataset) {
        let radius = 3;
        if (smooth) radius = 1.5;
        dataset.pointRadius = radius;

        if (smooth) {
          theChart.options.scales.xAxes[0].time = {
              unit: 'day',
              stepSize: 1,
          };
        } else {
          theChart.options.scales.xAxes[0].time = {
              unit: 'hour',
              stepSize: 1,
          };
        }
        theChart.update();
      });
    }

    pauseMonitoring (pause) {
      this.monitoring = !pause;
    }

    isPaused () {
      return !this.monitoring;
    }
};
